import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useAuth } from "react-oidc-context";
import { useAlert } from "react-alert";
import { getAuthHeaders } from "../../utils/utils";
import Article from "../article.js";
import { BACKEND_URL } from "../../config";

function BannedContent({ storyId, articleIDs, unBanArticle }) {
    const { user } = useAuth();
    const alert = useAlert();
    const [bannedArticles, setBannedArticles] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchBannedArticles = useCallback(async () => {
        try {
            setIsLoading(true);
            const headers = getAuthHeaders(user.access_token);
            const result = await axios.post(`${BACKEND_URL}/admin/content/article/list`, { id_list: articleIDs }, { headers });
            setBannedArticles(result.data);
        } catch (e) {
            alert.show(e.toString());
        } finally {
            setIsLoading(false);
        }
    }, [alert, articleIDs, user.access_token]);

    useEffect(() => {
        if (articleIDs?.length) {
            fetchBannedArticles();
        }
    }, [fetchBannedArticles, articleIDs]);

    return (
        <div className="banned-content mt-2" data-testid="banned-content-tab">
            {isLoading ? (
                <div style={{ gridColumn: "2/3" }} className="spinner-border d-inline-block text-center mx-auto" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                Array.isArray(bannedArticles) &&
                bannedArticles.length > 0 &&
                bannedArticles.map((article, i) => (
                    <Article
                        isEditMode={false}
                        key={article.id || article.url}
                        article={article}
                        isBanned={true}
                        index={i}
                        storyId={storyId}
                        removeArticleFromBanlist={(...args) => {
                            unBanArticle(...args);
                        }}
                    />
                ))
            )}
        </div>
    );
}
BannedContent.propTypes = {
    storyId: PropTypes.string.isRequired,
    articleIDs: PropTypes.array.isRequired,
    unBanArticle: PropTypes.func.isRequired
};

export default BannedContent;
