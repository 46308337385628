import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import ProtectedRoute from "./auth/ProtectedRoute";
import Main from "./main/main.js";
import "./App.css";

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/">
                    <ProtectedRoute>
                        <Main />
                    </ProtectedRoute>
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
