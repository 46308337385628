/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { formatDistanceToNowStrict } from "date-fns";
import { useAuth } from "react-oidc-context";
import ConfirmPopup from "../../utils/confirmPopup";
import API from "../../../api/deepDiveAPI";
import Table from "../../utils/table";
import NewDeepDiveTopic from "../newDeepDive";

function LinkedTopicsList({ categoryId }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [topics, setTopics] = useState(null);
    const [allCategories, setAllCategories] = useState(null);
    const [activeTab, setActiveTab] = useState("list");
    const [newTopicMode, setNewTopicMode] = useState(false);
    const relinkId = useRef(categoryId);

    const relinkStory = topicId => {
        setIsRefreshing(true);
        API.relinkTopicToCategory(topicId, relinkId.current, user.access_token)
            .catch(err => alert.show(`Error: ${err.message}`, { timeout: 1000, type: "error" }))
            .finally(() => {
                setIsRefreshing(false);
                relinkId.current = categoryId;
            });
    };

    const onRelinkSelect = e => {
        relinkId.current = e.target.value;
    };

    const cancelRelink = () => {
        relinkId.current = categoryId;
    };

    const categorySelectBox = (
        <div style={{ minWidth: "250px", padding: "0 0 12px 0" }}>
            {Array.isArray(allCategories) ? (
                <select onChange={onRelinkSelect} id="categoryLinkSelect" defaultValue={relinkId.current} className="custom-select d-inline">
                    {allCategories.map(category => {
                        return (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        );
                    })}
                </select>
            ) : (
                <div className="spinner spinner-border float-right d-inline-block mr-2" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )}
        </div>
    );

    const columns = [
        {
            Header: () => <span style={{ float: "left" }}>Name</span>,
            accessor: "name",
            Cell: row => (
                <Link
                    className={`text-primary ${!row.data[row.row.index].is_active ? "text-danger" : ""}`}
                    to={`/deep-dive/topic/${row.data[row.row.index].id}`}
                >
                    {row.value}
                    {!row.data[row.row.index].is_active && (
                        <span
                            onClick={() => alert.show("WARNING: This topic is disabled.", { timeout: 10000, type: "warning" })}
                            className="ml-2"
                            title={"WARNING: This topic is disabled."}
                        >
                            <i className="fas fa-exclamation-triangle text-warning position-absolute pointer"></i>
                        </span>
                    )}
                </Link>
            )
        },
        {
            Header: () => <span style={{ float: "left" }}>Updated</span>,
            accessor: "updatedAt",
            Cell: row => formatDistanceToNowStrict(new Date(row.value), { addSuffix: true })
        },
        {
            Header: () => <span style={{ float: "left" }}>Created</span>,
            accessor: "createdAt",
            Cell: row => formatDistanceToNowStrict(new Date(row.value), { addSuffix: true })
        },
        {
            Header: () => <span style={{ float: "left" }}>Actions</span>,
            accessor: "_", // this is just a workaround for custom header
            Cell: row => (
                <div>
                    <ConfirmPopup
                        confirmText="Relink"
                        title={"Select new category"}
                        content={categorySelectBox}
                        maxWidth={400}
                        onConfirm={() => relinkStory(row.data[row.row.index].id)}
                        onCancel={() => cancelRelink()}
                    >
                        <button className="btn btn-outline-danger btn-sm ml-1">Relink</button>
                    </ConfirmPopup>
                    <button
                        onClick={() => moveTopicPosition(row.data[row.row.index].id, "up")}
                        className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === 0 ? "invisible" : ""}`}
                    >
                        <i className="fas fa-arrow-up"></i>
                    </button>
                    <button
                        onClick={() => moveTopicPosition(row.data[row.row.index].id, "down")}
                        className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === row.rows.length - 1 ? "invisible" : ""}`}
                    >
                        <i className="fas fa-arrow-down"></i>
                    </button>
                </div>
            )
        }
    ];

    const fetchLinkedTopics = useCallback(() => {
        setIsRefreshing(true);
        API.getLinkedDeepDiveTopics({ parentIdsList: [categoryId] }, user.access_token)
            .then(data => setTopics(data && data.data))
            .catch(e => alert.show(e))
            .finally(() => setIsRefreshing(false));
    }, [alert, categoryId, user.access_token]);

    const fetchTopics = useCallback(() => {
        API.getAllDeepDiveCategories(user.access_token)
            .then(data => setAllCategories(data && data.data))
            .catch(e => alert.show(e));
    }, [alert, user.access_token]);

    // Fetch data on mount
    useEffect(() => {
        fetchLinkedTopics();
        fetchTopics();
    }, [fetchLinkedTopics, fetchTopics]);

    const onNewTopicSuccess = () => {
        fetchLinkedTopics();
        setNewTopicMode(false);
    };

    const moveTopicPosition = (topicId, direction) => {
        let topicToMoveId = "";
        let moveAfterTopicId = "";
        const currentIdx = topics.findIndex(x => x.id === topicId);

        if (direction === "up") {
            topicToMoveId = topics[currentIdx - 1].id;
            moveAfterTopicId = topicId;
        } else {
            topicToMoveId = topicId;
            moveAfterTopicId = topics[currentIdx + 1].id;
        }

        API.reorderTopicChildTopic(categoryId, topicToMoveId, moveAfterTopicId, user.access_token)
            .then(() => fetchLinkedTopics())
            .catch(e => alert.show(e));
    };

    return (
        <div className="mt-4 position-relative">
            {isRefreshing && allCategories ? (
                <div className="loading-modal">
                    <div className="spinner spinner-border float-right d-inline-block mr-2" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}
            <ul className="nav nav-tabs">
                <li className="nav-item" onClick={() => setActiveTab("list")}>
                    <p className={`nav-link pointer ${activeTab === "list" ? "active" : ""}`}>Linked topics</p>
                </li>
                {/* <li className="nav-item" onClick={() => setActiveTab("create")}>
                    <p className={`nav-link pointer ${activeTab === "create" ? "active" : ""}`}>Create topic and link</p>
                </li> */}
                {/* <li className="nav-item" onClick={() => setActiveTab("link")}>
                    <p className={`nav-link pointer ${activeTab === "link" ? "active" : ""}`}>Link Topic</p>
                </li> */}
            </ul>
            <div className="tab-pane">
                {!newTopicMode ? (
                    <div className="d-flex justify-content-center mt-2">
                        <span onClick={() => setNewTopicMode(true)} className="h4 pointer">
                            <i className="fas fa-plus"></i>
                        </span>
                    </div>
                ) : (
                    <div className="tab-pane">
                        <NewDeepDiveTopic parentId={categoryId} type="topic" onDone={onNewTopicSuccess} onClose={() => setNewTopicMode(false)} />
                    </div>
                )}
                {activeTab === "list" && topics && topics.length > 0 && (
                    <div className="tab-pane">
                        <Table columns={columns} data={topics} />
                    </div>
                )}
                {activeTab === "list" && topics && topics.length === 0 && (
                    <div className="tab-pane">
                        <p className="text-center mt-3">No linked topics</p>
                    </div>
                )}
                {/* {activeTab === "link" && allTopics && allTopics.length > 0 && (
                    <div className="TopicToLink tab-pane mt-3">
                        {allTopics.map(topic => {
                            if (topics.findIndex(x => x.id === topic.id) === -1) {
                                return (
                                    <button key={topic.id} className="btn btn-sm btn-outline-info" onClick={() => addTopicLink(topic.id)}>
                                        {topic.name}
                                    </button>
                                );
                            }
                        })}
                    </div>
                )} */}
            </div>
        </div>
    );
}

LinkedTopicsList.propTypes = {
    categoryId: PropTypes.string.isRequired
};

export default LinkedTopicsList;
