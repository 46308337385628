import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useAuth } from "react-oidc-context";
import { useAlert } from "react-alert";
import Article from "../article.js";
import Tweet from "../tweet.js";
import PinNewContent from "./pinNewContent";
import { UpdatePinnedContentPositionAPI } from "../../api/contentAPI";
import { isSatireCategory, getPublisherTags } from "../../utils/utils.js";

const SortableContentContainer = SortableContainer(({ children }) => <div>{children}</div>);

const populateContent = ({ props, content, index, itemPosition, updatePosition }) => {
    if (content.tweet_id) {
        return (
            <Tweet
                key={content.id}
                tweet={content}
                isPinned={true}
                itemPosition={itemPosition}
                onArticleChange={props.onChange}
                index={index}
                onUpdate={props.updatePinnedTweet}
                onDelete={props.deletePinnedTweet}
                onPositionUpdate={updatePosition}
                showPosition
            />
        );
    }
    return (
        <Article
            key={content.id || content.url}
            article={content}
            onDelete={props.deletePinnedArticle}
            isPinned={true}
            onArticleChange={props.onChange}
            index={index}
            itemPosition={itemPosition}
            showPosition
            isSatire={isSatireCategory(props.categoryName)}
            onUpdate={props.updatePinnedArticle}
            allowToChooseTagColor={!isSatireCategory(props.categoryName)}
            setTopicImage={props.setTopicImage}
            onPositionUpdate={updatePosition}
        />
    );
};
const SortableItem = SortableElement(populateContent);

function PinnedContent(props) {
    const alert = useAlert();
    const { user } = useAuth();
    const [data, setData] = useState(() => sortContent(props.pinnedContent, props.isSatire));
    const [isRefreshing, setIfRefreshing] = useState(false);

    useEffect(() => {
        setData(sortContent(props.pinnedContent, props.isSatire));
    }, [props.isSatire, props.pinnedContent]);

    const updatePosition = useCallback(
        async (content_to_move_id, move_after_content_id) => {
            try {
                setIfRefreshing(true);
                props.lockParent();
                UpdatePinnedContentPositionAPI({ story_id: props.storyId, content_to_move_id, move_after_content_id }, user.access_token).then(() => {
                    props.refetchStories();
                });
            } catch (e) {
                setIfRefreshing(false);
                alert.show(e.message || "Oops, something went wrong...");
                throw e;
            } finally {
                setIfRefreshing(false);
            }
        },
        [alert, user.access_token, props]
    );

    const onSortEnd = useCallback(
        targetBiasGroup =>
            ({ oldIndex, newIndex }) => {
                // sanity check: position has not been changed
                if (oldIndex === newIndex) return;

                const oldIndexItem = data[targetBiasGroup][oldIndex];
                const newIndexItem = data[targetBiasGroup][newIndex];

                if (newIndex === 0) {
                    // moving item to be the first
                    updatePosition(oldIndexItem.id, newIndex !== 0 ? oldIndexItem.id : undefined);
                } else if (newIndex > oldIndex) {
                    // moving item down
                    updatePosition(oldIndexItem.id, newIndexItem.id);
                } else if (newIndex < oldIndex) {
                    // moving item up
                    updatePosition(newIndexItem.id, oldIndexItem.id);
                }

                // TODO: not sure why it's not working
                // let newData = data[targetBiasGroup];
                // newData = arrayMove(newData, oldIndex, newIndex);
                // setData({ ...data, ...newData });
            },
        [data, updatePosition]
    );

    const shouldCancelStart = useCallback(
        e => {
            console.log("e.target.className: ", e.target.className);
            if (isRefreshing) {
                alert.show("Slow down, still working on the previous request");
                return true;
            }
            if (!e || !e.target || !e.target.className || !e.target.className.includes) {
                return true;
            }
            const allowedClassnames = ["publisher-name", "tweet", "author-name", "tweet-img", "article-footer"];
            const shouldCancel = !allowedClassnames.some(item => e.target.className.includes(item));
            return shouldCancel;
        },
        [alert, isRefreshing]
    );

    return (
        <React.Fragment>
            <PinNewContent categoryName={props.categoryName} pinNewContent={props.pinNewContent} />
            <div className={`pinned-content ${props.isSatire ? "one-column" : ""}`} data-testid="pinned-content">
                {data.left.length > 0 && (
                    <div className="left">
                        <SortableContentContainer
                            key={"sortable-left"}
                            axis={"y"}
                            disableAutoscroll={true}
                            onSortEnd={onSortEnd("left")}
                            shouldCancelStart={shouldCancelStart}
                        >
                            {data.left.map((content, i) => (
                                <SortableItem
                                    props={props}
                                    key={`pc-${content.id}`}
                                    index={i}
                                    itemPosition={props.pinnedContent.findIndex(item => item.id === content.id)}
                                    content={content}
                                    updatePosition={updatePosition}
                                />
                            ))}
                        </SortableContentContainer>
                    </div>
                )}
                {data.center.length > 0 && (
                    <div className="center">
                        <SortableContentContainer
                            key={"sortable-center"}
                            axis={"y"}
                            disableAutoscroll={true}
                            onSortEnd={onSortEnd("center")}
                            shouldCancelStart={shouldCancelStart}
                        >
                            {data.center.map((content, i) => (
                                <SortableItem
                                    props={props}
                                    key={`pc-${content.id}`}
                                    index={i}
                                    itemPosition={props.pinnedContent.findIndex(item => item.id === content.id)}
                                    content={content}
                                    updatePosition={updatePosition}
                                />
                            ))}
                        </SortableContentContainer>
                    </div>
                )}
                {data.right.length > 0 && (
                    <div className="right">
                        <SortableContentContainer
                            key={"sortable-right"}
                            axis={"y"}
                            disableAutoscroll={true}
                            onSortEnd={onSortEnd("right")}
                            shouldCancelStart={shouldCancelStart}
                        >
                            {data.right.map((content, i) => (
                                <SortableItem
                                    props={props}
                                    key={`pc-${content.id}`}
                                    index={i}
                                    itemPosition={props.pinnedContent.findIndex(item => item.id === content.id)}
                                    content={content}
                                    updatePosition={updatePosition}
                                />
                            ))}
                        </SortableContentContainer>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export function sortContent(pinnedContent, isSatire) {
    const sortedByColumn = { left: [], center: [], right: [], satire: [] };
    if (pinnedContent) {
        pinnedContent.forEach(content => {
            const bias = getBias(content);
            if (isSatire) {
                sortedByColumn.satire.push(content);
            } else if (bias === "left") {
                sortedByColumn.left.push(content);
            } else if (bias === "center" || bias === "middle" || !bias) {
                sortedByColumn.center.push(content);
            } else if (bias === "right") {
                sortedByColumn.right.push(content);
            }
        });
    }
    // sortByDate(sortedByColumn.left, ["position", "datePublished"]);
    // sortByDate(sortedByColumn.right, ["position", "datePublished"]);
    // sortByDate(sortedByColumn.center, ["position", "datePublished"]);
    return sortedByColumn;
}

function getBias(content) {
    if (!content) return;
    if (content.tweet_id) {
        return content.bias;
    } else {
        return getPublisherTags(content.full_info)[0];
    }
}

PinnedContent.propTypes = {
    pinnedContent: PropTypes.array.isRequired,
    storyId: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    isEditMode: PropTypes.bool,
    isSatire: PropTypes.bool,
    pinNewContent: PropTypes.func,
    updatePinnedTweet: PropTypes.func,
    updatePinnedArticle: PropTypes.func,
    deletePinnedTweet: PropTypes.func,
    deletePinnedArticle: PropTypes.func,
    refetchStories: PropTypes.func,
    setTopicImage: PropTypes.func,
    lockParent: PropTypes.func
};

export default PinnedContent;
