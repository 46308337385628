import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import axios from "axios";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import { BlockBlobClient } from "@azure/storage-blob";
import Modal from "../utils/modal";
import Progress from "../utils/progress.js";
//import TagInput from "../TagInput/TagInput";
import { BACKEND_URL } from "../../config.js";
import { getAuthHeaders } from "../../utils/utils.js";

const emptyFormData = { title: "", description: "", author: "" };

function UploadVideoModal(props) {
    const { user } = useAuth();

    const [formData, setFormData] = useState(emptyFormData);
    const [file, setFile] = useState(new File([], ""));
    const [filename, setFilename] = useState("Choose a file");
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const alert = useAlert();

    const resetModalData = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            setFormData(emptyFormData);
            setFile("");
            setUploadPercentage(0);
            setIsUploading(false);
            setFilename("Choose a file");
        });
    };

    const onInputChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onFileInputChange = e => {
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
    };

    const getUploadUrl = async () => {
        const authHeader = getAuthHeaders(user.access_token);
        const params = { description: formData.description };
        const uploadUrlResult = await axios.get(`${BACKEND_URL}/admin/videos/uploadurl`, { headers: authHeader, params });
        const result = uploadUrlResult.data && uploadUrlResult.data;
        if (!result) throw Error("Failed to retrieve upload URL");
        return result;
    };

    const uploadVideo = async uploadUrl => {
        const response = await new BlockBlobClient(uploadUrl).uploadData(file, {
            onProgress: progress => {
                setUploadPercentage(Math.round((progress.loadedBytes / file.size) * 100));
            },
            maxSingleShotSize: 256 * 1024 // 256 mb
        });
        if (!response || response._response.status !== 201) {
            throw Error("Something went wrong while uploading the video");
        }
    };

    /* Save Video metadata and begin encoding job */
    const saveNewVideoMetadata = async asset_name => {
        const headers = getAuthHeaders(user.access_token);
        const result = await axios.post(`${BACKEND_URL}/admin/videos/save`, { ...formData, asset_name: asset_name }, { headers });
        // on success we should get 200
        if (!result || result.status !== 200) {
            throw Error("Something went wrong while saving new video metadata and triggering encoding job");
        }
    };

    const onUploadClick = async () => {
        try {
            if (!file) throw Error("Please provide video");
            if (!formData.title) throw Error("Video title is required");
            if (!formData.description) throw Error("Video description is required");

            setIsUploading(true);

            // step 1: Get upload URL
            const { upload_url, asset_name } = await getUploadUrl();

            // step 2: Upload video to Azure media storage
            await uploadVideo(upload_url);

            // step 3: Save the new video metadata and trigger video encoding job on Azure
            await saveNewVideoMetadata(asset_name);

            alert.show("Video has been successfully uploaded", { timeout: 2000, type: "success" });
            ReactDOM.unstable_batchedUpdates(() => {
                resetModalData();
                props.closeModal();
                props.refreshVideoList();
            });
        } catch (e) {
            alert.show(e.toString(), { timeout: 2000, type: "error" });
            console.log(e);
            setIsUploading(false);
        }
    };

    return (
        <Modal
            isOpen={props.isOpen}
            styles={{ minWidth: "30vw", width: "70vw", maxWidth: "400px" }}
            onClose={props.closeModal}
            label="Article settings"
        >
            <div className="modal-header">
                <h5 className="modal-title">Upload a new video</h5>
            </div>
            <div className="modal-body">
                <div className="form-group">
                    <label className="control-label font-weight-bold" htmlFor="title">
                        Title:<span className="text-danger"> (required)</span>
                    </label>
                    <input
                        onChange={onInputChange}
                        disabled={isUploading}
                        className="form-control text-body mb-2"
                        name="title"
                        type="text"
                        value={formData.title}
                        placeholder="Add video title"
                        required
                    />

                    <label className="control-label font-weight-bold" htmlFor="description">
                        Description:<span className="text-danger"> (required)</span>
                    </label>
                    <textarea
                        onChange={onInputChange}
                        disabled={isUploading}
                        className="form-control text-body mb-2"
                        name="description"
                        type="text"
                        value={formData.description}
                        placeholder="Add video description"
                        required
                    />

                    <label className="control-label font-weight-bold" htmlFor="author">
                        Author:
                    </label>
                    <input
                        onChange={onInputChange}
                        disabled={isUploading}
                        className="form-control text-body"
                        name="author"
                        type="text"
                        value={formData.author}
                        placeholder="Original author"
                    />
                    {/* <TagInput label="Keywords:" placeholder="Add keywords" name="tags" onChange={onInputChange} value={formData.tags} /> */}
                </div>
                <div className="form-group">
                    <div className="input-group mb-3">
                        <div className="custom-file">
                            <input onChange={onFileInputChange} type="file" className="custom-file-input" />
                            <label className="custom-file-label">{filename}</label>
                        </div>
                    </div>
                    {uploadPercentage ? <Progress percentage={uploadPercentage} /> : null}
                </div>
            </div>
            {!isUploading ? (
                <div className="modal-footer">
                    <button onClick={props.closeModal} className="btn btn-secondary">
                        Cancel
                    </button>
                    <button onClick={onUploadClick} className="btn btn-primary">
                        Upload
                    </button>
                </div>
            ) : null}
            {isUploading ? (
                <div className="modal-footer justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : null}
        </Modal>
    );
}

UploadVideoModal.propTypes = {
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    refreshVideoList: PropTypes.func
};

export default UploadVideoModal;
