import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./pagination.scss";

const MAX_PAGINATION_PAGES = 10;

function Pagination(props) {
    const pagination = [];

    useEffect(() => {
        props.onClick(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.perPage]);

    const onClick = e => {
        props.onClick(parseInt(e.currentTarget.innerHTML));
    };

    const shiftLeft = () => {
        props.onClick(1);
    };

    const shiftRight = () => {
        const nextShift = props.page + MAX_PAGINATION_PAGES;
        props.onClick(nextShift > Math.ceil(props.total / props.perPage) ? Math.ceil(props.total / props.perPage) : nextShift);
    };

    const { startPage, endPage } = paginate(props.total, props.page, props.perPage, MAX_PAGINATION_PAGES);
    for (let i = startPage; i <= endPage; i++) {
        pagination.push(
            <li key={i} className={`page-item ${i === props.page ? "active" : ""}`} data-testid="page-item">
                <span onClick={onClick} className="page-link pointer">
                    {i}
                </span>
            </li>
        );
    }

    return (
        <div className={`pagination-container${props.className ? ` ${props.className}` : ""}`}>
            <ul className="pagination">
                <li className="page-item">
                    <span onClick={shiftLeft} className="page-link zindex-0" href="#">
                        &laquo;
                    </span>
                </li>
                {pagination}
                <li className="page-item">
                    <span onClick={shiftRight} className="page-link" href="#">
                        &raquo;
                    </span>
                </li>
            </ul>
            <div className="per-page form-group">
                <select onChange={props.onPerPageChange} id="perPageSelect" value={props.perPage} className="custom-select d-inline">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    {/* <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option> */}
                </select>
            </div>
        </div>
    );
}

function paginate(totalItems, currentPage = 1, pageSize = 10, maxPages = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    /* Keep this for now, may use it in the future */
    // calculate start and end item indexes
    // const startIndex = (currentPage - 1) * pageSize;
    // const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    // const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage
        // startIndex: startIndex,
        // endIndex: endIndex,
        // pages: pages
    };
}

Pagination.propTypes = {
    perPage: PropTypes.number.isRequired,
    className: PropTypes.string,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    onPerPageChange: PropTypes.func.isRequired
};

export default Pagination;
