// @ts-check

import React, { Fragment, memo } from "react";

export const AiAugmentedStorySummary = memo(
    /**
     * @param {object} props
     * @param {import("./AiSummaryTypes").AiSummary['htmlTree']} props.htmlTree
     */
    ({ htmlTree }) => {
        return (
            <div className="AiAugmentedStorySummary">
                <ElementRenderer tree={htmlTree} />
            </div>
        );
    }
);

const disallowedTags = ["script", "style", "iframe", "object", "embed", "form", "input", "button"];
const nonRenderedTags = ["html", "body"];

const ElementRenderer = memo(
    /**
     * @param {object} props
     * @param {import("./AiSummaryTypes").AiSummary['htmlTree']} props.tree
     */
    ({ tree }) => {
        if (!tree?.length) {
            return null;
        }

        return (
            <>
                {tree.map((element, idx) => {
                    if ("text" in element) {
                        return <Fragment key={idx}>{element.text}</Fragment>;
                    }

                    if (element.tag === "fre") {
                        return (
                            <span className="aug-link" key={idx} title={element.attributes?.["data-description"]}>
                                <ElementRenderer tree={element.children} />
                            </span>
                        );
                    }

                    if (nonRenderedTags.includes(element.tag)) {
                        return <ElementRenderer tree={element.children} key={idx} />;
                    }

                    if (disallowedTags.includes(element.tag)) {
                        return null;
                    }

                    if (element.tag === "br") {
                        return <br />;
                    }

                    if (element.tag === "hr") {
                        return <hr />;
                    }

                    return (
                        <Component key={idx} as={element.tag} {...element.attributes}>
                            <ElementRenderer tree={element.children} />
                        </Component>
                    );
                })}
            </>
        );
    }
);

function Component({ as: Tag = "div", ...otherProps }) {
    return <Tag {...otherProps} />;
}
