import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { BACKEND_URL } from "../config.js";
import Categories from "../components/categories";
import Header from "../header/header.js";
import Sidebar from "../sidebar/sidebar.js";
import CensoredArticlesPage from "../components/pages/CensoredArticles/CensoredArticles";
import FunArticlesPage from "../components/pages/FunArticles/FunArticles";
import VideoLibrary from "../components/pages/VideoLibrary/VideoLibrary";
import Reports from "../components/reports/reports";
import { getAuthHeaders, hasRole } from "../utils/utils.js";
import DeepDive from "../components/deepDive/deepDive";
import Crawler from "../components/crawler/crawler";
import Unauthorized from "../components/errors/403.js";

// axios.defaults.headers['ngrok-skip-browser-warning'] = '1';

function Main() {
    const [categories, setCategories] = useState([]);
    const { user } = useAuth();

    const hasTesterRole = hasRole(user, "tester");
    const fetchCategories = useCallback(async () => {
        const headers = getAuthHeaders(user.access_token);
        const result = await axios.get(`${BACKEND_URL}/admin/category/list`, { headers });
        setCategories(result.data.filter(cat => (hasTesterRole ? true : cat.name !== "e2e Test")));
    }, [hasTesterRole, user.access_token]);

    const onCategoryUpdate = useCallback(
        (categoryId, newData) => {
            setCategories(categories.map(category => (category._id === categoryId ? newData : category)));
        },
        [categories]
    );

    useEffect(() => {
        if (user.access_token && !categories.length) {
            fetchCategories();
        }
    }, [categories.length, fetchCategories, user.access_token]);

    return (
        <div className="layout">
            <Header />
            <Sidebar categories={categories} />
            <Switch>
                <Route path="/deep-dive" exact={false}>
                    <DeepDive />
                </Route>
                <Route path="/knowledge-panel" exact={false}>
                    {hasRole(user, "curation_knowledge-panel") ? <DeepDive user={user} /> : <Unauthorized />}
                </Route>
                <Route path="/anso">
                    <CensoredArticlesPage />
                </Route>
                <Route path="/fun">
                    <FunArticlesPage />
                </Route>
                <Route path="/video_library">
                    <VideoLibrary />
                </Route>
                {/* <Route path="/articles">
                    <ArticleList user={props.user} />
                </Route> */}
                {/* <Route path="/sources">{hasRole(props.user, "admin") ? <SourceList user={props.user} /> : <Unauthorized />}</Route>
                <Route path="/hosts">{hasRole(props.user, "admin") ? <HostList user={props.user} /> : <Unauthorized />}</Route> */}
                <Route path="/crawler">{hasRole(user, "curation_knowledge-panel") ? <Crawler user={user} /> : <Unauthorized />}</Route>
                <Route path="/reports/twitter">
                    <Reports />
                </Route>
                <Route path="/category">{categories.length && <Categories categories={categories} onCategoryUpdate={onCategoryUpdate} />}</Route>
                <Route path="/">{categories.length && <Categories categories={categories} onCategoryUpdate={onCategoryUpdate} />}</Route>
            </Switch>
        </div>
    );
}

export default Main;
