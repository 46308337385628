import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { getAuthHeaders } from "../../utils/utils.js";
import { BACKEND_URL } from "../../config";

// axios.defaults.headers['ngrok-skip-browser-warning'] = '1';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "300px"
    }
};

function CategoryLinking(props) {
    const { user } = useAuth();
    const [formData, setFormData] = useState({ link_to_category: props.data.link_to_category, link_to_topic: props.data.link_to_topic });
    const [categoryList, setCategoryList] = useState([]);
    const [isLoading, setIsLoading] = useState([true]);

    const fetchListOfCategoriesAndTopics = useCallback(async () => {
        setIsLoading(true);
        const headers = getAuthHeaders(user.access_token);
        const result = await axios.get(`${BACKEND_URL}/admin/category/list`, { headers });
        setIsLoading(false);
        setCategoryList(result.data);
    }, [user.access_token]);

    useEffect(() => {
        if (props.isOpen) {
            fetchListOfCategoriesAndTopics();
        }
    }, [fetchListOfCategoriesAndTopics, props.isOpen]);

    const onInputChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const closeModal = () => {
        props.onClose();
    };

    const onSave = () => {
        props.onSave(formData, props.parentId);
    };

    const unLink = () => {
        setFormData({ link_to_category: "", link_to_topic: "" });
    };

    const selectedCategory = categoryList.find(category => category._id === formData.link_to_category) || null;
    const selectedTopic =
        selectedCategory && selectedCategory.topics ? selectedCategory.topics.find(topic => topic._id === formData.link_to_topic) : null;

    return (
        <Modal isOpen={props.isOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Link to Category">
            <div className="modal-body">
                {isLoading ? (
                    <div className="d-flex justify-content-center mt-2 w-100 text-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="form-group has-danger mb-0">
                        <p className="mb-2">
                            Link to topic
                            {formData.link_to_category && (
                                <span onClick={unLink} className="float-right pointer text-info">
                                    Unlink
                                </span>
                            )}
                        </p>
                        <div>
                            <select
                                value={(selectedCategory && selectedCategory._id) || ""}
                                onChange={onInputChange}
                                name="link_to_category"
                                className="custom-select"
                            >
                                <option value="">Select category</option>
                                {categoryList.map(category => {
                                    return (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        {selectedCategory && (
                            <div>
                                <select
                                    value={(selectedTopic && selectedTopic._id) || ""}
                                    onChange={onInputChange}
                                    name="link_to_topic"
                                    className="custom-select mt-2"
                                >
                                    <option value="">Select topic</option>
                                    {selectedCategory &&
                                        selectedCategory.topics.map(topic => {
                                            return (
                                                <option key={topic._id} value={topic._id}>
                                                    {topic.name}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="modal-footer">
                <button onClick={closeModal} className="btn btn-secondary">
                    Close
                </button>
                <button className="btn btn-primary" onClick={onSave}>
                    Save changes
                </button>
            </div>
        </Modal>
    );
}

CategoryLinking.propTypes = {
    parentId: PropTypes.string.isRequired,
    data: PropTypes.object,
    isOpen: PropTypes.bool,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CategoryLinking;
