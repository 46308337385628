import React from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";
import { useAuth } from "react-oidc-context";
import { APP_VERSION, COMMIT_HASH, ENV, BACKEND_URL, OPENID_ISSUER, ENABLE_DATADOG, DATADOG_ENV } from "../../config";

function AppInfoPopup({ children }) {
    const { user } = useAuth();

    const popover = (
        <Popover id="popover-basic" style={{ width: "600px" }} className="pb-2">
            <Popover.Title as="h3" className="text-primary">
                <strong>App:</strong>
            </Popover.Title>
            <Popover.Content>
                <p className="my-2">
                    <u>Version:</u>{" "}
                    <span className="text-nowrap float-right">
                        <strong>{APP_VERSION}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
                <p className="my-2">
                    <u>Build:</u>{" "}
                    <span className="text-nowrap float-right">
                        <strong>{COMMIT_HASH ? COMMIT_HASH.slice(0, 10) : COMMIT_HASH}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
            </Popover.Content>
            <Popover.Title as="h3" className="text-primary">
                <strong>Account:</strong>
            </Popover.Title>
            <Popover.Content>
                <p className="my-2">
                    <u>Email:</u>{" "}
                    <span className="text-nowrap float-right">
                        <strong>{user?.profile?.email}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
                <p className="my-2">
                    <u>Name:</u>{" "}
                    <span className="text-nowrap float-right">
                        <strong>{user?.profile?.name || "Hmm... Could not find your name"}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
                <p className="my-2">
                    <u>Permissions:</u>{" "}
                    <span className="float-right">
                        <strong>{user?.profile?.realm_access?.roles?.join(", ") || "You must be a hacker"}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
            </Popover.Content>
            <Popover.Title as="h3" className="text-primary">
                <strong>Settings:</strong>
            </Popover.Title>
            <Popover.Content>
                <p className="my-2">
                    <u>Environment:</u>{" "}
                    <span className="text-nowrap float-right">
                        <strong>{ENV}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
                <p className="my-2">
                    <u>Backend URL:</u>{" "}
                    <span style={{ wordBreak: "break-all" }} className="float-right">
                        <strong>{BACKEND_URL}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
                <p className="my-2">
                    <u>Auth Issuer:</u>{" "}
                    <span style={{ wordBreak: "break-all" }} className="float-right">
                        <strong>{OPENID_ISSUER}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
                <p className="my-2">
                    <u>Analytics:</u>{" "}
                    <span className="text-nowrap float-right">
                        <strong>{ENABLE_DATADOG.toString()}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
                <p className="my-2">
                    <u>Analytics env:</u>{" "}
                    <span className="text-nowrap float-right">
                        <strong>{DATADOG_ENV}</strong>
                    </span>
                </p>
                <div className="clearfix"></div>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover}>
            {children}
        </OverlayTrigger>
    );
}

AppInfoPopup.propTypes = {
    children: PropTypes.node.isRequired
};

export default AppInfoPopup;
