import axios from "axios";
import { getAuthHeaders } from "../utils/utils";
import { BACKEND_URL } from "../config";

/* Change story content priority (tweets and articles) */
export async function UpdatePinnedContentPositionAPI({ story_id, content_to_move_id, move_after_content_id }, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.post(
            `${BACKEND_URL}/admin/story/content/reorder`,
            { storyId: story_id, contentToMoveId: content_to_move_id, moveAfterContentId: move_after_content_id },
            { headers }
        );
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}
