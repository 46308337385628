import React, { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import NewDeepDive from "../newDeepDive";
import API from "../../../api/deepDiveAPI";

function DeepDiveCategoryList() {
    const alert = useAlert();
    const { user } = useAuth();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [showNewCategoryCard, setShowNewCategoryCard] = useState(false);
    const [categories, setCategories] = useState(null);

    const fetchCategories = useCallback(() => {
        setIsRefreshing(true);
        API.getAllDeepDiveCategories(user.access_token)
            .then(data => setCategories((data && data.data) || []))
            .catch(e => alert.show(e))
            .finally(() => setIsRefreshing(false));
    }, [user.access_token, alert]);

    // Fetch data on mount
    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    const onNewCategorySuccess = useCallback(() => {
        fetchCategories();
        setShowNewCategoryCard(false);
    }, [fetchCategories]);

    return (
        <div className="DeepDiveCategoryList">
            <h4 className="d-inline align-top">Deep Dive Categories</h4>
            <span onClick={fetchCategories} className="mx-3 pointer h4">
                <i className={isRefreshing ? "fa fa-refresh fa-spin" : "fas fa-sync-alt"} aria-hidden={isRefreshing ? "true" : "false"}></i>
            </span>
            <span onClick={() => setShowNewCategoryCard(true)} className="h4 pointer">
                <i className="fas fa-plus"></i>
            </span>
            {showNewCategoryCard && <NewDeepDive type={"category"} onDone={onNewCategorySuccess} onClose={() => setShowNewCategoryCard(false)} />}
            <div className="d-flex flex-wrap mt-2">
                {categories &&
                    categories.map(category => (
                        <div key={category.id} className={`card my-2 mx-2${category.is_active ? "" : " border border-danger"}`}>
                            <NavLink className="text-primary" activeClassName="underline" to={`/deep-dive/category/${category.id}`}>
                                <div className="card-header">{category.name}</div>
                            </NavLink>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default DeepDiveCategoryList;
