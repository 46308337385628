import axios from "axios";
import { getAuthHeaders } from "../utils/utils";
import { BACKEND_URL } from "../config";
import { getAxiosError } from "./errors";

// Pin video to a story
export async function getStoryByIdAPI(story_id, token) {
    try {
        const headers = getAuthHeaders(token);
        const url = `${BACKEND_URL}/admin/story/${story_id}`;
        const { data } = await axios.get(url, { headers });
        return data;
    } catch (e) {
        throw getAxiosError(e);
    }
}

// Pin video to a story
export async function pinVideoToStory(story_id, video_id, token) {
    try {
        const headers = getAuthHeaders(token);
        const url = `${BACKEND_URL}/admin/story/pin/video`;
        const { data } = await axios.post(url, { story_id, video_id }, { headers });
        return data;
    } catch (e) {
        throw getAxiosError(e);
    }
}

// Unpin video from a story
export async function unpinVideoFromStory(story_id, video_id, token) {
    try {
        const headers = getAuthHeaders(token);
        const url = `${BACKEND_URL}/admin/story/pin/video`;
        const requestData = { story_id, video_id };
        const { data } = await axios.delete(url, { data: requestData, headers });
        return data;
    } catch (e) {
        throw getAxiosError(e);
    }
}
