import React, { useState, useCallback } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import { allowedBestOfWebCategoryNames, allowedCensoredStoriesCategoryNames } from "../utils/allowLists";
import ScraperAPI from "../../api/scrapeAPI";
import API from "../../api/deepDiveAPI";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "475px"
    }
};

const categoryNameMap = {
    fun: allowedBestOfWebCategoryNames,
    censored: allowedCensoredStoriesCategoryNames
};

function AddOrUpdateSpecialContentModal({ type, tag, content = {}, topicId, onClose, onSuccess }) {
    const isEdit = type === "edit";
    const alert = useAlert();
    const { user } = useAuth();
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState(() => {
        if (isEdit) {
            return {
                // title: content.title || "",
                subcategory: content.subcategory || ""
            };
        }
        return {
            url: "",
            imageUrl: "",
            // title: "",
            subcategory: ""
        };
    });

    const saveContent = useCallback(async () => {
        setIsSaving(true);
        if (isEdit) {
            const request = {
                id: content.id,
                ...(content.url !== formData.url && { url: formData.url }),
                // ...(content.title !== formData.title && { title: formData.title }),
                ...(content.subcategory !== formData.subcategory && { subcategory: formData.subcategory })
            };
            // no changes
            if (Object.keys(request).length === 1) {
                setIsSaving(false);
                return;
            }
            API.updateTopicContent(request, "article", user.access_token)
                .then(() => onSuccess())
                .catch(e => alert.show(e))
                .finally(() => setIsSaving(false));
        } else {
            const payload = {
                topicId: topicId,
                url: formData.url,
                type: tag,
                metadata: {},
                subcategory: formData.subcategory
            };

            const metadata = await ScraperAPI.scrapeMetadataFromUrl(formData.url, user.access_token).catch(e =>
                console.error("scrapeMetadataFromUrl failed: ", e)
            );

            if (metadata) {
                const { title, description, author, datePublished, publisherName, publisherLogo, imageUrl } = metadata;
                payload.metadata = {
                    title,
                    description,
                    author,
                    datePublished,
                    publisherName,
                    publisherLogo,
                    imageUrl: formData.imageUrl || imageUrl
                };
            }
            API.postNewTopicItem(payload, "article", user.access_token)
                .then(() => onSuccess())
                .catch(e => alert.show(e))
                .finally(() => setIsSaving(false));
        }
    }, [
        alert,
        content.id,
        content.subcategory,
        content.url,
        formData.imageUrl,
        formData.subcategory,
        formData.url,
        isEdit,
        user.access_token,
        onSuccess,
        tag,
        topicId
    ]);

    const onInputChange = useCallback(
        e => {
            if (e.target.type === "radio") {
                setFormData({ ...formData, bias: e.target.value });
            } else {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        },
        [formData]
    );

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="">
            <div className="modal-body">
                <div className="form-group has-danger">
                    {!isEdit && (
                        <>
                            <label className="form-control-label font-weight-bold" htmlFor="url">
                                URL:<span className="text-danger">*</span>
                            </label>
                            <div className="input-group mb-3">
                                <input
                                    onChange={onInputChange}
                                    disabled={isEdit}
                                    value={formData.url}
                                    type="text"
                                    id="url"
                                    name="url"
                                    className="form-control"
                                />
                            </div>
                            <label className="form-control-label mt-2" htmlFor="imageUrl">
                                Image URL (optional):
                            </label>
                            <input
                                onChange={onInputChange}
                                value={formData.imageUrl}
                                type="text"
                                id="imageUrl"
                                name="imageUrl"
                                className="form-control"
                            />
                            {formData.imageUrl && (
                                <img
                                    style={{ objectFit: "cover", width: "400px", height: "200px" }}
                                    className="img-thumbnail"
                                    src={formData.imageUrl}
                                    alt="article"
                                />
                            )}
                        </>
                    )}
                    {/* <label className="form-control-label font-weight-bold" htmlFor="title">
                        Title (optional):
                    </label>
                    <textarea onChange={onInputChange} value={formData.title} rows="2" id="title" name="title" className="form-control" /> */}

                    <label className="control-label font-weight-bold mt-3" htmlFor="subcategory">
                        Subcategory:<span className="text-danger">*</span>
                    </label>
                    <select defaultValue={formData.subcategory || ""} onChange={onInputChange} name="subcategory" className="custom-select mb-2">
                        <option value="">Select one</option>
                        {categoryNameMap[tag].map(topic => {
                            return (
                                <option key={topic} value={topic}>
                                    {topic}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Cancel
                </button>
                <button className="btn btn-primary" onClick={saveContent}>
                    {isSaving ? (
                        <div className="d-inline-flex">
                            <span className="mx-2 spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Save</span>
                            </span>
                        </div>
                    ) : (
                        "Save"
                    )}
                </button>
            </div>
        </Modal>
    );
}

// prettier-ignore
AddOrUpdateSpecialContentModal.propTypes = {
    type:       PropTypes.oneOf(["new", "edit"]).isRequired,
    tag: PropTypes.oneOf(["fun", "censored"]).isRequired,
    content:    PropTypes.object,
    topicId:    PropTypes.string.isRequired,
    onClose:    PropTypes.func.isRequired,
    onSuccess:  PropTypes.func.isRequired
};

export default AddOrUpdateSpecialContentModal;
