// @ts-check

import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toTimeAgo } from "../../../utils/utils";
import { AddSummaryModal } from "./AddSummaryModal";
import { AiAugmentedStorySummary } from "./AiAugmentedStorySummary";

import { useAuth } from "react-oidc-context";
import { DeleteSummaryModal } from "./DeleteSummaryModal";
import { EditSummary } from "./EditSummary";
import "./aiSummaryTab.css";
import { fetchAiSummary, updateAISummary } from "./useAiSummaryApi";

/**
 * @typedef TopicAiSummary
 * @property {string} id
 * @property {string} aiModel
 * @property {string} aiPromptId
 * @property {boolean} enabled
 * @property {string} createdAt
 */

/**
 * @component
 * @param {object} props
 * @param {string} props.storyId
 * @param {TopicAiSummary[]} props.aiSummaries
 * @param {() => Promise<void>} props.onRefetch
 * @returns
 */
function AiSummary({ storyId, aiSummaries, onRefetch }) {
    const [aiSummaryId, setAiSummaryId] = useState(/** @type {() => string | undefined} */ (() => aiSummaries?.[0]?.id));
    const [aiSummary, setAiSummary] = useState(/** @type {?import("./AiSummaryTypes").AiSummary} */ (null));
    const [isEdit, setIsEdit] = useState(false);

    const { user } = useAuth();

    const summaryOptions = useMemo(
        () =>
            aiSummaries.map(summary => ({
                value: summary.id,
                label: `${toTimeAgo(summary.createdAt, true)}${summary.aiModel ? ` - ${summary.aiModel}` : ""} - ${summary.id}`
            })),
        [aiSummaries]
    );

    const fetchSummary = useCallback(
        /**
         * @param {import("axios").CancelToken=} cancelToken
         */
        async cancelToken => {
            if (!aiSummaryId) {
                setAiSummary(null);
                return;
            }

            if (!user?.access_token) {
                return;
            }

            try {
                const response = await fetchAiSummary({
                    token: user?.access_token,
                    storyId: storyId,
                    summaryId: aiSummaryId,
                    cancelToken
                });
                setAiSummary(response ?? null);
            } catch (e) {
                console.error(e);
            }
        },
        [aiSummaryId, storyId, user?.access_token]
    );

    const toggleEnabled = useCallback(async () => {
        if (!aiSummaryId) {
            return;
        }
        if (!user?.access_token) {
            throw new Error("unauthenticated");
        }
        await updateAISummary({
            token: user?.access_token,
            storyId,
            summaryId: aiSummaryId,
            data: { enabled: !aiSummary?.enabled }
        });
        fetchSummary();
        onRefetch();
    }, [aiSummary?.enabled, aiSummaryId, fetchSummary, storyId, user?.access_token, onRefetch]);

    useEffect(() => {
        if (!aiSummaryId) {
            setAiSummary(null);
            return;
        }

        const source = axios.CancelToken.source();

        fetchSummary(source.token);

        return () => {
            source.cancel();
        };
    }, [aiSummaryId, storyId, fetchSummary]);

    const summaryRef = useRef(/** @type {?import("./EditSummary").SummaryRef} */ (null));
    const [isSaving, setIsSaving] = useState(false);
    const saveEdit = useCallback(async () => {
        const data = summaryRef.current?.getValue();
        if (!aiSummaryId) {
            return;
        }
        if (!user?.access_token) {
            throw new Error("unauthenticated");
        }
        await updateAISummary({
            token: user?.access_token,
            storyId,
            summaryId: aiSummaryId,
            data: {
                summary: data?.summary,
                named_entities: data?.definitions
            }
        });
        await fetchSummary();
        setIsSaving(false);
        setIsEdit(false);
    }, [aiSummaryId, fetchSummary, storyId, user?.access_token]);

    return (
        <div>
            {!summaryOptions?.length && <div>No AI Summaries yet</div>}
            <AddSummaryModal
                storyId={storyId}
                onGenerate={async id => {
                    await onRefetch();
                    setAiSummaryId(id);
                }}
            />
            {!!summaryOptions?.length && (
                <div className="mb-2">
                    <select onChange={e => setAiSummaryId(e.target.value)} value={aiSummaryId} className="dropdown p-1">
                        {summaryOptions.map(({ value, label }) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {aiSummary && (
                <div className="story-summary">
                    <div className="d-flex flex-row align-items-middle mb-2">
                        <h3 className="mb-0">Summary</h3>
                        <div className="d-flex flex-row align-items-middle">
                            {!isEdit ? (
                                <>
                                    <button className={clsx("btn ml-4", aiSummary.enabled ? "btn-danger" : "btn-success")} onClick={toggleEnabled}>
                                        {aiSummary.enabled ? "Disable" : "Enable"}
                                    </button>
                                    <div className="border rounded p-1 ml-3">
                                        <button className="btn btn-secondary btn-sm mr-1" onClick={() => setIsEdit(true)}>
                                            <i className="fas fa-edit"></i>
                                        </button>
                                        <DeleteSummaryModal
                                            summary={aiSummary}
                                            storyId={storyId}
                                            onDeleted={async () => {
                                                await onRefetch();
                                                const otherSummaries = aiSummaries.filter(summary => summary.id !== aiSummaryId);
                                                const enabledSummary = otherSummaries.find(summary => summary.enabled);
                                                setAiSummaryId(enabledSummary?.id ?? otherSummaries[0]?.id);
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <button className={clsx("btn ml-4", aiSummary.enabled ? "btn-primary" : "btn-success")} onClick={saveEdit}>
                                        {isSaving ? "Saving" : "Save"}
                                    </button>
                                    <button className="btn btn-secondary ml-3" onClick={() => setIsEdit(false)}>
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    {!isEdit && (
                        <>
                            <AiAugmentedStorySummary htmlTree={aiSummary.htmlTree} />
                            <h3>Definitions</h3>
                            {aiSummary.entities.map(entity => (
                                <div key={entity.id}>
                                    <h5 className={clsx(aiSummary.summary.indexOf(entity.name) === -1 && "text-danger")}>{entity.name}</h5>
                                    <p>{entity.description}</p>
                                </div>
                            ))}
                        </>
                    )}
                    {isEdit && <EditSummary definitions={aiSummary.entities} summary={aiSummary.summary} summaryRef={summaryRef} />}
                </div>
            )}
            {!aiSummary && !!summaryOptions?.length && <div>Loading...</div>}
        </div>
    );
}

AiSummary.propTypes = {
    aiSummaries: PropTypes.array,
    storyId: PropTypes.string.isRequired
};

export default AiSummary;
