import React, { useState, useCallback } from "react";
import VideoList from "../../bannedContent/videoList";

const tabs = ["Library"];

function VideoLibrary() {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const onTabClick = useCallback(e => {
        const clickedTab = e.target.getAttribute("data-tab-name");
        setSelectedTab(clickedTab);
    }, []);

    return (
        <div className="bg-light pt-3 px-5">
            <ul className="nav">
                {tabs.map(tab => {
                    return (
                        <li key={tab} className="nav-item">
                            <span
                                className={`nav-link pointer px-2 ${selectedTab === tab ? "active text-primary underline" : "text-secondary"}`}
                                data-tab-name={tab}
                                onClick={onTabClick}
                            >
                                {tab}
                            </span>
                        </li>
                    );
                })}
            </ul>
            {selectedTab === "Library" ? <VideoList /> : null}
        </div>
    );
}

export default VideoLibrary;
