// @ts-check

import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import React, { forwardRef, useImperativeHandle } from "react";

import "./tiptap.scss";

const extensions = [StarterKit];
/**
 * For performance reasons, we are using the forwardRef function to forward the ref to the EditorContent component, instead of calling getHTML constantly
 * @typedef TiptapRef
 * @property {() => string | undefined} getHtml;
 */

const Tiptap = forwardRef(
    /**
     * @param {object} props
     * @param {string} props.content
     */
    ({ content }, /** @type {React.Ref<TiptapRef>} */ forwardedRef) => {
        const editor = useEditor(
            {
                extensions,
                content
            },
            []
        );

        useImperativeHandle(forwardedRef, () => ({
            getHtml: () => editor?.getHTML()
        }));

        return (
            <div>
                <MenuBar editor={editor} />
                <EditorContent editor={editor} />
            </div>
        );
    }
);

export default Tiptap;

/**
 * @param {object} props
 * @param {?import("@tiptap/react").Editor} props.editor
 */
const MenuBar = ({ editor }) => {
    if (!editor) {
        return null;
    }

    return (
        <div className="tiptap-menu">
            <button
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={clsx(editor.isActive("paragraph") ? "is-active" : "", "btn btn-sm btn-info")}
            >
                <i className="fas fa-paragraph"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={clsx(editor.isActive("bulletList") ? "is-active" : "", "btn btn-sm btn-info")}
            >
                <i className="fas fa-list-ul"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().undo().run()}
                disabled={!editor.can().chain().focus().undo().run()}
                className="btn btn-sm btn-secondary"
            >
                <i className="fas fa-undo"></i>
            </button>
            <button
                onClick={() => editor.chain().focus().redo().run()}
                disabled={!editor.can().chain().focus().redo().run()}
                className="btn btn-sm btn-secondary"
            >
                <i className="fas fa-redo"></i>
            </button>
        </div>
    );
};
