import axios from "axios";
import { getAuthHeaders, fetchUrlHtmlDocument } from "../utils/utils";
import { BACKEND_URL } from "../config";

// Collect metadata from URL
async function scrapeMetadataFromUrl(url, token) {
    try {
        const payload = { url };

        try {
            const htmlDocString = await fetchUrlHtmlDocument(url);
            if (htmlDocString) {
                payload.rawData = htmlDocString;
            }
        } catch (e) {
            console.error(`scrapeMetadataFromUrl: ${e}`);
        }

        const headers = getAuthHeaders(token);
        const { data } = await axios.post(`${BACKEND_URL}/admin/scrape/metadata`, payload, { headers });
        return data;
    } catch (e) {
        const responseError = (e.response && e.response.data && e.response.data.message) || e.message;
        throw responseError || e;
    }
}

async function scrapeTweetDataFromUrl(url, token) {
    try {
        const headers = getAuthHeaders(token);
        const { data } = await axios.get(`${BACKEND_URL}/admin/scrape/tweet?url=${url}`, { headers });
        return data;
    } catch (e) {
        const responseError = (e.response && e.response.data && e.response.data.message) || e.message;
        throw responseError || e;
    }
}

const APIsList = {
    scrapeMetadataFromUrl,
    scrapeTweetDataFromUrl
};

export default APIsList;
