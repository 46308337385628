import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import * as API from "../../api/storyAPI";
import VideoTile from "../bannedContent/videoTile";
import { useCategoryAccess } from "../contexts/categoryContext";
import "./VideoContentTab.scss";

function VideoContentTab({ storyId, pinnedVideos }) {
    const { fetchCategory } = useCategoryAccess(); // calling this triggers data re-fetching inside category.js component
    const { user } = useAuth();
    const alert = useAlert();

    // API
    const unpinVideo = useCallback(
        videoId => {
            API.unpinVideoFromStory(storyId, videoId, user.access_token)
                .then(() => {
                    alert.show("Success", { timeout: 5000, type: "success" });
                    fetchCategory();
                })
                .catch(e => alert.show(e, { timeout: 5000, type: "error" }));
        },
        [storyId, user.access_token, alert, fetchCategory]
    );

    return (
        <React.Fragment>
            <div className="text-muted">Pinning videos has been deprecated</div>
            {/* <PinVideoContent storyId={storyId} /> */}
            <div className="video-content-wrapper d-flex flex-column justify-content-center">
                {Array.isArray(pinnedVideos) &&
                    pinnedVideos.map(video => {
                        return <VideoTile locationKey={storyId} video={video} showUnpinIcon onUnPinRequest={unpinVideo} key={video.id} />;
                    })}
            </div>
        </React.Fragment>
    );
}

VideoContentTab.propTypes = {
    pinnedVideos: PropTypes.array.isRequired,
    storyId: PropTypes.string.isRequired
};

export default VideoContentTab;
