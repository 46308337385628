import React from "react";
import HantedHouse from "../../static/images/haunted_house.png";
import BatWing from "../../static/images/bat-wing.png";
import BatBody from "../../static/images/bat-body.png";
import "./errors.css";

function Unauthorized() {
    return (
        <div className="error">
            <div className="maincontainer">
                <div className="bat">
                    <img className="wing leftwing" src={BatWing} />
                    <img className="body" src={BatBody} alt="bat" />
                    <img className="wing rightwing" src={BatWing} />
                </div>
                <div className="bat">
                    <img className="wing leftwing" src={BatWing} />
                    <img className="body" src={BatBody} alt="bat" />
                    <img className="wing rightwing" src={BatWing} />
                </div>
                <div className="bat">
                    <img className="wing leftwing" src={BatWing} />
                    <img className="body" src={BatBody} alt="bat" />
                    <img className="wing rightwing" src={BatWing} />
                </div>
                <img className="foregroundimg" src={HantedHouse} alt="haunted house" />
            </div>
            <h1 className="errorcode text-primary">ERROR 403</h1>
            <div className="text-center  text-primary">
                <p>You do not have permission to access this page,</p>
                <p>please refer to your system administrator</p>
            </div>
        </div>
    );
}

export default Unauthorized;
