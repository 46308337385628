import React, { useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { isValidHttpUrl } from "../../../../utils/utils";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "475px"
    }
};

const noImageDataURI = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function UpdateCategoryModal({ category, onClose, onSave }) {
    const [formData, setFormData] = useState(() => ({ name: category.name || "", image_url: category.image_url || "" }));

    const onInputChange = e =>
        e.target.type === "radio" ? setFormData({ ...formData, bias: e.target.name }) : setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSaveBtnClick = () => {
        onSave({
            ...(category.name !== formData.name && { name: formData.name }),
            ...(category.image_url !== formData.image_url && { image_url: formData.image_url })
        });
    };
    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="Update category">
            <div className="modal-body">
                <div className="form-group has-danger">
                    <label className="form-control-label" htmlFor="name">
                        Name:
                    </label>
                    <input onChange={onInputChange} value={formData.name} type="text" id="name" name="name" className="form-control" />
                    <label className="form-control-label mt-2" htmlFor="image_url">
                        Image URL:
                    </label>
                    <input onChange={onInputChange} value={formData.image_url} type="text" id="image_url" name="image_url" className="form-control" />
                </div>
                <div className="position-relative">
                    {!formData.image_url && <span className="position-absolute translate-middle">No image selected</span>}
                    {formData.image_url && !isValidHttpUrl(formData.image_url) && (
                        <span className="position-absolute translate-middle">Invalid image URL</span>
                    )}
                    <img
                        style={{ objectFit: "cover", width: "445px", height: "91px" }}
                        className="img-thumbnail"
                        src={isValidHttpUrl(formData.image_url) ? formData.image_url : noImageDataURI}
                        alt="category"
                    />
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Close
                </button>
                <button className="btn btn-primary" onClick={onSaveBtnClick}>
                    Save changes
                </button>
            </div>
        </Modal>
    );
}

UpdateCategoryModal.propTypes = {
    category: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default UpdateCategoryModal;
