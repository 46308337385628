import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash-es/debounce";

function SearchBar({ className = "", debounceTime = 300, onChange, placeholderText, onSubmit, isSearching }) {
    const [query, setQuery] = useState("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const reportChange = useCallback(
        debounce(q => {
            if (onChange) {
                onChange(q);
            }
        }, debounceTime),
        [debounceTime, onChange]
    );

    const onQueryChange = useCallback(
        e => {
            e.preventDefault();
            setQuery(e.target.value);
            reportChange(e.target.value);
        },
        [reportChange]
    );

    const onSubmitBtnClick = e => {
        e.preventDefault();
        onSubmit(query);
    };

    return (
        <div className={`SearchBar ${className}`}>
            <form className="form-group my-1" onSubmit={onSubmitBtnClick}>
                <div className="input-group">
                    <input
                        autoFocus
                        className="form-control"
                        style={{ minWidth: "" }}
                        type="text"
                        placeholder={placeholderText}
                        onChange={onQueryChange}
                        value={query}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                            {isSearching ? (
                                <div className="mx-3 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                "Search"
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

SearchBar.propTypes = {
    className: PropTypes.string,
    placeholderText: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    isSearching: PropTypes.bool,
    debounceTime: PropTypes.number
};

export default SearchBar;
