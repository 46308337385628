// @ts-check

import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { getAuthHeaders } from "../../../utils/utils";

/**
 * @typedef {object} PostDocumentData
 * @property {string} story_id
 * @property {string} url
 * @property {string} title
 * @property {string=} description
 * @property {string=} date
 * @property {string=} image
 *
 * @param {object} options
 * @param {string} options.token
 * @param {PostDocumentData} options.document
 */
export async function postDocument({ token, document }) {
    const headers = getAuthHeaders(token);
    const response = await axios.post(`${BACKEND_URL}/admin/story/pin/document`, document, { headers });
    return response.data;
}

/**
 * @param {object} options
 * @param {string} options.token
 * @param {string} options.storyId
 * @param {string} options.documentId
 */
export async function deleteDocument({ token, storyId, documentId }) {
    const headers = getAuthHeaders(token);
    const response = await axios.delete(`${BACKEND_URL}/admin/story/pin/document?story_id=${storyId}&document_id=${documentId}`, { headers });
    return response.data;
}

/**
 * @typedef {object} UpdateDocumentData
 * @property {string} document_id
 * @property {string=} title
 * @property {string=} description
 * @property {string=} date
 * @property {string=} image
 *
 * @param {object} options
 * @param {string} options.token
 * @param {UpdateDocumentData} options.document
 */
export async function updateDocument({ token, document }) {
    const headers = getAuthHeaders(token);
    const response = await axios.patch(`${BACKEND_URL}/admin/story/pin/document`, document, { headers });
    return response.data;
}
