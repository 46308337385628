/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { toTimeAgo } from "../../../../../utils/utils";
import Table from "../../../../utils/table";
import ConfirmPopup from "../../../../utils/confirmPopup";

function SnapshotsTable({ stories, type, addAction, removeAction, moveAction }) {
    const alert = useAlert();

    const isEdit = type === "edit";

    const columns = useMemo(
        () => [
            {
                Header: <span style={{ float: "left" }}>Name</span>,
                accessor: "name",
                Cell: row => (
                    <>
                        <p className="d-inline-flex align-middle m-0">
                            {row.data[row.row.index].isActive === false && (
                                <span
                                    onClick={() =>
                                        alert.show("WARNING: This story is not published. It will not be shown on Freespoke website.", {
                                            timeout: 10000,
                                            type: "warning"
                                        })
                                    }
                                    className="ml-2"
                                    title={"WARNING: This story is not published. It will not be shown on Freespoke website."}>
                                    <i className="fas fa-exclamation-triangle text-warning pointer mr-2"></i>
                                </span>
                            )}
                            <strong>{row.value}</strong>
                        </p>
                    </>
                )
            },
            // {
            //     Header: <span style={{ float: "left" }}>Summary</span>,
            //     accessor: "summary",
            //     Cell: row => <p className="d-inline-flex align-middle m-0">{row.value}</p>
            // },
            {
                Header: <span style={{ float: "left", minWidth: "110px" }}>Created</span>,
                accessor: "createdAt",
                Cell: row => <p className="d-inline-flex align-middle m-0 text-secondary">{toTimeAgo(row.value)}</p>
            },
            {
                Header: <span style={{ float: "left" }}>Actions</span>,
                accessor: "_", // this is just a workaround for custom header
                Cell: row => {
                    if (isEdit) {
                        return (
                            <div style={{ minWidth: "80px" }}>
                                <ConfirmPopup onConfirm={() => removeAction(row.data[row.row.index].id)}>
                                    <button className="btn btn-outline-danger btn-sm ml-2">Unlink</button>
                                </ConfirmPopup>
                                <button
                                    onClick={() => moveAction(row.data[row.row.index].id, "up")}
                                    className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === 0 ? "invisible" : ""}`}>
                                    <i className="fas fa-arrow-up"></i>
                                </button>
                                <button
                                    onClick={() => moveAction(row.data[row.row.index].id, "down")}
                                    className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === row.rows.length - 1 ? "invisible" : ""}`}>
                                    <i className="fas fa-arrow-down"></i>
                                </button>
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ minWidth: "80px" }}>
                                <button onClick={() => addAction(row.data[row.row.index].id)} className="btn btn-outline-info btn-sm ml-2">
                                    Add
                                </button>
                            </div>
                        );
                    }
                }
            }
        ],
        [addAction, alert, isEdit, moveAction, removeAction]
    );

    return (
        <div className="SnapshotsTable mt-3">
            {stories && <Table columns={columns} data={stories} />}
            {stories && stories.length === 0 && <p className="text-center">No items</p>}
        </div>
    );
}

SnapshotsTable.propTypes = {
    stories: PropTypes.array.isRequired,
    type: PropTypes.oneOf(["add", "edit"]).isRequired,
    addAction: PropTypes.func,
    removeAction: PropTypes.func,
    moveAction: PropTypes.func
};

export default SnapshotsTable;
