import axios from "axios";
import qs from "query-string";
import { getAuthHeaders } from "../../../utils/utils";
import { BACKEND_URL } from "../../../config";
import { handleAPIError } from "../../../utils/handleApiError";

axios.defaults.paramsSerializer = p => {
    return qs.stringify(p);
};

// Get story pinned Videos
export async function fetchVideosPinnedToAStory({ story_id, page, per_page }, token) {
    try {
        const headers = getAuthHeaders(token);
        const params = { page, per_page };
        const { data } = await axios.get(`${BACKEND_URL}/admin/story/${story_id}/video`, { params, headers });
        return data;
    } catch (e) {
        throw handleAPIError(e);
    }
}

// Create and pin video to a story
export async function createAndPinVideoToAStory(request, token) {
    try {
        const headers = getAuthHeaders(token);
        const { data } = await axios.post(`${BACKEND_URL}/admin/story/pin/video`, request, { headers });
        return data;
    } catch (e) {
        throw handleAPIError(e);
    }
}

export async function updateVideo(request, token) {
    try {
        const headers = getAuthHeaders(token);
        const { data } = await axios.patch(`${BACKEND_URL}/admin/story/pin/video`, request, { headers });
        return data;
    } catch (e) {
        throw handleAPIError(e);
    }
}

// Reposition items associated with a story
export async function reorderVideoContent({ story_id, content_to_move_id, move_after_content_id }, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.post(
            `${BACKEND_URL}/admin/story/content/reorder`,
            { storyId: story_id, contentToMoveId: content_to_move_id, moveAfterContentId: move_after_content_id },
            { headers }
        );
    } catch (e) {
        throw handleAPIError(e);
    }
}

// Unlinks video from a story
export async function unlinkVideoFromAStory({ storyId, videoId }, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.post(`${BACKEND_URL}/admin/content/unlink`, { storyId, videoId }, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}
