// @ts-check

import axios from "axios";
import { BACKEND_URL } from "../../../config";
import { getAuthHeaders } from "../../../utils/utils";

/**
 * @param {object} options
 * @param {string} options.storyId
 * @param {string} options.summaryId
 * @param {string} options.token
 * @param {import("axios").CancelToken=} options.cancelToken
 * @return {Promise<import("./AiSummaryTypes.d.ts").AiSummary | undefined>}
 */
export async function fetchAiSummary({ storyId, summaryId, token, cancelToken }) {
    const headers = getAuthHeaders(token);
    const response = await axios.get(`${BACKEND_URL}/admin/story/${storyId}/ai_summary/${summaryId}`, { headers, cancelToken });
    return response.data;
}

/**
 * @param {object} options
 * @param {string} options.token
 * @returns {Promise<import("./AiSummaryTypes").AiSummaryPrompt[]>}
 */
export async function fetchSummaryPrompts({ token }) {
    const headers = getAuthHeaders(token);
    const response = await axios.get(`${BACKEND_URL}/admin/story/summary_prompts`, { headers });
    return response.data;
}

/**
 * @param {object} options
 * @param {string} options.storyId
 * @param {string} options.promptId
 * @param {string[] | undefined} options.articleIds
 * @param {string} options.token
 * @returns {Promise<import("./AiSummaryTypes").AiSummary>}
 */
export async function generateAISummary({ token, storyId, promptId, articleIds }) {
    const headers = getAuthHeaders(token);
    const response = await axios.post(
        `${BACKEND_URL}/admin/story/${storyId}/ai_summary`,
        { prompt_id: promptId, article_ids: articleIds },
        { headers }
    );
    return response.data;
}

/**
 * @typedef UpdateAISummaryData
 * @property {boolean=} enabled
 * @property {string=} summary
 * @property {import("./AiSummaryTypes").Entity[]=} named_entities
 *
 * @param {object} options
 * @param {string} options.storyId
 * @param {string} options.summaryId
 * @param {string} options.token
 * @param {UpdateAISummaryData} options.data
 * @returns {Promise<import("./AiSummaryTypes").AiSummary>}
 */
export async function updateAISummary({ token, storyId, summaryId, data }) {
    const headers = getAuthHeaders(token);
    const response = await axios.patch(`${BACKEND_URL}/admin/story/${storyId}/ai_summary/${summaryId}`, data, { headers });
    return response.data;
}

/**
 * @param {object} options
 * @param {string} options.storyId
 * @param {string} options.summaryId
 * @param {string} options.token
 * @returns {Promise<void>}
 */
export async function deleteAiSummary({ token, storyId, summaryId }) {
    const headers = getAuthHeaders(token);
    const response = await axios.delete(`${BACKEND_URL}/admin/story/${storyId}/ai_summary/${summaryId}`, { headers });
    return response.data;
}
