/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { useAuth } from "react-oidc-context";
import API from "../../../../../api/deepDiveAPI";
import RelatedTopicsTable from "./relatedTopicsTable";
import AddRelatedTopicsModal from "../../modals/addRelatedTopics";

function RelatedTopicsTab({ topicId, parentRequestedRefetch }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [openRelatedModal, setOpenRelatedModal] = useState(false);
    const [relatedTopics, setRelatedTopics] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchRelatedTopics = useCallback(() => {
        API.getTopicRelatedTopics(
            { topicId, page: 1, perPage: 10, pickFields: ["id", "name", "is_active", "createdAt", "updatedAt", "category"] },
            user.access_token
        )
            .then(data => {
                setRelatedTopics(data && data.topics && data.topics.data);
                // setTotal(data.total); // TODO: Nic - add support for pagination
            })
            .catch(e => alert.show(e))
            .finally(() => setIsFetching(false));
    }, [alert, user.access_token, topicId]);

    useEffect(() => {
        fetchRelatedTopics();
    }, [fetchRelatedTopics, parentRequestedRefetch]);

    const requestRefresh = useCallback(() => fetchRelatedTopics(), [fetchRelatedTopics]);

    const removeTopicAssociation = useCallback(
        itemId => {
            API.unLinkRelatedStoryOrTopicToTopic(topicId, itemId, "topic", user.access_token)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, user.access_token, requestRefresh, topicId]
    );

    const moveTopicPosition = useCallback(
        (itemId, direction) => {
            let topicToMoveId = "";
            let moveAfterId = "";
            const currentIdx = relatedTopics.findIndex(x => x.id === itemId);

            if (direction === "up") {
                topicToMoveId = relatedTopics[currentIdx - 1].id;
                moveAfterId = itemId;
            } else {
                topicToMoveId = itemId;
                moveAfterId = relatedTopics[currentIdx + 1].id;
            }

            const request = { topicId, topicToMoveId, moveAfterId };
            API.reorderRelatedCompositeStory(user.access_token, request)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, user.access_token, relatedTopics, requestRefresh, topicId]
    );

    return (
        <div className="RelatedTopicsTab mt-3">
            {openRelatedModal ? (
                <AddRelatedTopicsModal
                    topicId={topicId}
                    content={relatedTopics}
                    onSuccess={requestRefresh}
                    onClose={() => setOpenRelatedModal(false)}
                />
            ) : (
                <div className="d-flex justify-content-center mt-2">
                    <span onClick={() => setOpenRelatedModal(true)} className="h4 pointer">
                        <i className="fas fa-plus"></i>
                    </span>
                </div>
            )}
            {relatedTopics && relatedTopics.length > 0 && (
                <div className="tab-pane">
                    <RelatedTopicsTable
                        type="edit"
                        deepDiveTopics={relatedTopics}
                        removeAction={removeTopicAssociation}
                        moveAction={moveTopicPosition}
                    />
                </div>
            )}
            {isFetching ? (
                <div className="mx-3 spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                relatedTopics && relatedTopics.length === 0 && <p className="text-center mt-3">No related topics</p>
            )}
        </div>
    );
}

RelatedTopicsTab.propTypes = {
    topicId: PropTypes.string.isRequired,
    parentRequestedRefetch: PropTypes.number.isRequired
};

export default RelatedTopicsTab;
