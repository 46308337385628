import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { useAlert } from "react-alert";
import PaginationV2 from "../utils/paginationV2";
import isValidHostname from "is-valid-hostname";
import SearchBar from "../utils/searchBar";
import { getCrawlerSources } from "../../api/crawlerAPI";

function CrawlerFind(props) {
    const { user } = useAuth();

    const alert = useAlert();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [sources, setSources] = useState(null);
    const [domain, setDomain] = useState("");
    const [total, setTotal] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const fetchSources = useCallback(
        q => {
            if (!q || !isValidHostname(q)) {
                if (q?.startsWith("https://") || q?.startsWith("https://")) {
                    alert.show("Invalid input. Domain should not include http:// or https://");
                } else {
                    alert.show("Invalid input");
                }
                return;
            }
            setIsRefreshing(true);
            setSources(null);
            getCrawlerSources(q, currentPage, user.access_token)
                .then(data => {
                    setIsRefreshing(true);
                    setSources(data?.sources);
                    setTotal(data?.resultCount);
                })
                .catch(e => {
                    alert.show(e);
                })
                .finally(() => setIsRefreshing(false));
        },
        [currentPage, user.access_token, alert]
    );

    useEffect(() => {
        if (domain) {
            fetchSources(domain);
        }
    }, [currentPage, domain, fetchSources]);

    const changePage = async data => {
        setCurrentPage(data.selected + 1);

        const body = document.querySelector("#SourcesList");

        body.scrollIntoView(
            {
                behavior: "smooth"
            },
            500
        );
    };

    return (
        <div className="CrawlerSourcesList">
            <h4 className="d-inline align-top">Crawler Sources</h4>
            <SearchBar className="mt-2" isSearching={isRefreshing} onSubmit={q => setDomain(q)} placeholderText="Enter a domain name" />

            <nav>
                <PaginationV2
                    initialPage={currentPage}
                    forcePage={currentPage}
                    total={parseInt(total)}
                    perPageCount={20}
                    onPageChange={changePage}
                    onPerPageChange={changePage}
                />
                {/* {pageCount > 1 ? (
                    <ReactPaginate
                        forcePage={currentPage}
                        pageCount={pageCount}
                        // initialPage={0}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={changePage}
                        containerClassName={"pagination d-flex justify-content-end"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"page-item active z-index-0"}
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        disableInitialCallback={true}
                    />
                ) : null} */}
            </nav>

            <div className="results" id="SourcesList">
                {domain != "" ? (
                    <h4>
                        {total} {total == 1 ? "source" : "sources"} for {domain}
                    </h4>
                ) : (
                    ""
                )}
                {sources && sources.length > 0 ? (
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Domain</th>
                                <th scope="col">Source URL</th>
                                <th scope="col">Next Fetch Date</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sources
                                ? sources.map(src => {
                                      return <SourceRow key={src.id} src={src} user={props.user} />;
                                  })
                                : null}
                        </tbody>
                    </table>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

function SourceRow(props) {
    return (
        <tr key={props.src.id}>
            <td scope="col">{props?.src?.metadata?.hostname}</td>
            <td scope="col">{props?.src?.url}</td>
            <td scope="col">{props?.src?.nextFetchDate}</td>
            <td scope="col">{props?.src?.status}</td>
        </tr>
    );
}

export default CrawlerFind;
