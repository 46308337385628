import React from "react";
import ArticleList from "../../topicContent/articleList";

function FunArticlesPage() {
    return (
        <div className="bg-light pt-3 px-5">
            <h4>Best of the Web</h4>
            <ArticleList tag="fun" />
        </div>
    );
}

export default FunArticlesPage;
