import React, { useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import { isSpangldURL } from "../../../../utils/utils";
import API from "../../../../api/deepDiveAPI";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "475px"
    }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function SpangldModal({ type, spangld, topicId, onClose, onSuccess }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState(() => ({ url: (spangld && spangld.url) || "" }));

    const saveContent = () => {
        if (!isSpangldURL(formData.url)) {
            return alert.show("Invalid URL: Only URL with `spangld.com' domain allowed");
        }
        setIsSaving(true);
        if (type === "edit") {
            API.updateTopicContent(formData, spangld.id, "spangld", topicId, user.access_token)
                .then(() => onSuccess())
                .catch(e => alert.show(e))
                .finally(() => setIsSaving(false));
        } else {
            API.postNewTopicItem({ topicId, url: formData.url }, "spangld", user.access_token)
                .then(() => onSuccess())
                .catch(e => alert.show(e))
                .finally(() => setIsSaving(false));
        }
    };

    const onInputChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="Update category">
            <div className="modal-body">
                <div className="form-group has-danger">
                    <label className="form-control-label" htmlFor="url">
                        URL:
                    </label>
                    <div className="input-group mb-3">
                        <input onChange={onInputChange} value={formData.url} type="text" id="url" name="url" className="form-control" />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Cancel
                </button>
                <button className="btn btn-primary" onClick={saveContent}>
                    {isSaving ? (
                        <div className="d-inline-flex">
                            <span className="mx-2 spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Save</span>
                            </span>
                        </div>
                    ) : (
                        "Save"
                    )}
                </button>
            </div>
        </Modal>
    );
}

SpangldModal.propTypes = {
    type: PropTypes.oneOf(["new", "edit"]).isRequired,
    spangld: PropTypes.object,
    topicId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default SpangldModal;
