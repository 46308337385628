/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { formatDistanceToNowStrict } from "date-fns";
import ConfirmPopup from "../../../../utils/confirmPopup";
import Table from "../../../../utils/table";

function RelatedTopicsTable({ deepDiveTopics, type, addAction, removeAction, moveAction }) {
    const isEdit = type === "edit";
    // const getLinkedCategories = category => {
    //     if (category && !category.title) {
    //         return (
    //             <span style={{ padding: ".6em" }} className="badge badge-pill badge-danger">
    //                 Invalid linked category
    //             </span>
    //         );
    //     }
    //     if (!category) {
    //         return (
    //             <span style={{ padding: ".6em" }} className="badge badge-pill badge-secondary">
    //                 No linked category
    //             </span>
    //         );
    //     }
    //     return (
    //         <>
    //             <Link style={{ marginBottom: ".3em" }} key={category.id} to={`/deep-dive/category/${category.id}`} className="btn btn-info btn-sm mr-2">
    //                 {category.title}
    //             </Link>
    //         </>
    //     );
    // };
    const columns = useMemo(
        () => [
            {
                Header: <span style={{ float: "left" }}>Name</span>,
                accessor: "name",
                Cell: row => (
                    <Link className="text-primary" to={`/deep-dive/topic/${row.data[row.row.index].id}`}>
                        {row.value}
                    </Link>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Updated</span>,
                accessor: "updatedAt",
                Cell: row => formatDistanceToNowStrict(new Date(row.value), { addSuffix: true })
            },
            {
                Header: <span style={{ float: "left" }}>Created</span>,
                accessor: "createdAt",
                Cell: row => formatDistanceToNowStrict(new Date(row.value), { addSuffix: true })
            },
            // {
            //     Header: <span style={{ float: "left" }}>Linked to Category</span>,
            //     accessor: "category",
            //     Cell: row => getLinkedCategories(row.value)
            // },
            {
                Header: <span style={{ float: "left" }}>Actions</span>,
                accessor: "_", // this is just a workaround for custom header
                Cell: row => {
                    if (isEdit) {
                        return (
                            <div style={{ minWidth: "180px" }}>
                                <ConfirmPopup onConfirm={() => removeAction(row.data[row.row.index].id)}>
                                    <button className="btn btn-outline-danger btn-sm ml-1">Unlink</button>
                                </ConfirmPopup>
                                <button
                                    onClick={() => moveAction(row.data[row.row.index].id, "up")}
                                    className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === 0 ? "invisible" : ""}`}
                                >
                                    <i className="fas fa-arrow-up"></i>
                                </button>
                                <button
                                    onClick={() => moveAction(row.data[row.row.index].id, "down")}
                                    className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === row.rows.length - 1 ? "invisible" : ""}`}
                                >
                                    <i className="fas fa-arrow-down"></i>
                                </button>
                            </div>
                        );
                    } else {
                        return (
                            <div style={{ minWidth: "180px" }}>
                                <button onClick={() => addAction(row.data[row.row.index].id)} className="btn btn-outline-info btn-sm ml-2">
                                    Add
                                </button>
                            </div>
                        );
                    }
                }
            }
        ],
        [addAction, isEdit, moveAction, removeAction]
    );
    return <div className="RelatedTopicsTable mt-3">{deepDiveTopics && <Table columns={columns} data={deepDiveTopics} />}</div>;
}

RelatedTopicsTable.propTypes = {
    deepDiveTopics: PropTypes.array,
    type: PropTypes.oneOf(["add", "edit"]).isRequired,
    addAction: PropTypes.func,
    removeAction: PropTypes.func,
    moveAction: PropTypes.func
};

export default RelatedTopicsTable;
