import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import AppInfoPopup from "../components/utils/appInfoPopup";
import { ENV } from "../config.js";

function Header() {
    const auth = useAuth();

    const logout = useCallback(() => {
        auth?.signoutRedirect({ redirectUri: window.location.origin });
        auth?.removeUser();
    }, [auth]);

    return (
        <nav className="navbar navbar-dark bg-primary header p-0">
            <div className="pl-3">
                <Link className="navbar-brand" to="/">
                    <span className="logo text-light">FREESPOKE Admin Center ({ENV})</span>
                </Link>
                <AppInfoPopup>
                    <span>
                        <i className="fas fa-info-circle text-info pointer"></i>
                    </span>
                </AppInfoPopup>
            </div>
            <div onClick={logout} className="float-right text-light pr-4 pointer">
                <span>LOGOUT</span>
            </div>
        </nav>
    );
}

export default Header;
