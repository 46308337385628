import React, { useState, useEffect, useCallback, useMemo } from "react";
import QueryString from "query-string";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import { getAuthHeaders } from "../../utils/utils";
import { BACKEND_URL, PROXY_URL, PROXY_AUTH_KEY } from "../../config";
import { OutTable, ExcelRenderer } from "../utils/excelRenderer";
import "./twitterReportPreview.css";

const EXCEL_COLUMNS = [
    { name: "A", key: 0 },
    // { name: 'B', key: 1 },
    { name: "C", key: 2 },
    // { name: 'D', key: 3 },
    // { name: 'E', key: 4 },
    // { name: 'F', key: 5 },
    { name: "G", key: 6 },
    { name: "H", key: 7 },
    { name: "I", key: 8 }
    // { name: 'J', key: 9 },
    // { name: 'K', key: 10 },
    // { name: 'L', key: 11 },
];

function TwitterReportPreview() {
    const { user } = useAuth();
    const alert = useAlert();
    const location = useLocation();
    const reportId = useMemo(() => QueryString.parse(location.search).id, [location.search]);
    const [excelState, setExcelState] = useState(null);

    const getReportAccessURL = useCallback(async () => {
        const headers = getAuthHeaders(user.access_token);
        const { data } = await axios.get(`${BACKEND_URL}/admin/reports/twitter/url?id=${reportId}`, { headers });
        return data.url;
    }, [user.access_token, reportId]);

    const downloadReport = useCallback(async () => {
        try {
            const url = await getReportAccessURL(reportId);

            if (!url) throw new Error("Could not get report access URL");

            const headers = { Authorization: `Basic ${PROXY_AUTH_KEY}` };
            const { data } = await axios.get(`${PROXY_URL}?address=${url}`, { headers, responseType: "blob" });

            ExcelRenderer(data, (err, resp) => {
                if (err) {
                    console.error(err);
                    alert.show(`Error: ${err.message}`, { timeout: 1000, type: "error" });
                } else {
                    setExcelState({
                        cols: EXCEL_COLUMNS,
                        rows: resp.rows
                    });
                }
            });
        } catch (e) {
            console.error(e);
            alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" });
        }
    }, [alert, getReportAccessURL, reportId]);

    useEffect(() => {
        downloadReport();
    }, [downloadReport]);

    return (
        <div className="p-4 bg-light">
            <h4>Twitter Report Preview:</h4>
            <h5>
                File name: <span className="text-secondary">{reportId}</span>
            </h5>
            {excelState ? (
                <div className="restrict-card">
                    <OutTable
                        withZeroColumn
                        withoutRowNum={false}
                        data={excelState.rows}
                        columns={excelState.cols}
                        tableClassName="ExcelTable2007"
                        tableHeaderRowClass="heading"
                    />
                </div>
            ) : (
                <div className="mx-auto spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )}
        </div>
    );
}

export default TwitterReportPreview;
