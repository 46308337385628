import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";

function PrivateRoute({ children }) {
    const auth = useAuth();
    const isPlaywrightTestRunner = navigator.userAgent.toLowerCase().includes("playwright");

    useEffect(() => {
        // the `return` is important - addAccessTokenExpiring() returns a cleanup function
        return auth.events.addAccessTokenExpiring(() => {
            // if (alert("You're about to be signed out due to inactivity. Press continue to stay signed in.")) {
            auth.signinSilent();
            // }
        });
    }, [auth, auth.events, auth.signinSilent]);

    if (auth.isLoading) {
        return (
            <div className="d-flex mt-2 w-100 h-100 text-center justify-content-center align-items-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    if (auth.error) {
        return (
            <div className="d-flex mt-2 w-100 h-100 text-center justify-content-center align-items-center">
                <p>Oops... {auth.error.message}</p>
            </div>
        );
    }

    if (auth.isAuthenticated) {
        return children;
    }

    return (
        <div className="d-flex mt-2 w-100 h-100 text-center justify-content-center align-items-center">
            <button
                data-testid="login-button"
                onClick={() => void auth.signinRedirect(isPlaywrightTestRunner ? undefined : { extraQueryParams: { kc_idp_hint: "microsoft" } })}
            >
                Log in
            </button>
        </div>
    );
}

export default PrivateRoute;
