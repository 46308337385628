import axios from "axios";
import { getAuthHeaders } from "../utils/utils";
import { BACKEND_URL } from "../config";
import { getAxiosError } from "./errors";

// Get article similarities
export async function getSimilarityListAPI(token, articlesList, options) {
    try {
        const headers = getAuthHeaders(token);
        const url = `${BACKEND_URL}/admin/similarity/list`;
        const { minSentenceSimilarity, maxSentencesByCluster } = options;
        const requestData = { articlesList, minSentenceSimilarity, maxSentencesByCluster };
        const { data } = await axios.post(url, requestData, { headers });
        return data;
    } catch (e) {
        throw getAxiosError(e);
    }
}
