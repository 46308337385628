// @ts-check

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

/**
 * @type {React.CSSProperties}
 */
const customStyles = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "90vh",
    overflowY: "auto",
    zIndex: 9999
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement("#modal");

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {React.CSSProperties=} props.styles
 * @param {string=} props.label
 * @param {boolean} props.isOpen
 * @param {string=} props.portalClassName
 * @param {string=} props.closeClassName
 * @param {() => void} props.onClose
 * @returns
 */
function Modal({ children, styles, label, isOpen, onClose, portalClassName, closeClassName }) {
    return (
        <>
            <ReactModal
                isOpen={isOpen}
                onRequestClose={onClose}
                style={{ content: { ...customStyles, ...styles } }}
                contentLabel={label}
                onAfterOpen={() => (document.body.style.overflow = "hidden")}
                onAfterClose={() => (document.body.style.overflow = "unset")}
                portalClassName={portalClassName}
            >
                <span
                    onClick={onClose}
                    className={clsx("pointer", closeClassName)}
                    style={{ position: "fixed", top: "0.5rem", right: "1.5rem", fontSize: 36 }}
                >
                    &times;
                </span>
                {children}
            </ReactModal>
        </>
    );
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    styles: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default Modal;
