import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import RelatedTopicsTable from "../../topic/tabs/relatedTopics/relatedTopicsTable";
import API from "../../../../api/deepDiveAPI";
import PaginationBar from "../../../utils/pagination";
import SearchBar from "../../../utils/searchBar";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "1024px"
    }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function AddRelatedTopicsModal({ topicId, content, onClose, onSuccess }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [q, setQuery] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(() => {
        const customSetting = localStorage.getItem("RELATED_TOPICS_PER_PAGE");
        return parseInt(customSetting) || 20;
    });
    const [total, setTotal] = useState(0);
    const [deepDiveTopics, setDeepDiveTopics] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const fetchDeepDiveTopics = useCallback(async () => {
        setIsFetching(true);
        API.getTopicsList(user.access_token, { q, perPage, page }, "deep_dive_topic")
            .then(data => {
                setDeepDiveTopics(data.data.filter(topic => content.findIndex(x => x.id === topic.id) === -1));
                setTotal(data.total);
            })
            .catch(e => alert.show(e))
            .finally(() => setIsFetching(false));
    }, [alert, content, user.access_token, page, perPage, q]);

    useEffect(() => {
        fetchDeepDiveTopics();
    }, [q, page, perPage, fetchDeepDiveTopics]);

    useEffect(() => {
        setDeepDiveTopics(deepDiveTopics.filter(topic => content.findIndex(x => x.id === topic.id) === -1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    const searchBarInputChange = useCallback(q => setQuery(q), []);

    const addTopicLink = useCallback(
        dataId => {
            setIsFetching(true);
            API.linkRelatedStoryOrTopicToTopic(topicId, dataId, "topic", user.access_token)
                .then(() => onSuccess())
                .catch(e => alert.show(e))
                .finally(() => setIsFetching(false));
        },
        [alert, user.access_token, onSuccess, topicId]
    );

    const onPerPageChange = useCallback(e => {
        const perPageNum = parseInt(e.target.value);
        localStorage.setItem("RELATED_TOPICS_PER_PAGE", perPageNum);
        setPerPage(perPageNum);
    }, []);

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="Add Related Deep Dive Topics">
            <div className="modal-body" style={{ minHeight: "calc(100vh - 210px)" }}>
                <SearchBar
                    className="mt-2 mb-2"
                    isSearching={isFetching}
                    onChange={searchBarInputChange}
                    onSubmit={searchBarInputChange}
                    placeholderText="Search topics ..."
                />
                {deepDiveTopics && deepDiveTopics.length ? (
                    <PaginationBar
                        total={total}
                        page={page}
                        perPage={perPage}
                        onClick={selectedPage => setPage(selectedPage)}
                        onPerPageChange={onPerPageChange}
                    />
                ) : null}
                {deepDiveTopics && deepDiveTopics.length ? (
                    <RelatedTopicsTable type="add" deepDiveTopics={deepDiveTopics} addAction={addTopicLink} />
                ) : (
                    <div className="col-xs-12 text-center" style={{ padding: "1em" }}>
                        {isFetching ? (
                            <div className="m-auto spinner-border spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            <p>No items found</p>
                        )}
                    </div>
                )}
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Close
                </button>
            </div>
        </Modal>
    );
}

AddRelatedTopicsModal.propTypes = {
    content: PropTypes.array,
    topicId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default AddRelatedTopicsModal;
