import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

function Pagination({ initialPage, forcePage, total, perPageCount, onPageChange, onPerPageChange }) {
    return (
        <div>
            <ReactPaginate
                initialPage={initialPage - 1}
                forcePage={forcePage - 1}
                pageCount={Math.ceil(total / perPageCount)}
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={onPageChange}
                containerClassName={"pagination d-inline-flex mr-2 my-0"}
                subContainerClassName={"pages pagination"}
                activeClassName={"page-item active z-index-0"}
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                breakLinkClassName="page-link"
                disableInitialCallback={true}
            />
            <div className="d-inline-flex per-page form-group float-right my-0">
                <select onChange={onPerPageChange} id="perPageSelect" value={perPageCount} className="custom-select d-inline">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    {/* <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option> */}
                </select>
            </div>
        </div>
    );
}

Pagination.propTypes = {
    initialPage: PropTypes.number.isRequired,
    forcePage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    perPageCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onPerPageChange: PropTypes.func.isRequired
};

export default Pagination;
