import axios from "axios";
import qs from "query-string";
import { BACKEND_URL } from "../config";
import { getAuthHeaders } from "../utils/utils";
axios.defaults.paramsSerializer = p => {
    return qs.stringify(p);
};

/******** CATEGORY **********/
// Create new deep dive category
async function postNewDeepDiveCategory(data, token) {
    try {
        const headers = getAuthHeaders(token);
        const tagsList = ["deep_dive_topic_category"];
        await axios.post(`${BACKEND_URL}/admin/topic`, { ...data, tagsList }, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        if (responseError && responseError.toLowerCase().includes("duplicate key")) {
            throw new Error("Cannot use this name. A category with this name already exists");
        }
        throw responseError || e.toString();
    }
}

// Get list of deep dive categories
async function getAllDeepDiveCategories(token) {
    try {
        const headers = getAuthHeaders(token);
        const params = { page: 1, perPage: 10, pickFields: ["id", "name", "is_active", "updatedAt"], tagsList: ["deep_dive_topic_category"] };
        const { data } = await axios.get(`${BACKEND_URL}/admin/topic/list`, { params, headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Get deep dive category by ID
async function getDeepDiveCategoryById(categoryId, token) {
    try {
        const headers = getAuthHeaders(token);
        const params = { pickFields: ["id", "name", "is_active", "image_url", "updatedAt"] };
        const { data } = await axios.get(`${BACKEND_URL}/admin/topic/${categoryId}`, { params, headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

/******** TOPICS **********/
// Create new deep dive topic
async function postNewDeepDiveTopic(data, token, tag) {
    try {
        const headers = getAuthHeaders(token);
        const tagsList = tag || ["deep_dive_topic"];
        await axios.post(`${BACKEND_URL}/admin/topic`, { ...data, tagsList }, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        if (responseError && responseError.toLowerCase().includes("duplicate key")) {
            throw new Error("Cannot use this name. A topic with this name already exists");
        }
        throw responseError || e.toString();
    }
}

async function linkRelatedStoryOrTopicToTopic(parentId, idToLink, type, token) {
    try {
        const headers = getAuthHeaders(token);
        const data = { parentId, [`${type}Id`]: idToLink };
        await axios.post(`${BACKEND_URL}/admin/topic/link`, data, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        if (responseError && responseError.toLowerCase().includes("duplicate key")) {
            throw new Error("Cannot use this name. A topic with this name already exists");
        }
        throw responseError || e.toString();
    }
}
async function unLinkRelatedStoryOrTopicToTopic(parentId, idToLink, type, token) {
    try {
        const headers = getAuthHeaders(token);
        const data = { parentId, [`${type}Id`]: idToLink };
        await axios.post(`${BACKEND_URL}/admin/topic/link/delete`, data, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        if (responseError && responseError.toLowerCase().includes("duplicate key")) {
            throw new Error("Cannot use this name. A topic with this name already exists");
        }
        throw responseError || e.toString();
    }
}

// Get list of deep dive topics
async function getTopicsList(token, options, tag) {
    try {
        const headers = getAuthHeaders(token);
        const params = { ...options, tagsList: tag, pickFields: ["id", "name", "is_active", "createdAt", "updatedAt", "category"] };
        const { data } = await axios.get(`${BACKEND_URL}/admin/topic/list`, { params, headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Get list of deep dive topics linked to a specific category
async function getLinkedDeepDiveTopics({ relatedTopicId, parentIdsList, page, perPage, pickFields }, token) {
    try {
        const headers = getAuthHeaders(token);
        const defaultPickFields = ["id", "name", "is_active", "createdAt", "updatedAt"];
        const params = { relatedTopicId, parentIdsList, page, perPage, pickFields: pickFields || defaultPickFields };
        const { data } = await axios.get(`${BACKEND_URL}/admin/topic/list`, { params, headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Get topic related topics
async function getTopicRelatedTopics({ topicId, page, perPage }, token) {
    try {
        const headers = getAuthHeaders(token);
        const params = { topicId, page, perPage, include: "topics" };
        const { data } = await axios.get(`${BACKEND_URL}/admin/topic/related/list`, { params, headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Get topic related stories
async function getTopicRelatedStories({ topicId, page, perPage }, token) {
    try {
        const headers = getAuthHeaders(token);
        const params = { topicId, page, perPage, include: "stories" };
        const { data } = await axios.get(`${BACKEND_URL}/admin/topic/related/list`, { params, headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Get deep dive topic by ID
async function getDeepDiveTopicById(topicId, token) {
    try {
        const headers = getAuthHeaders(token);
        const params = {
            pickFields: ["id", "name", "is_active", "image_url", "updatedAt", "news", "spangld", "related_anso", "other_content", "featured_news"]
        };
        const { data } = await axios.get(`${BACKEND_URL}/admin/topic/${topicId}`, { params, headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Update deep dive topic or category
async function updateDeepDiveTopicOrCategory(data, topicId, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.patch(`${BACKEND_URL}/admin/topic/${topicId}`, data, { headers });
    } catch (e) {
        const responseError = e.response && e.response.message;
        throw responseError || e.toString();
    }
}

// Delete deep dive topic
async function DeleteDeepDiveTopic(topicId, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.delete(`${BACKEND_URL}/admin/topic/${topicId}`, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

/******** TOPIC ITEMS **********/

// Get topic content by type
async function getContentByType(token, topicId, contentType) {
    try {
        const headers = getAuthHeaders(token);
        const params = { topicId, tagsList: [contentType] };
        const response = await axios.get(`${BACKEND_URL}/admin/content/list`, { params, headers });
        return response && response.data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Add new content to a deep dive topic
async function postNewTopicItem(data, contentType, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.post(`${BACKEND_URL}/admin/content/${contentType}`, data, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Link existing anso article to a deep dive topic
async function linkAnsoArticleToTopic({ topicId, articleId }, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.post(`${BACKEND_URL}/admin/content/link`, { topicId, articleId, type: "deep_dive_anso" }, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Unlink content from a topic
async function unlinkTopicItem(contentId, contentType, topicId, token) {
    try {
        const headers = getAuthHeaders(token);
        const data = { topicId };
        if (contentType === "tweet") {
            data.tweetId = contentId;
        } else if (contentType === "spangld") {
            data.spangldId = contentId;
        } else if (contentType === "article") {
            data.articleId = contentId;
        }
        await axios.post(`${BACKEND_URL}/admin/content/unlink`, data, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Update topic content by ID
async function updateTopicContent(data, contentType, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.patch(`${BACKEND_URL}/admin/content/${contentType}`, data, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Reposition items associated items with a deep dive topic
async function reorderTopicContent(token, { topicId, contentToMoveId, moveAfterContentId }) {
    try {
        const headers = getAuthHeaders(token);
        const data = { topicId, contentToMoveId, moveAfterContentId };
        await axios.post(`${BACKEND_URL}/admin/topic/content/reorder`, data, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Get existing stories for topic content
async function getStories(token, { q, perPage, page, parentIdsList, relatedTopicId }) {
    try {
        const headers = getAuthHeaders(token);
        const params = { q, perPage, page, parentIdsList, relatedTopicId };
        const { data } = await axios.get(`${BACKEND_URL}/admin/story/list`, { params, headers });
        return data;
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

/******** CATEGORY-TOPIC LINKING **********/
// relink DD topic from one category to another
const relinkTopicToCategory = async (topic_id, category_id, token) => {
    try {
        const headers = getAuthHeaders(token);
        await axios.post(`${BACKEND_URL}/admin/topic/relink`, { topic_id, category_id }, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
};

// Reposition topics for a deep dive category
async function reorderTopicChildTopic(parentTopicId, topicToMoveId, moveAfterTopicId, token) {
    try {
        const headers = getAuthHeaders(token);
        await axios.post(`${BACKEND_URL}/admin/topic/reorder`, { parentTopicId, topicToMoveId, moveAfterTopicId }, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

// Reposition topics for a deep dive category
async function reorderRelatedCompositeStory(token, { storyId, topicId, topicToMoveId, storyToMoveId, moveAfterId }) {
    try {
        const headers = getAuthHeaders(token);
        await axios.post(`${BACKEND_URL}/admin/topic/reorder/related`, { storyId, topicId, topicToMoveId, storyToMoveId, moveAfterId }, { headers });
    } catch (e) {
        const responseError = e.response && e.response.data && e.response.data.message;
        throw responseError || e.toString();
    }
}

const APIsList = {
    // Categories
    postNewDeepDiveCategory,
    getAllDeepDiveCategories,
    getDeepDiveCategoryById,
    updateDeepDiveTopicOrCategory,
    // Topics
    getContentByType,
    postNewDeepDiveTopic,
    linkRelatedStoryOrTopicToTopic,
    unLinkRelatedStoryOrTopicToTopic,
    getTopicsList,
    getDeepDiveTopicById,
    getLinkedDeepDiveTopics,
    getTopicRelatedTopics,
    getTopicRelatedStories,
    DeleteDeepDiveTopic,
    // Topic Items
    postNewTopicItem,
    linkAnsoArticleToTopic,
    unlinkTopicItem,
    updateTopicContent,
    reorderTopicContent,
    getStories,
    // Category-topic linking
    relinkTopicToCategory,
    reorderTopicChildTopic,
    reorderRelatedCompositeStory
};

export default APIsList;
