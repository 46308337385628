import React, { useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import API from "../../../../api/deepDiveAPI";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "475px"
    }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function AnsoModal({ topicId, onClose, onSuccess }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState(() => ({ articleId: "" }));

    const saveContent = () => {
        setIsSaving(true);
        API.linkAnsoArticleToTopic(
            {
                articleId: formData.articleId,
                topicId: topicId
            },
            user.access_token
        )
            .then(() => onSuccess())
            .catch(e => alert.show(e))
            .finally(() => setIsSaving(false));
    };

    const onInputChange = e => {
        if (e.target.type === "radio") {
            setFormData({ ...formData, bias: e.target.name });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="Update category">
            <div className="modal-body">
                <div className="form-group has-danger">
                    <label className="form-control-label" htmlFor="articleId">
                        Censored Story ID:
                    </label>
                    <div className="input-group mb-3">
                        <input
                            onChange={onInputChange}
                            value={formData.id}
                            type="text"
                            id="articleId"
                            name="articleId"
                            className="form-control"
                            placeholder="example: 123abc"
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Cancel
                </button>
                <button className="btn btn-primary" onClick={saveContent}>
                    {isSaving ? (
                        <div className="d-inline-flex">
                            <span className="mx-2 spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </span>
                        </div>
                    ) : (
                        "Link"
                    )}
                </button>
            </div>
        </Modal>
    );
}

AnsoModal.propTypes = {
    type: PropTypes.oneOf(["new"]).isRequired,
    topicId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default AnsoModal;
