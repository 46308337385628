import { formatDistanceToNowStrict } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import { useHistory, useLocation, useParams } from "react-router-dom";
import API from "../../../api/deepDiveAPI";
import ConfirmPopup from "../../utils/confirmPopup";
import "./deepDiveTopic.scss";
import ContentTabs from "./deepDiveTopicTabs";
import UpdateTopicModal from "./updateTopicModal";

// used to hide some functionality - like topic deletion
const SPECIAL_TOPICS_ID_LIST = ["9H2eDWDn0lCr41Vcy3PZj", "P0wwzoZVMoYXpSIle0vNV", "sfF9UjQ4saQrlglrhlA9T", "4U45FKs_6EUsTLJf4fa3Q"];

function DeepDiveTopic() {
    const alert = useAlert();
    const { pathname } = useLocation();
    const { user } = useAuth();

    const { topic_id } = useParams();
    const history = useHistory();
    const [topic, setTopic] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [needToRefetchChildren, setNeedToRefetchChildren] = useState(1); // used to trigger refetch on some children components

    const isKnowledgePanelPage = pathname.startsWith("/knowledge-panel");
    const pathnamePrefix = isKnowledgePanelPage ? "/knowledge-panel" : "/deep-dive";

    const fetchTopic = useCallback(() => {
        setIsLoading(true);
        API.getDeepDiveTopicById(topic_id, user.access_token)
            .then(data => {
                setTopic(data);
                setNeedToRefetchChildren(needToRefetchChildren + 1);
            })
            .catch(e => alert.show(e))
            .finally(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topic_id, user.access_token, alert]);

    // Fetch data on mount
    useEffect(() => fetchTopic(), [fetchTopic]);

    // When users clicks on another related topic link
    useEffect(() => fetchTopic(), [fetchTopic, topic_id]);

    const updateDeepDiveTopicOrCategory = useCallback(
        fieldsToUpdate => {
            setIsLoading(true);
            API.updateDeepDiveTopicOrCategory(fieldsToUpdate, topic?.id, user.access_token)
                .then(() => fetchTopic())
                .catch(e => {
                    alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" });
                    setIsLoading(false);
                });
        },
        [alert, fetchTopic, user.access_token, topic?.id]
    );

    const onActiveInputChange = useCallback(
        async e => {
            if (e.target.type === "checkbox") {
                const fieldsToUpdate = { [e.target.name]: e.target.checked };
                updateDeepDiveTopicOrCategory(fieldsToUpdate);
            }
        },
        [updateDeepDiveTopicOrCategory]
    );

    const onSave = useCallback(
        fieldsToUpdate => {
            updateDeepDiveTopicOrCategory(fieldsToUpdate);
            setOpenEditModal(false);
        },
        [updateDeepDiveTopicOrCategory]
    );

    const deleteTopic = useCallback(() => {
        setIsLoading(true);
        API.DeleteDeepDiveTopic(topic?.id, user.access_token)
            .then(() => history.push(`${pathnamePrefix}/topics`))
            .catch(e => {
                alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" });
                setIsLoading(false);
            });
    }, [alert, history, user.access_token, pathnamePrefix, topic?.id]);

    return (
        <div className="DeepDiveTopic pb-5">
            {/* UPDATE TOPIC MODAL */}
            {openEditModal && <UpdateTopicModal topic={topic} onClose={() => setOpenEditModal(false)} onSave={onSave} />}
            <div className="d-flex align-items-center">
                <button onClick={() => history.push(`${pathnamePrefix}/topics`)} className="btn btn-outline-primary">
                    {" "}
                    &#x2190; Go back
                </button>
                <span onClick={fetchTopic} className="d-inline-flex mx-3 my-0 pointer h4 align-items-center">
                    <i className={isLoading ? "fa fa-refresh fa-spin" : "fas fa-sync-alt"} aria-hidden={isLoading ? "true" : "false"}></i>
                </span>
            </div>
            <div>
                {topic && (
                    <div className={`card mt-3 ${!topic.is_active ? "border border-danger" : ""}`}>
                        {isLoading ? (
                            <div className="loading-modal">
                                <div className="spinner spinner-border float-right d-inline-block mr-2" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="card-header py-1">
                            {/* TOPIC CONTROLS */}
                            <div className="p-0 ml-auto">
                                {!SPECIAL_TOPICS_ID_LIST.includes(topic.id) && (
                                    <ConfirmPopup confirmText="Delete" onConfirm={deleteTopic}>
                                        <span className="float-left mr-3 pointer">
                                            <i className="fas fa-trash-alt"></i>
                                        </span>
                                    </ConfirmPopup>
                                )}
                                <span className="float-left text-muted last-updated">
                                    Last updated: {formatDistanceToNowStrict(new Date(topic.updatedAt), { addSuffix: true })}
                                </span>
                                {!SPECIAL_TOPICS_ID_LIST.includes(topic.id) && (
                                    <div className="custom-control custom-switch d-inline-block float-right ml-2">
                                        <input
                                            onChange={onActiveInputChange}
                                            checked={topic.is_active}
                                            type="checkbox"
                                            name="is_active"
                                            id={topic.id}
                                            className="custom-control-input"
                                        />
                                        <label className="custom-control-label pointer" htmlFor={topic.id}>
                                            {topic.is_active ? "On" : "Off"}
                                        </label>
                                    </div>
                                )}
                                {!SPECIAL_TOPICS_ID_LIST.includes(topic.id) && (
                                    <span onClick={() => setOpenEditModal(true)} className="align-middle float-right pointer ml-2">
                                        <i className="far fa-edit"></i>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="card-body">
                            {/* TOPIC TITLE */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-4 d-flex flex-column align-items-center justify-content-center">
                                        <h5 className="text-secondary">Topic Name:</h5>
                                        <h4 className="card-title text-break">{topic.name}</h4>
                                    </div>
                                    {!SPECIAL_TOPICS_ID_LIST.includes(topic.id) && (
                                        <div className="d-flex align-items-center justify-content-center position-relative col-8">
                                            {!topic.image_url && <span className="position-absolute translate-middle">No image selected</span>}
                                            <img
                                                alt="Topic"
                                                onClick={() => setOpenEditModal(true)}
                                                style={{ objectFit: "cover", width: "445px", height: "150px", content: "No Image" }}
                                                className="img-thumbnail pointer"
                                                src={topic.image_url || "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="}
                                            />
                                        </div>
                                    )}
                                </div>
                                {Array.isArray(topic.keywords) && (
                                    <div className="row align-middle mt-2">
                                        <span onClick={() => setOpenEditModal(true)} className="d-inline-block mb-2 pointer">
                                            Keywords:
                                        </span>
                                        {topic.keywords.length > 0 ? (
                                            <span onClick={() => setOpenEditModal(true)} className="ml-2 pointer">
                                                {topic.keywords.map((keyword, i) => (
                                                    <span key={`${keyword}-${i}`} className="badge bg-primary mr-2 mb-2 p-2 text-white">
                                                        {keyword}
                                                    </span>
                                                ))}
                                            </span>
                                        ) : (
                                            <span className="ml-2">none</span>
                                        )}
                                    </div>
                                )}
                            </div>
                            <ContentTabs requestRefresh={fetchTopic} parentRequestedRefetch={needToRefetchChildren} topic={topic} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DeepDiveTopic;
