import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash-es";
import { useAlert } from "react-alert";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import PropTypes from "prop-types";
import VideoTile from "./videoTile.js";
import UploadVideoModal from "./uploadVideo.js";
import PaginationV2 from "../utils/paginationV2";
import API from "../../api/videoLibraryAPI";

const DEFAULT_VIDEOS_PER_PAGE = 10;

function VideoList({ showPinIcon, onPinRequest }) {
    // Hooks
    const location = useLocation();
    const history = useHistory();
    const alert = useAlert();
    const { user } = useAuth();

    /* Local state */
    const [videoList, setVideoList] = useState(null);
    const [total, setTotal] = useState(0);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState(() => {
        const urlParams = new URLSearchParams(location.search);
        const savedPerPage = parseInt(localStorage.getItem("DEFAULT_VIDEOS_PER_PAGE"));
        return {
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page")) || 1,
            perPage: parseInt(urlParams.get("perPage")) || savedPerPage || DEFAULT_VIDEOS_PER_PAGE
        };
    });

    // API
    const fetchVideoList = useCallback(() => {
        setIsLoading(true);
        API.fetchVideos(params, user.access_token)
            .then(data => {
                setVideoList(data.data);
                setTotal(data.total);
            })
            .catch(e => alert.show(e, { timeout: 5000, type: "error" }))
            .finally(() => setIsLoading(false));
    }, [params, user.access_token, alert]);

    const deleteVideo = useCallback(
        videoId => {
            setIsLoading(true);
            API.deleteVideoById(videoId, user.access_token)
                .then(() => alert.show("Successfully deleted", { timeout: 5000, type: "success" }))
                .catch(e => alert.show(e, { timeout: 5000, type: "error" }))
                .finally(() => {
                    setIsLoading(false);
                    fetchVideoList();
                });
        },
        [user.access_token, alert, fetchVideoList]
    );

    const updateVideo = useCallback(
        (videoId, data) => {
            setIsLoading(true);
            API.updateVideoById(videoId, data, user.access_token)
                .then(() => alert.show("Successfully updated", { timeout: 5000, type: "success" }))
                .catch(e => alert.show(e, { timeout: 5000, type: "error" }))
                .finally(() => {
                    setIsLoading(false);
                    fetchVideoList();
                });
        },
        [user.access_token, alert, fetchVideoList]
    );

    /* Lifecycle Hooks */
    useEffect(() => {
        fetchVideoList(); // Also, fetches videos after component is mounted
    }, [params.q, params.page, params.perPage, fetchVideoList]);

    useEffect(() => {
        // Watches for URL query changes and updates 'params' state
        const urlParams = new URLSearchParams(location.search);
        const savedPerPage = parseInt(localStorage.getItem("DEFAULT_VIDEOS_PER_PAGE"));
        setParams({
            q: urlParams.get("q") || "",
            page: parseInt(urlParams.get("page")) || 1,
            perPage: parseInt(urlParams.get("perPage")) || savedPerPage || DEFAULT_VIDEOS_PER_PAGE
        });
    }, [location.search]);

    const updateSearchQuery = debounce(value => {
        history.push({ ...location, search: `?${new URLSearchParams({ ...params, q: value })}` });
    }, 500);

    const onSearchChange = useCallback(
        e => {
            updateSearchQuery(e.target.value);
        },
        [updateSearchQuery]
    );

    const onPerPageChange = useCallback(
        e => {
            const perPageNum = parseInt(e.target.value);
            localStorage.setItem("DEFAULT_VIDEOS_PER_PAGE", parseInt(perPageNum));
            history.push({ ...location, search: `?${new URLSearchParams({ ...params, page: 1, perPage: perPageNum })}` });
        },
        [history, location, params]
    );

    const onPageChange = useCallback(
        pageObj => {
            const newPage = pageObj.selected + 1;
            history.push({ ...location, search: `?${new URLSearchParams({ ...params, page: parseInt(newPage) })}` });
        },
        [history, location, params]
    );

    return (
        <div className="pt-1 px-1">
            <h5 className="d-flex align-content-center ml-1">
                Videos
                <span className="badge badge-primary badge-pill ml-2 py-1 px-2">{total}</span>
                <span className="mx-3">|</span>
                <span onClick={() => setShowUploadModal(true)} className="pointer">
                    Upload<i className="fas fa-upload ml-2"></i>
                </span>
                <span className="mx-3">|</span>
                {!isLoading ? (
                    <span onClick={fetchVideoList} className="pointer">
                        <i className="fas fa-sync-alt"></i>
                    </span>
                ) : (
                    <span className="pointer">
                        <i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
                    </span>
                )}
            </h5>
            <div className="mt-3">
                <PaginationV2
                    initialPage={params.page}
                    forcePage={params.page}
                    total={total}
                    perPageCount={params.perPage}
                    onPageChange={onPageChange}
                    onPerPageChange={onPerPageChange}
                />
            </div>
            <input className="form-control text-body mt-3" placeholder="Search titles..." onChange={onSearchChange} />
            <div className="video-list-wrapper mt-4">
                {videoList ? (
                    videoList.map(video => {
                        return (
                            <VideoTile
                                locationKey="video-list"
                                showEditIcon
                                onPinRequest={onPinRequest}
                                showPinIcon={showPinIcon}
                                updateVideo={updateVideo}
                                video={video}
                                key={video.id}
                                deleteVideo={deleteVideo}
                            />
                        );
                    })
                ) : (
                    <div className="modal-footer justify-content-center mx-auto">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
            </div>
            <UploadVideoModal refreshVideoList={fetchVideoList} isOpen={showUploadModal} closeModal={() => setShowUploadModal(false)} />
        </div>
    );
}

VideoList.propTypes = {
    showPinIcon: PropTypes.bool,
    onPinRequest: PropTypes.func
};

export default VideoList;
