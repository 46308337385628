// @ts-check

import React, { useCallback, useState } from "react";
import { useAuth } from "react-oidc-context";
import { getHttpErrorMessage, toTimeAgo } from "../../../utils/utils";
import Modal from "../../utils/modal";
import { deleteAiSummary } from "./useAiSummaryApi";

/**
 * @param {object} props
 * @param {string} props.storyId
 * @param {import("./AiSummaryTypes").AiSummary} props.summary
 * @param {() => Promise<void>} props.onDeleted
 */
export function DeleteSummaryModal({ storyId, summary, onDeleted }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState(/** @type {?string} */ (null));

    const { user } = useAuth();

    const onConfirm = useCallback(async () => {
        if (isDeleting) {
            return;
        }
        setIsDeleting(true);
        setError(null);
        try {
            if (!user?.access_token) {
                throw new Error("unauthenticated");
            }
            await deleteAiSummary({ token: user?.access_token, storyId, summaryId: summary.id });
            await onDeleted();
            setIsOpen(false);
        } catch (e) {
            setError(getHttpErrorMessage(e));
        } finally {
            setIsDeleting(false);
        }
    }, [isDeleting, onDeleted, storyId, summary.id, user?.access_token]);

    return (
        <>
            <button className="btn btn-danger btn-sm" onClick={() => setIsOpen(true)}>
                <i className="fas fa-trash"></i>
            </button>
            <Modal
                className="add-ai-modal"
                label="Add AI Summary"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                closeClassName="delete-summary-modal-close"
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    Are you sure you want to delete this summary? (created {toTimeAgo(summary.createdAt)})
                    <br />
                    <div className="d-flex flex-row align-items-center mt-2">
                        <button className="btn btn-danger mr-3" disabled={isDeleting} onClick={onConfirm}>
                            {isDeleting ? "Deleting" : "Delete AI Summary"}
                        </button>
                        <button className="btn btn-secondary" onClick={() => setIsOpen(false)}>
                            Cancel
                        </button>
                    </div>
                    {error && (
                        <>
                            <br />
                            <span className="text-danger">{error}</span>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
}
