import React from "react";
import { Route, Switch } from "react-router-dom";

import CrawlerAdd from "./add";
import CrawlerFind from "./find";

function Crawler() {
    return (
        <main className="pt-lg-4 px-lg-5 bg-light">
            <Switch>
                <Route exact path="/crawler">
                    <CrawlerFind />
                </Route>
                <Route path="/crawler/add">
                    <CrawlerAdd />
                </Route>
            </Switch>
        </main>
    );
}

export default Crawler;
