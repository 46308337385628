import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { formatDistanceToNowStrict } from "date-fns";
import { useAuth } from "react-oidc-context";
import API from "../../../api/deepDiveAPI";
import UpdateCategoryModal from "./modals/updateCategoryModal";
import LinkedTopicsList from "./linkedTopicsList";
import "./deepDiveCategory.scss";

function DeepDiveCategory() {
    const alert = useAlert();
    const { user } = useAuth();
    const { category_id } = useParams();
    const history = useHistory();
    const [category, setCategory] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const fetchCategory = useCallback(() => {
        setIsLoading(true);
        API.getDeepDiveCategoryById(category_id, user.access_token)
            .then(data => setCategory(data))
            .catch(e => alert.show(e))
            .finally(() => setIsLoading(false));
    }, [category_id, user.access_token, alert]);

    // Fetch data on mount
    useEffect(() => fetchCategory(), [fetchCategory]);

    const updateDeepDiveTopicOrCategory = useCallback(
        fieldsToUpdate => {
            setIsLoading(true);
            API.updateDeepDiveTopicOrCategory(fieldsToUpdate, category.id, user.access_token)
                .then(() => fetchCategory())
                .catch(e => {
                    alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" });
                    setIsLoading(false);
                });
        },
        [alert, category?.id, fetchCategory, user.access_token]
    );

    const onActiveInputChange = useCallback(
        async e => {
            if (e.target.type === "checkbox") {
                const fieldsToUpdate = { [e.target.name]: e.target.checked };
                updateDeepDiveTopicOrCategory(fieldsToUpdate);
            }
        },
        [updateDeepDiveTopicOrCategory]
    );

    const onSave = useCallback(
        fieldsToUpdate => {
            updateDeepDiveTopicOrCategory(fieldsToUpdate);
            setOpenEditModal(false);
        },
        [updateDeepDiveTopicOrCategory]
    );

    const onModalClose = useCallback(() => setOpenEditModal(false), []);

    return (
        <div className="DeepDiveCategory pb-5">
            {/* UPDATE CATEGORY MODAL */}
            {openEditModal && <UpdateCategoryModal category={category} onClose={onModalClose} onSave={onSave} />}
            <div className="d-flex align-items-center">
                <button onClick={() => history.push("/deep-dive/categories")} className="btn btn-outline-primary">
                    {" "}
                    &#x2190; Go back
                </button>
                <span onClick={fetchCategory} className="d-inline-flex mx-3 my-0 pointer h4 align-items-center">
                    <i className={isLoading ? "fa fa-refresh fa-spin" : "fas fa-sync-alt"} aria-hidden={isLoading ? "true" : "false"}></i>
                </span>
            </div>
            <div>
                {category && (
                    <div className={`card mt-3 ${!category.is_active ? "border border-danger" : ""}`}>
                        {isLoading ? (
                            <div className="loading-modal">
                                <div className="spinner spinner-border float-right d-inline-block mr-2" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="card-header py-1">
                            {/* CATEGORY CONTROLS */}
                            <div className="p-0 ml-auto">
                                <span className="float-left text-muted last-updated">
                                    Last updated: {formatDistanceToNowStrict(new Date(category.updatedAt), { addSuffix: true })}
                                </span>
                                <div className="custom-control custom-switch d-inline-block float-right ml-2">
                                    <input
                                        onChange={onActiveInputChange}
                                        checked={category.is_active}
                                        type="checkbox"
                                        name="is_active"
                                        id={category.id}
                                        className="custom-control-input"
                                    />
                                    <label className="custom-control-label pointer" htmlFor={category.id}>
                                        {category.is_active ? "On" : "Off"}
                                    </label>
                                </div>
                                <span onClick={() => setOpenEditModal(true)} className="align-middle float-right pointer ml-2">
                                    <i className="far fa-edit"></i>
                                </span>
                            </div>
                        </div>
                        <div className="card-body">
                            {/* CATEGORY TITLE */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-4 d-flex flex-column align-items-center justify-content-center">
                                        <h5 className="text-secondary">Deep Dive Category Name:</h5>
                                        <h4 className="card-title d-inline-flex my-0 text-break">{category.name}</h4>
                                    </div>
                                    <div className="d-flex justify-content-center position-relative col-8">
                                        {!category.image_url && <span className="position-absolute translate-middle">No image selected</span>}
                                        <img
                                            onClick={() => setOpenEditModal(true)}
                                            style={{ objectFit: "cover", width: "445px", height: "150px", content: "No Image" }}
                                            className="img-thumbnail px-1 pointer"
                                            src={category.image_url || "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="}
                                            alt="category"
                                        />
                                    </div>
                                </div>
                            </div>
                            <LinkedTopicsList key={category.updatedAt} categoryId={category.id} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DeepDiveCategory;
