import axios from "axios";
import { formatDistanceToNowStrict, isValid as isValidDate } from "date-fns";
import { encode } from "js-base64";
import { PROXY_AUTH_KEY, PROXY_URL } from "../config";
import stripTagsFromHtml from "../services/htmlTagsStriper";

const swapArrayLocs = (arr, index1, index2) =>
    arr.map((val, idx) => {
        if (idx === index1) return arr[index2];
        if (idx === index2) return arr[index1];
        return val;
    });

const hasRole = (user, role) => {
    if (!role) return false;
    return !!(
        user &&
        user.profile &&
        user.profile.realm_access &&
        user.profile.realm_access.roles &&
        user.profile.realm_access.roles.length &&
        user.profile.realm_access.roles.includes(role)
    );
};

const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
};

const getAuthHeaders = token => {
    return {
        Authorization: `Bearer ${token}`
    };
};

const isEmptyObject = obj => {
    return Object.keys(obj).length === 0;
};

// earliest first
const sortByDate = (array, key) => {
    if (!key) {
        throw Error("sortByDate: key is not specified");
    }
    if (!Array.isArray(array)) {
        return;
    }
    if (Array.isArray(key)) {
        array.sort(function (a, b) {
            return new Date(b[key[0]] ? b[key[0]] : b[key[1]]) - new Date(a[key[0]] ? a[key[0]] : a[key[1]]);
        });
    } else {
        array.sort(function (a, b) {
            return new Date(b[key]) - new Date(a[key]);
        });
    }
};

const getPublisherName = result => {
    // NOTE: to support the new backend (core)
    if (result.publisherName) {
        return result.publisherName;
    } else if (result.publishers && !isEmptyObject(result.publishers)) {
        // TODO: remove once we completely switch to the new backend (core)
        let publisher = result.publishers[Object.keys(result.publishers)[0]].name;
        return publisher;
    } else {
        return "Unknown";
    }
};

const filterTags = tags => {
    const allowedTags = ["left", "right", "center", "middle", "satire"];
    return tags.filter(tag => allowedTags.includes(tag.toLowerCase()));
};

const getPublisherTags = result => {
    // NOTE: to support the new backend (core)
    if (result.bias && typeof result.bias === "string") {
        return [result.bias];
    } else if (result.publishers && !isEmptyObject(result.publishers)) {
        // TODO: remove once we completely switch to the new backend (core)
        let tags = result.publishers[Object.keys(result.publishers)[0]].tags;
        tags = filterTags(tags || []);
        return tags.length ? tags : ["center"]; // if no tag => default to "center"
    }
    return [];
};

const getImageSourceList = article => {
    let imageSources = [];
    if (article.imageUrl) {
        imageSources.push(article.imageUrl);
    }
    /* Remove Array.isArray check once Jordan fixes data structure inconsistency in regards to image URLs */
    if (Array.isArray(article.images)) {
        imageSources = article.images;
    } else if (article.images) {
        Object.keys(article.images).forEach(img => {
            if (article.images[img].url && !imageSources.includes(article.images[img].url)) {
                if (Array.isArray(article.images[img].url)) {
                    imageSources.push(...article.images[img].url);
                } else {
                    imageSources.push(article.images[img].url);
                }
            } else if (article.images[img].imageUrl && !imageSources.includes(article.images[img].imageUrl)) {
                if (Array.isArray(article.images[img].imageUrl)) {
                    imageSources.push(...article.images[img].imageUrl);
                } else {
                    imageSources.push(article.images[img].imageUrl);
                }
            } else if (article.images[img].contentUrl && !imageSources.includes(article.images[img].contentUrl)) {
                if (Array.isArray(article.images[img].contentUrl)) {
                    imageSources.push(...article.images[img].contentUrl);
                } else {
                    imageSources.push(article.images[img].contentUrl);
                }
            }
        });
    }
    // to prevent 'Not Secure' icon on chrome based browsers
    imageSources = imageSources.map(imageSrc => imageSrc.replace("http://", "https://"));
    return imageSources;
};

const arrayMoveMutate = (array, from, to) => {
    const startIndex = from < 0 ? array.length + from : from;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = to < 0 ? array.length + to : to;

        const [item] = array.splice(from, 1);
        array.splice(endIndex, 0, item);
    }
};

const arrayMove = (array, from, to) => {
    array = [...array];
    arrayMoveMutate(array, from, to);
    return array;
};

const isSatireCategory = categoryName => {
    return categoryName.toLowerCase() === "make me laugh";
};

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const isTweetURL = (url = "") => {
    const twitterRegex = /(twitter|x).com(.*)\/status\/(.*)/gm;
    return url.match(twitterRegex);
};

const isSpangldURL = (url = "") => {
    const spangldRegex = /spangld.com(.*)/gm;
    return url.match(spangldRegex);
};

const getFreespokeId = (url = "") => {
    try {
        const urlObject = new URL(url);
        const params = new URLSearchParams(urlObject.search);
        return params.get("freespoke_id") || null;
    } catch (e) {
        return null;
    }
};

const isValidHttpUrl = string => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

const toTimeAgo = (date, short = false) => {
    const dateObj = new Date(date);
    if (!isValidDate(dateObj)) {
        return "invalid date";
    }
    let formattedDistance = formatDistanceToNowStrict(dateObj, { addSuffix: true });

    // Removes 'in' keyword from future dates (ex: "in 20 minutes" becomes "20 minutes" )
    if (formattedDistance.startsWith("in")) {
        formattedDistance = formattedDistance.slice(3);
    }

    if (short) {
        const distanceSplit = formattedDistance.split(" ");
        // "2 months ago" becomes "2mo ago"
        return `${distanceSplit[0]}${distanceSplit[1]?.substr(0, distanceSplit[1].includes("month") ? 2 : 1)} ${
            distanceSplit[2] ? distanceSplit[2] : ""
        }`;
    }
    return formattedDistance;
};

const fetchUrlHtmlDocument = async url => {
    try {
        // temp hack for bloomberg, newsmax and probably many others
        if (typeof url === "string" && !url.includes("bloomberg.com")) {
            const headers = { Authorization: `Basic ${PROXY_AUTH_KEY}` };
            const response = await axios.get(`${PROXY_URL}?address=${url}`, { headers });
            if (response && response.data) {
                const strippedHtml = stripTagsFromHtml(response.data);
                return encode(strippedHtml);
            }
        }
    } catch (e) {
        const responseError = (e.response && e.response.data && e.response.data.message) || e.message;
        throw new Error(`failed to fetch the following url: <${url}>. Description: ${responseError || e}`);
    }
};

const getHttpErrorMessage = e => {
    return e.response?.data?.message || e.message || e;
};

export {
    arrayMove,
    arrayMoveMutate,
    fetchUrlHtmlDocument,
    getAuthHeaders,
    getFreespokeId,
    getImageSourceList,
    getPublisherName,
    getPublisherTags,
    getRandomInt,
    hasRole,
    isEmptyObject,
    isSatireCategory,
    isSpangldURL,
    isTweetURL,
    isValidHttpUrl,
    sortByDate,
    swapArrayLocs,
    toTimeAgo,
    uuidv4,
    getHttpErrorMessage
};
