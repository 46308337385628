import React, { createContext, useContext } from "react";

const CategoryAccessContext = createContext();

// eslint-disable-next-line react/prop-types
export const CategoryAccessProvider = ({ children, fetchCategory }) => {
    return (
        <CategoryAccessContext.Provider
            value={{
                fetchCategory
            }}>
            {children}
        </CategoryAccessContext.Provider>
    );
};

export const useCategoryAccess = () => {
    return useContext(CategoryAccessContext);
};
