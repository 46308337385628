// safely parse json
export function safeParseJson(data) {
    try {
        const parsedData = JSON.parse(data);
        return parsedData;
    } catch (e) {
        console.error(e);
        return;
    }
}
