import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import API from "../../api/deepDiveAPI";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function NewDeepDive({ onDone, onClose, type, parentId }) {
    const { pathname } = useLocation();
    const alert = useAlert();
    const { user } = useAuth();
    const [formData, setFormData] = useState({ title: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const isKnowledgePanelPage = pathname.startsWith("/knowledge-panel");

    const onClick = async () => {
        try {
            setIsSubmitting(true);
            if (type === "category") {
                await API.postNewDeepDiveCategory(formData, user.access_token);
            } else if (type === "topic") {
                // Link the new topic to category (optional)
                const data = parentId ? { ...formData, parentId } : formData;
                await API.postNewDeepDiveTopic(data, user.access_token, isKnowledgePanelPage ? ["knowledge_panel_topic"] : ["deep_dive_topic"]);
            } else {
                throw Error("Invalid NewDeepDive type");
            }
            onDone();
        } catch (e) {
            alert.show(e);
            setIsSubmitting(false);
        }
    };

    return (
        <div className="card my-2 mb-1">
            <div className="card-body">
                <div className="mb-3">
                    <div key="new" className="input-group mb-3">
                        <input
                            autoFocus
                            onChange={onChange}
                            type="text"
                            name="title"
                            value={formData.title}
                            className="form-control"
                            placeholder={`Type a new ${type} name`}
                        />
                    </div>
                    {!!formData.title && (
                        <button onClick={onClick} className="btn btn-primary float-right ml-2 pointer">
                            {isSubmitting ? <i className="fa fa-refresh fa-spin"></i> : "Create"}
                        </button>
                    )}
                    <button onClick={onClose} className="btn btn-outline-secondary d-inline float-right pointer">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

NewDeepDive.propTypes = {
    type: PropTypes.oneOf(["category", "topic"]).isRequired,
    parentId: PropTypes.string,
    onDone: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default NewDeepDive;
