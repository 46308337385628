// @ts-check

import React, { useCallback, useState } from "react";
import { useAuth } from "react-oidc-context";
import { getHttpErrorMessage } from "../../../utils/utils";
import DatePicker from "../../utils/datePicker";
import Modal from "../../utils/modal";
import { postDocument } from "./pinnedDocumentsApi";

/**
 * @typedef {object} AddDocumentFormData
 * @property {string} url
 * @property {string} title
 * @property {string} description
 * @property {?Date} date
 * @property {string} image
 */

/**
 * @param {object} props
 * @param {string} props.storyId
 * @param {() => Promise<void>} props.onAdd
 * @returns
 */
export function AddDocumentModal({ storyId, onAdd }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(/** @type {?string} */ (null));
    const [formData, setFormData] = useState(
        /** @type {Partial<AddDocumentFormData>} */ ({
            url: undefined,
            title: undefined,
            description: undefined,
            date: new Date(),
            image: undefined
        })
    );

    const { user } = useAuth();

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onInputChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = useCallback(async () => {
        const { url, title, description, image, date } = formData;
        if (!storyId || isSubmitting) {
            return;
        }

        if (!url || !title) {
            setError("Make sure all required fields are filled out");
            return;
        }

        setIsSubmitting(true);
        setError(null);
        try {
            if (!user?.access_token) {
                throw new Error("unauthenticated");
            }

            await postDocument({
                token: user.access_token,
                document: {
                    description,
                    image,
                    title,
                    url,
                    story_id: storyId,
                    date: date?.toISOString()
                }
            });
            await onAdd();
            setFormData({
                url: undefined,
                title: undefined,
                description: undefined,
                date: new Date(),
                image: undefined
            });
            setIsOpen(false);
        } catch (e) {
            setError(getHttpErrorMessage(e));
        } finally {
            setIsSubmitting(false);
        }
    }, [formData, isSubmitting, onAdd, storyId, user?.access_token]);

    return (
        <div className="d-flex justify-content-center my-2 mb-3">
            <button onClick={() => setIsOpen(true)} disabled={isOpen} className="btn btn-primary" data-testid="add-document">
                Add Document <i className="fas fa-plus text-secondary pointer"></i>
            </button>
            <Modal
                styles={{ width: "90%" }}
                className="add-document-modal"
                label="Add Document Summary"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <div>
                    <div className="form-group">
                        <label className="control-label font-weight-bold" htmlFor="url" data-testid="add-document-url">
                            <span className="text-danger">* </span>Document Url:
                        </label>
                        <input
                            id="url"
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="url"
                            type="url"
                            value={formData.url}
                            placeholder="https://..."
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="control-label font-weight-bold" htmlFor="title" data-testid="document-title">
                            <span className="text-danger">* </span>Title:
                        </label>
                        <input
                            id="title"
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="title"
                            type="text"
                            value={formData.title}
                            placeholder="Enter a title"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="control-label font-weight-bold" htmlFor="description" data-testid="document-description">
                            Description:
                        </label>
                        <input
                            id="description"
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="description"
                            type="text"
                            value={formData.description}
                            placeholder="Enter a description"
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-control-label mt-2 font-weight-bold" htmlFor="date">
                            Date:
                        </label>
                        <div className="w-100">
                            <DatePicker
                                id="date"
                                className="form-control bg-white pl-2"
                                isClearable
                                todayButton="Now"
                                selected={formData.date}
                                onChange={newDate => {
                                    if (Array.isArray(newDate)) {
                                        newDate = newDate[0];
                                    }
                                    setFormData({ ...formData, date: newDate });
                                }}
                                placeholderText="Click to select a date"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="control-label font-weight-bold" htmlFor="image" data-testid="document-image-url">
                            Image Url:
                        </label>
                        <input
                            id="image"
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="image"
                            type="url"
                            value={formData.image}
                            placeholder="https://..."
                        />
                    </div>

                    <button className="btn btn-info" disabled={isSubmitting} onClick={onSubmit} data-testid="add-document-modal">
                        {isSubmitting ? "Adding" : "Add Document"}
                    </button>
                    {error && (
                        <>
                            <br /> <span className="text-danger">{error}</span>
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
}
