import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import { useAuth } from "react-oidc-context";
import SnapshotsTable from "../../topic/tabs/snapshots/snapshotsTable";
import API from "../../../../api/deepDiveAPI";
import PaginationBar from "../../../utils/pagination";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "1024px"
    }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function AddSnapshotsModal({ topicId, content, onClose, onSuccess }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [stories, setStories] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(() => {
        const customSetting = localStorage.getItem("SHAPSHOT_STORIES_PER_PAGE");
        return parseInt(customSetting) || 50;
    });
    const [total, setTotal] = useState(0);
    const [isFetching, setIsFetching] = useState(false);

    const fetchStoryList = useCallback(async () => {
        setIsFetching(true);
        API.getStories(user.access_token, { q: searchTerm, perPage, page })
            .then(data => {
                setStories(data.data.filter(story => content.findIndex(x => x.id === story.id) === -1));
                setTotal(data.total);
            })
            .catch(e => alert.show(e))
            .finally(() => setIsFetching(false));
    }, [alert, content, user.access_token, page, perPage, searchTerm]);

    useEffect(() => {
        fetchStoryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, perPage]);

    useEffect(() => {
        setStories(stories.filter(story => content.findIndex(x => x.id === story.id) === -1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    const addStory = useCallback(
        storyId => {
            API.linkRelatedStoryOrTopicToTopic(topicId, storyId, "story", user.access_token)
                .then(() => onSuccess())
                .catch(e => alert.show(e));
        },
        [alert, user.access_token, onSuccess, topicId]
    );

    const onInputChange = useCallback(e => {
        setSearchTerm(e.target.value);
    }, []);

    const searchClick = useCallback(
        e => {
            e.preventDefault();
            if (page !== 1) {
                setPage(1);
            } else {
                fetchStoryList();
            }
        },
        [fetchStoryList, page]
    );

    const clearAndReset = useCallback(() => {
        setSearchTerm("");
        setPage(1);
        setPerPage(50);
        API.getStories(user.access_token, { q: "", perPage: 50, page: 1 })
            .then(data => {
                setStories(data.data.filter(story => content.findIndex(x => x.id === story.id) === -1));
                setTotal(data.total);
            })
            .catch(e => alert.show(e));
    }, [alert, content, user.access_token]);

    const onPerPageChange = useCallback(e => {
        const perPageNum = parseInt(e.target.value);
        localStorage.setItem("SHAPSHOT_STORIES_PER_PAGE", perPageNum);
        setPerPage(perPageNum);
    }, []);

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="Add Snapshot Stories">
            <div className="modal-body" style={{ minHeight: "calc(100vh - 210px)" }}>
                <form className="form-group" onSubmit={searchClick}>
                    <div className="input-group">
                        <input
                            className="form-control"
                            style={{ minWidth: "" }}
                            type="text"
                            placeholder="Search Stories"
                            onChange={onInputChange}
                            value={searchTerm}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="submit">
                                {isFetching ? (
                                    <div className="mx-3 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    "Search"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
                {stories && stories.length ? (
                    <PaginationBar
                        total={total}
                        page={page}
                        perPage={perPage}
                        onClick={selectedPage => setPage(selectedPage)}
                        onPerPageChange={onPerPageChange}
                    />
                ) : null}
                {stories && stories.length ? (
                    <SnapshotsTable type="add" stories={stories} addAction={addStory} />
                ) : (
                    <div className="col-xs-12 text-center p-1 h-100">
                        {isFetching ? (
                            <div className="m-auto spinner-border spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            <>
                                <p>No items found</p>
                                <button className="btn btn-secondary" onClick={clearAndReset}>
                                    Reset Search
                                </button>
                            </>
                        )}
                    </div>
                )}
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Close
                </button>
            </div>
        </Modal>
    );
}

AddSnapshotsModal.propTypes = {
    content: PropTypes.array,
    topicId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default AddSnapshotsModal;
