import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DeepDiveCategoryList from "./category/deepDiveCategoryList";
import DeepDiveTopicsList from "./topic/deepDiveTopicsList";
import DeepDiveCategory from "./category/deepDiveCategory";
import DeepDiveTopic from "./topic/deepDiveTopic";

function DeepDive() {
    let { path } = useRouteMatch();

    return (
        <main className="pt-lg-4 px-lg-5 bg-light">
            <Switch>
                <Route path="/deep-dive/category/:category_id">
                    <DeepDiveCategory />
                </Route>
                <Route path={`${path}/topic/:topic_id`}>
                    <DeepDiveTopic />
                </Route>
                <Route path="/deep-dive/categories">
                    <DeepDiveCategoryList />
                </Route>
                <Route path={`${path}/topics`}>
                    <DeepDiveTopicsList />
                </Route>
            </Switch>
        </main>
    );
}

export default DeepDive;
