export function formatDuration(seconds) {
    try {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        if (Number.isNaN(hours) || Number.isNaN(minutes)) {
            return "";
        }

        let result = "";
        if (hours > 0) {
            result += `${hours} hr `;
        }
        if (minutes > 0 || hours > 0) {
            // Include minutes if there are any hours
            result += `${minutes} min `;
        }
        result += `${remainingSeconds} seconds`;

        return result.trim();
    } catch (e) {
        return "Invalid duration";
    }
}

export function parseDuration(durationStr) {
    let seconds = 0;
    const pattern = /\b(\d+)\s*(hr|hour|hours|min|minute|minutes|sec|second|seconds)\b/gi;
    let match;

    while ((match = pattern.exec(durationStr)) !== null) {
        const value = parseInt(match[1], 10);
        const unit = match[2].toLowerCase();

        switch (unit) {
            case "hr":
            case "hour":
            case "hours":
                seconds += value * 3600;
                break;
            case "min":
            case "minute":
            case "minutes":
                seconds += value * 60;
                break;
            case "sec":
            case "second":
            case "seconds":
                seconds += value;
                break;
            default:
                break;
        }
    }

    return seconds;
}
