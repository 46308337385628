import React, { useState } from "react";
import PropTypes from "prop-types";

function NewStory(props) {
    const [storyData, setStoryData] = useState({ name: "" });

    const onChange = e => {
        setStoryData({ ...storyData, [e.target.name]: e.target.value });
    };

    const onSave = () => {
        props.onSave(storyData);
    };

    return (
        <div>
            <div className="card mb-2 mb-1">
                <div className="card-body">
                    <div className="mb-3">
                        <div key="new" className="input-group mb-3">
                            <input
                                onChange={onChange}
                                type="text"
                                name="name"
                                value={storyData.name}
                                className="form-control"
                                placeholder="Add a story name"
                            />
                        </div>
                        {storyData.name ? (
                            <span onClick={onSave} className="card-link d-inline float-right ml-2 text-success pointer">
                                Save
                            </span>
                        ) : null}
                        <span onClick={props.onClose} className="card-link d-inline float-right text-dark pointer">
                            Cancel
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

NewStory.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func
};

export default React.memo(NewStory);
