import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import qs from "query-string";
import { useAlert } from "react-alert";
import { debounce } from "lodash-es";
import { useAuth } from "react-oidc-context";
import { BACKEND_URL } from "../../config";
import { getAuthHeaders } from "../../utils/utils.js";
import ArticleTile from "./articleTile";
// import UploadContentModal from "./uploadContent.js";
import PaginationBar from "../utils/pagination.js";
import { allowedCensoredStoriesCategoryNames, allowedBestOfWebCategoryNames } from "../utils/allowLists";
import AddOrUpdateSpecialContentModal from "../topic/addOrUpdateSpecialModal";

// axios.defaults.headers['ngrok-skip-browser-warning'] = '1';

const DEFAULT_TOPIC_ARTICLES_PER_PAGE = 10;

axios.defaults.paramsSerializer = p => {
    return qs.stringify(p);
};

const topicsMap = {
    fun: allowedBestOfWebCategoryNames,
    censored: allowedCensoredStoriesCategoryNames
};

function ArticleList({ tag }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [articleList, setArticleList] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRefreshing, setIfRefreshing] = useState(false);

    /* Params */
    const [searchQuery, setSearchQuery] = useState();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(() => {
        const customSetting = localStorage.getItem("TOPIC_ARTICLES_PER_PAGE");
        return parseInt(customSetting) || DEFAULT_TOPIC_ARTICLES_PER_PAGE;
    });
    const [total, setTotal] = useState(0);
    const [parentTopicId, setParentTopicId] = useState("");

    const fetchArticleList = useCallback(async () => {
        const url = `${BACKEND_URL}/admin/topic/specific/list`;
        const params = { perPage, page, q: searchQuery, tagsList: tag, category: selectedTopic };
        setIfRefreshing(true);
        try {
            const headers = getAuthHeaders(user.access_token);
            const { data } = await axios.get(`${url}`, { headers, params });
            setIfRefreshing(false);

            setArticleList(data.data);
            setTotal(data.total);
            setParentTopicId(data.topicId);
        } catch (e) {
            alert.show(e.message || "Oops, something went wrong...");
            setIfRefreshing(false);
        }
    }, [alert, user.access_token, page, perPage, searchQuery, selectedTopic, tag]);

    useEffect(() => {
        fetchArticleList();
    }, [perPage, page, searchQuery, selectedTopic, fetchArticleList]);

    const updateSearchQuery = debounce(value => {
        setSearchQuery(encodeURI(value));
    }, 500);

    const onSearchChange = useCallback(
        e => {
            const { value } = e.target;
            updateSearchQuery(value);
        },
        [updateSearchQuery]
    );

    const openModal = useCallback(() => setIsModalOpen(true), []);
    const closeModal = useCallback(() => setIsModalOpen(false), []);

    const refreshArticleList = useCallback(async () => {
        try {
            setIfRefreshing(true);
            await fetchArticleList();
        } catch (e) {
            alert.show(e.message, { timeout: 2000, type: "error" });
        } finally {
            setIfRefreshing(false);
        }
    }, [alert, fetchArticleList]);

    const updateArticle = useCallback(
        async (articleId, data, isMultiform) => {
            try {
                const headers = getAuthHeaders(user.access_token);
                if (isMultiform) {
                    headers["content-type"] = "multipart/form-data";
                }
                await axios.patch(`${BACKEND_URL}/admin/topic/content/${articleId}`, data, { headers });

                // refresh articles
                refreshArticleList();
                alert.show("Saved", { timeout: 2000, type: "success" });
            } catch (e) {
                alert.show(e.message || "Oops, something went wrong...");
            }
        },
        [alert, user.access_token, refreshArticleList]
    );

    const deleteArticle = useCallback(
        async articleId => {
            try {
                const headers = getAuthHeaders(user.access_token);
                await axios.delete(`${BACKEND_URL}/admin/topic/content/${articleId}`, { headers });
                await refreshArticleList();
                alert.show("Deleted", { timeout: 2000, type: "success" });
            } catch (e) {
                alert.show(e.message || "Oops, something went wrong...");
            }
        },
        [alert, user.access_token, refreshArticleList]
    );

    const onPerPageChange = useCallback(e => {
        const perPageNum = parseInt(e.target.value);
        localStorage.setItem("TOPIC_ARTICLES_PER_PAGE", perPageNum);
        setPerPage(perPageNum);
    }, []);

    const onTopicClick = useCallback(e => {
        const clickedTab = e.target.getAttribute("data-tab-name");
        if (clickedTab === "All") {
            setSelectedTopic(null);
        } else {
            setSelectedTopic(clickedTab);
        }
    }, []);

    return (
        <div className="bg-light pt-1 px-1">
            <h5 className="ml-1">
                Article List
                <span className="badge badge-primary badge-pill ml-2 py-1 px-2">{total}</span>
                <span className="mx-3">|</span>
                <span onClick={openModal} className="pointer">
                    Add<i className="fas fa-plus ml-2"></i>
                </span>
                <span className="mx-3">|</span>
                {!isRefreshing ? (
                    <span onClick={refreshArticleList} className="pointer">
                        <i className="fas fa-sync-alt"></i>
                    </span>
                ) : (
                    <span className="pointer">
                        <i className="fa fa-refresh fa-spin" aria-hidden="true"></i>
                    </span>
                )}
            </h5>
            <PaginationBar
                total={total}
                page={page}
                perPage={perPage}
                onClick={selectedPage => setPage(selectedPage)}
                onPerPageChange={onPerPageChange}
            />
            <input className="form-control text-body mt-3" placeholder="Search titles..." onChange={onSearchChange} />
            <ul className="nav mt-3 mb-3">
                <li key="All" className={`btn btn-sm mr-2 my-1 ${selectedTopic === null ? "btn-info" : "btn-light bordered"}`}>
                    <div className="button white-text" data-tab-name={"All"} data-tab-id={"All"} onClick={onTopicClick}>
                        {"All"}
                    </div>
                </li>
                {topicsMap[tag] &&
                    topicsMap[tag].map(topic => {
                        return (
                            <li key={topic} className={`btn btn-sm mr-2 my-1 ${selectedTopic === topic ? "btn-info" : "btn-light bordered"}`}>
                                <div className="white-text" data-tab-name={topic} data-tab-id={topic} onClick={onTopicClick}>
                                    {topic}
                                </div>
                            </li>
                        );
                    })}
            </ul>
            <div className="video-list-wrapper mt-4">
                {articleList ? (
                    articleList.map(article => {
                        return (
                            <ArticleTile
                                topicId={parentTopicId}
                                tag={tag}
                                article={article}
                                updateArticle={updateArticle}
                                deleteArticle={deleteArticle}
                                key={article.id}
                                askParentToRefetch={refreshArticleList}
                            />
                        );
                    })
                ) : isRefreshing ? (
                    <div className="modal-footer justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div>No results</div>
                )}
            </div>
            {isModalOpen && (
                <AddOrUpdateSpecialContentModal
                    topicId={parentTopicId}
                    type={"new"}
                    tag={tag}
                    onClose={closeModal}
                    onSuccess={() => {
                        closeModal();
                        fetchArticleList();
                    }}
                />
            )}
            {/* <UploadContentModal
                user={user}
                triggerRefresh={refreshArticleList}
                isOpen={isModalOpen && tag === "censored"}
                closeModal={closeModal}
                topics={topicsMap[tag]}
            /> */}
        </div>
    );
}

ArticleList.propTypes = {
    tag: PropTypes.oneOf(["fun", "censored"]).isRequired
};

export default ArticleList;
