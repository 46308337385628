/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { useAuth } from "react-oidc-context";
import API from "../../../../api/deepDiveAPI";
import Table from "../../../utils/table";
import ContentModal from "../modals/addOrEditContent";
import ConfirmPopup from "../../../utils/confirmPopup";
import { isValidHttpUrl, toTimeAgo } from "../../../../utils/utils";
import { ReactComponent as TwitterIcon } from "../../../../static/svg/twitter.svg";

const noImageDataURI = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

function OtherContentTab({ topicId, parentRequestedRefetch }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [contentToEdit, setContentToEdit] = useState(false);
    const [openNewContentModal, setOpenNewContentModal] = useState(false);
    const [content, setContent] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchNewsContent = useCallback(() => {
        setIsFetching(true);
        API.getContentByType(user.access_token, topicId, "deep_dive_content_other")
            .then(data => {
                setContent(data.data);
                // setTotal(data.total); // TODO: Nic - add support for pagination
            })
            .catch(e => alert.show(e))
            .finally(() => setIsFetching(false));
    }, [alert, user.access_token, topicId]);

    useEffect(() => {
        fetchNewsContent();
    }, [fetchNewsContent, parentRequestedRefetch]);

    const requestRefresh = useCallback(() => fetchNewsContent(), [fetchNewsContent]);

    const repositionContent = useCallback(
        (itemId, direction) => {
            let contentToMoveId = "";
            let moveAfterContentId = "";
            const currentIdx = content.findIndex(x => x.id === itemId);

            if (direction === "up") {
                contentToMoveId = content[currentIdx - 1].id;
                moveAfterContentId = itemId;
            } else {
                contentToMoveId = itemId;
                moveAfterContentId = content[currentIdx + 1].id;
            }

            const request = { topicId, contentToMoveId, moveAfterContentId };
            API.reorderTopicContent(user.access_token, request)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, content, user.access_token, requestRefresh, topicId]
    );

    const unlinkContent = useCallback(
        content => {
            const type = content.tweet ? "tweet" : "article";
            API.unlinkTopicItem(content.id, type, topicId, user.access_token)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, user.access_token, requestRefresh, topicId]
    );

    const columns = useMemo(
        () => [
            {
                Header: <span style={{ float: "left" }}>Image</span>,
                accessor: "article.images",
                Cell: row => (
                    <div className="position-relative d-flex">
                        {content[row.row.index].tweet ? (
                            <TwitterIcon style={{ width: "25px", height: "25px" }} className="mx-auto" />
                        ) : (
                            <>
                                {(!Array.isArray(row.value) || !row.value.length) && (
                                    <span className="position-absolute translate-middle">No image selected</span>
                                )}
                                {Array.isArray(row.value) && row.value[0] && !isValidHttpUrl(row.value[0]) && (
                                    <span className="position-absolute translate-middle">Invalid image URL</span>
                                )}
                                <img
                                    style={{ objectFit: "cover", minWidth: "125px", height: "75px" }}
                                    className="img-thumbnail m-0 p-0"
                                    src={isValidHttpUrl(row.value[0]) ? row.value[0] : noImageDataURI}
                                    alt="topic"
                                />
                            </>
                        )}
                    </div>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Title</span>,
                accessor: "article.title",
                Cell: row => (
                    <p
                        onClick={() => setContentToEdit(content[row.row.index].article || content[row.row.index].tweet)}
                        className="d-inline-flex align-middle m-0 pointer text-wrap"
                    >
                        <strong>{content[row.row.index].tweet ? content[row.row.index].tweet.author.name : row.value}</strong>
                    </p>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Description</span>,
                accessor: "article.description",
                Cell: row => (
                    <p
                        onClick={() => setContentToEdit(content[row.row.index].article || content[row.row.index].tweet)}
                        className="d-inline-flex align-middle m-0 pointer text-wrap"
                    >
                        {content[row.row.index].tweet ? content[row.row.index].tweet.text : row.value}
                    </p>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Bias</span>,
                accessor: "article.bias",
                Cell: row => (
                    <div className="d-flex align-items-center">
                        {(content[row.row.index].tweet && content[row.row.index].tweet.bias) || row.value ? (
                            <span>
                                <i
                                    className={`fas fa-circle ${(content[row.row.index].tweet && content[row.row.index].tweet.bias) || row.value}`}
                                ></i>
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Publisher</span>,
                accessor: "article.publisherName",
                Cell: row => (
                    <a
                        className="d-inline-flex align-middle m-0 text-secondary"
                        href={
                            (content[row.row.index].article && content[row.row.index].article.url) ||
                            (content[row.row.index].tweet && content[row.row.index].tweet.url)
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {row.value ||
                            "@" +
                                (content[row.row.index].tweet && content[row.row.index].tweet.author && content[row.row.index].tweet.author.username)}
                    </a>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Logo</span>,
                accessor: "article.publisherIcon",
                Cell: row => (
                    <img
                        alt="publisher"
                        style={{ objectFit: "contain", maxWidth: "60px", height: "35px" }}
                        className="img-thumbnail"
                        src={
                            row.value ||
                            (content[row.row.index].tweet &&
                                content[row.row.index].tweet.author &&
                                content[row.row.index].tweet.author.profile_image_url)
                        }
                    ></img>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Published</span>,
                accessor: "article.datePublished",
                Cell: row => (
                    <p style={{ minWidth: "90px" }} className="d-inline-flex align-middle m-0 text-secondary">
                        {toTimeAgo(row.value || (content[row.row.index].tweet && content[row.row.index].tweet.datePublished))}
                    </p>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Pinned</span>,
                accessor: "createdAt",
                Cell: row => (
                    <p style={{ minWidth: "90px" }} className="d-inline-flex align-middle m-0 text-secondary">
                        {toTimeAgo(row.value)}
                    </p>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Actions</span>,
                accessor: "_", // this is just a workaround for custom header
                Cell: row => {
                    return (
                        <div style={{ minWidth: "180px" }}>
                            <button
                                onClick={() => setContentToEdit(content[row.row.index].article || content[row.row.index].tweet)}
                                className="btn btn-outline-primary btn-sm"
                            >
                                Edit
                            </button>
                            <ConfirmPopup onConfirm={() => unlinkContent(row.data[row.row.index])}>
                                <button className="btn btn-outline-danger btn-sm ml-2">Delete</button>
                            </ConfirmPopup>
                            <button
                                onClick={() => repositionContent(row.data[row.row.index].id, "up")}
                                className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === 0 ? "invisible" : ""}`}
                            >
                                <i className="fas fa-arrow-up"></i>
                            </button>
                            <button
                                onClick={() => repositionContent(row.data[row.row.index].id, "down")}
                                className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === row.rows.length - 1 ? "invisible" : ""}`}
                            >
                                <i className="fas fa-arrow-down"></i>
                            </button>
                        </div>
                    );
                }
            }
        ],
        [content, repositionContent, unlinkContent]
    );

    const onSuccess = useCallback(() => {
        setContentToEdit(false);
        setOpenNewContentModal(false);
        requestRefresh();
    }, [requestRefresh]);

    return (
        <div className="mt-3">
            {contentToEdit && (
                <ContentModal
                    type="edit"
                    parentType="deep_dive_content_other"
                    content={contentToEdit}
                    topicId={topicId}
                    onSuccess={onSuccess}
                    onClose={() => setContentToEdit(false)}
                />
            )}
            {openNewContentModal ? (
                <ContentModal
                    type="new"
                    parentType="deep_dive_content_other"
                    topicId={topicId}
                    onSuccess={onSuccess}
                    onClose={() => setOpenNewContentModal(false)}
                />
            ) : (
                <div className="d-flex justify-content-center mt-2">
                    <span onClick={() => setOpenNewContentModal(true)} className="h4 pointer">
                        <i className="fas fa-plus"></i>
                    </span>
                </div>
            )}
            {content && <Table columns={columns} data={content} />}
            {isFetching ? (
                <div className="mx-auto spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                content && content.length === 0 && <p className="text-center">No items</p>
            )}
        </div>
    );
}

OtherContentTab.propTypes = {
    topicId: PropTypes.string.isRequired,
    parentRequestedRefetch: PropTypes.number.isRequired
};

export default OtherContentTab;
