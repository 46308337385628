import PropTypes from "prop-types";
import { NavLink, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import AnsoTab from "./tabs/ansoTab";
import News from "./tabs/newsTab";
import OtherContentTab from "./tabs/otherContentTab";
import RelatedTopicsTab from "./tabs/relatedTopics/relatedTopicsTab";
import SnapshotsTab from "./tabs/snapshots/snapshotsTab";
import SpangldTab from "./tabs/spangldTab";
// import PreviewTab from "./tabs/previewTab";

const TOP_STORIES_TOPIC_ID = "9H2eDWDn0lCr41Vcy3PZj";
const SUGGESTED_TOPICS_TOPIC_ID = "P0wwzoZVMoYXpSIle0vNV";
const ELECTION_2022_TOPIC_ID = "sfF9UjQ4saQrlglrhlA9T";
const ELECTION_2022_MIDTERMS_UPDATES_TOPIC_ID = "4U45FKs_6EUsTLJf4fa3Q";
const ELECTION_2024_UPDATES_TOPIC_ID = "V87-4FwLdtSXpvgB-OWeW";

function ContentList({ topic, parentRequestedRefetch }) {
    const { pathname } = useLocation();
    const { url } = useRouteMatch();

    const isKnowledgePanelPage = pathname.startsWith("/knowledge-panel");

    const showNews = topic.id !== TOP_STORIES_TOPIC_ID && topic.id !== SUGGESTED_TOPICS_TOPIC_ID;
    const showSpangld =
        !isKnowledgePanelPage &&
        topic.id !== ELECTION_2022_TOPIC_ID &&
        topic.id !== ELECTION_2022_MIDTERMS_UPDATES_TOPIC_ID &&
        topic.id !== ELECTION_2024_UPDATES_TOPIC_ID;
    const showAnso =
        !isKnowledgePanelPage &&
        topic.id !== ELECTION_2022_TOPIC_ID &&
        topic.id !== ELECTION_2022_MIDTERMS_UPDATES_TOPIC_ID &&
        topic.id !== ELECTION_2024_UPDATES_TOPIC_ID;
    const shopStories = !isKnowledgePanelPage && topic.id !== SUGGESTED_TOPICS_TOPIC_ID && topic.id !== ELECTION_2024_UPDATES_TOPIC_ID;
    const showRelatedTopics = !isKnowledgePanelPage && topic.id !== ELECTION_2024_UPDATES_TOPIC_ID;
    const showOtherContent = !isKnowledgePanelPage && topic.id !== ELECTION_2024_UPDATES_TOPIC_ID;

    return (
        <div className="ContentList mt-4">
            <ul className="nav nav-tabs">
                {showNews && (
                    <>
                        <NavLink
                            to={`${url}`}
                            exact
                            style={{ color: "#6d8082" }}
                            className="nav-item nav-link pointer"
                            activeClassName="active text-primary"
                        >
                            {topic.id === ELECTION_2022_MIDTERMS_UPDATES_TOPIC_ID || topic.id === ELECTION_2024_UPDATES_TOPIC_ID
                                ? "Breaking News"
                                : "News"}
                        </NavLink>
                        {topic.id === ELECTION_2024_UPDATES_TOPIC_ID && (
                            <NavLink
                                to={`${url}/featured`}
                                exact
                                style={{ color: "#6d8082" }}
                                className="nav-item nav-link pointer"
                                activeClassName="active text-primary"
                            >
                                Featured
                            </NavLink>
                        )}

                        {showSpangld && (
                            <NavLink
                                to={`${url}/spangld`}
                                style={{ color: "#6d8082" }}
                                className="nav-item nav-link pointer"
                                activeClassName="active text-primary"
                            >
                                Spangld
                            </NavLink>
                        )}
                        {showAnso && (
                            <NavLink
                                to={`${url}/anso`}
                                style={{ color: "#6d8082" }}
                                className="nav-item nav-link pointer"
                                activeClassName="active text-primary"
                            >
                                ANSO
                            </NavLink>
                        )}
                    </>
                )}
                {shopStories && (
                    <NavLink
                        to={`${url}/snapshots`}
                        style={{ color: "#6d8082" }}
                        className="nav-item nav-link pointer"
                        activeClassName="active text-primary"
                    >
                        {topic.id === ELECTION_2022_MIDTERMS_UPDATES_TOPIC_ID ? "Popular Election Stories" : "Stories"}
                    </NavLink>
                )}
                {showRelatedTopics &&
                    topic.id !== TOP_STORIES_TOPIC_ID &&
                    topic.id !== ELECTION_2022_TOPIC_ID &&
                    topic.id !== ELECTION_2022_MIDTERMS_UPDATES_TOPIC_ID && (
                        <NavLink
                            to={`${url}/related-topics`}
                            style={{ color: "#6d8082" }}
                            className="nav-item nav-link pointer"
                            activeClassName="active text-primary"
                        >
                            Related topics
                        </NavLink>
                    )}
                {showOtherContent &&
                    topic.id !== TOP_STORIES_TOPIC_ID &&
                    topic.id !== SUGGESTED_TOPICS_TOPIC_ID &&
                    topic.id !== ELECTION_2022_MIDTERMS_UPDATES_TOPIC_ID && (
                        <NavLink
                            to={`${url}/other-content`}
                            style={{ color: "#6d8082" }}
                            className="nav-item nav-link pointer"
                            activeClassName="active text-primary"
                        >
                            Other content
                        </NavLink>
                    )}
                {/* <NavLink to={`${url}/preview`} style={{ color: "#6d8082" }} className="nav-item nav-link pointer" activeClassName="active text-primary">
                    Preview
                </NavLink> */}
            </ul>
            <Switch>
                <Route path={`${url}/spangld`}>
                    <SpangldTab parentRequestedRefetch={parentRequestedRefetch} spangld={topic.spangld} topicId={topic.id} />
                </Route>
                <Route path={`${url}/anso`}>
                    <AnsoTab parentRequestedRefetch={parentRequestedRefetch} anso={topic.related_anso} topicId={topic.id} />
                </Route>
                <Route path={`${url}/snapshots`}>
                    <SnapshotsTab parentRequestedRefetch={parentRequestedRefetch} topicId={topic.id} />
                </Route>
                <Route path={`${url}/related-topics`}>
                    <RelatedTopicsTab
                        parentRequestedRefetch={parentRequestedRefetch}
                        topicId={topic.id}
                        relatedTopics={topic.related_deep_dive_topics}
                    />
                </Route>
                {/* <Route path={`${url}/preview`}>
                    <PreviewTab requestRefresh={requestRefresh} topic={topic} />
                </Route> */}
                <Route path={`${url}/other-content`}>
                    <OtherContentTab parentRequestedRefetch={parentRequestedRefetch} content={topic.other_content} topicId={topic.id} />
                </Route>
                <Route path={`${url}/featured`}>
                    <News parentRequestedRefetch={parentRequestedRefetch} topicId={topic.id} type="deep_dive_featured_news" />
                </Route>
                <Route path={[`${url}/`]}>
                    <News parentRequestedRefetch={parentRequestedRefetch} news={topic.news} topicId={topic.id} />
                </Route>
            </Switch>
        </div>
    );
}

ContentList.propTypes = {
    topic: PropTypes.object.isRequired,
    requestRefresh: PropTypes.func.isRequired,
    parentRequestedRefetch: PropTypes.number.isRequired
};

export default ContentList;
