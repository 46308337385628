import React from "react";
import ReactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { datadogRum } from "@datadog/browser-rum";
import { AuthProvider } from "react-oidc-context";
import { AlertTemplate } from "./components/alertTemplate/alertTemplate.js";
import { ENABLE_DATADOG, DATADOG_ENV, APP_VERSION, OPENID_ISSUER, OPENID_CLIENT_ID } from "./config";
import App from "./App.js";
import "./index.css";

if (ENABLE_DATADOG) {
    datadogRum.init({
        applicationId: "f0df6c3b-c016-40a0-99a6-fc852a1944b6",
        clientToken: "pubbe188a2278b8433d8876cae8c35fdecd",
        site: "datadoghq.com",
        service: "admin-panel",
        env: DATADOG_ENV,
        version: APP_VERSION || "unknown contact admin"
    });
}

const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 30000,
    offset: "30px",
    // you can also just use 'scale'
    transition: transitions.SCALE,
    type: "error"
};

const oidcConfig = {
    authority: OPENID_ISSUER,
    client_id: OPENID_CLIENT_ID,
    redirect_uri: window.location.origin,
    response_type: "code", // Use "code" for Authorization Code Flow with PKCE
    scope: "openid", // Scopes you are requesting from the OIDC provider
    post_logout_redirect_uri: window.location.origin + "/", // Where to redirect after logout
    onSigninCallback: _user => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
    onRemoveUser: () => {
        window.location.pathname = "";
    }
};

ReactDOM.render(
    <AuthProvider {...oidcConfig}>
        <AlertProvider template={AlertTemplate} {...options}>
            <App />
        </AlertProvider>
    </AuthProvider>,

    document.getElementById("root")
);
