import React, { useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "475px"
    }
};

Modal.setAppElement("#root");

function CategoryTagModal({ categories, topicId, onSave, onClose }) {
    const [selectedParentId, setSelectedParentId] = useState("");
    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="Select a category">
            <div className="modal-header">
                <h4>Select a Category</h4>
            </div>
            <div className="modal-body">
                <div className="position-relative">
                    {categories &&
                        categories.map(category => (
                            <div key={category.id} className="card my-2 mx-2">
                                <button className={`btn ${selectedParentId === category.id ? "btn-primary" : "btn-outline-primary"}`} onClick={() => setSelectedParentId(category.id, topicId)}>
                                    {category.name}
                                </button>
                            </div>
                        ))}
                </div>
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Close
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        onSave(topicId, selectedParentId);
                    }}>
                    Save changes
                </button>
            </div>
        </Modal>
    );
}

CategoryTagModal.propTypes = {
    categories: PropTypes.array.isRequired,
    topicId: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CategoryTagModal;
