/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { formatDistanceToNowStrict } from "date-fns";
import NewDeepDiveTopic from "../newDeepDive";
import CategoryTagModal from "../category/modals/deepDiveCatTagModal";
import API from "../../../api/deepDiveAPI";
import Table from "../../utils/table";
import PaginationBar from "../../utils/pagination";
import SearchBar from "../../utils/searchBar";
import "./deepDiveTopicsList.scss";

const DEFAULT_DEEPDIVE_TOPICS_PER_PAGE = 10;

const DEFAULT_FILTER = "all";

function DeepDiveTopicsList() {
    const alert = useAlert();
    const { pathname } = useLocation();
    const { user } = useAuth();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [showNewTopicCard, setShowNewTopicCard] = useState(false);
    const [topics, setTopics] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState("");
    const [categories, setCategories] = useState(null);
    const [total, setTotal] = useState(0);
    const [params, setParams] = useState(() => ({
        page: 1,
        perPage: parseInt(localStorage.getItem("DEEPDIVE_TOPICS_PER_PAGE")) || DEFAULT_DEEPDIVE_TOPICS_PER_PAGE,
        q: "",
        status: localStorage.getItem("TOPICS_FILTER_V2") || DEFAULT_FILTER
    }));

    const isKnowledgePanelPage = pathname.startsWith("/knowledge-panel");
    const pathnamePrefix = isKnowledgePanelPage ? "/knowledge-panel" : "/deep-dive";

    const getLinkedCategories = useCallback((parentTopic, currentTopicID) => {
        return (
            <>
                {" "}
                <Link key={parentTopic.id} to={`/deep-dive/category/${parentTopic.id}`} className="btn btn-info btn-sm mr-2 my-1">
                    {parentTopic.title}
                </Link>{" "}
                <button
                    onClick={() => setSelectedTopic(currentTopicID)}
                    className="btn btn-sm"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Add Category Link"
                >
                    <i className="fas fa-plus"></i>
                </button>{" "}
            </>
        );
    }, []);

    const columns = React.useMemo(() => {
        const result = [
            {
                Header: <span style={{ float: "left" }}>Name</span>,
                accessor: "name",
                Cell: row => (
                    <>
                        <Link
                            className={`text-primary ${!row.data[row.row.index].is_active ? "text-danger" : ""}`}
                            to={`${pathnamePrefix}/topic/${row.data[row.row.index].id}`}
                        >
                            {row.value}
                        </Link>
                        {!row.data[row.row.index].is_active && (
                            <span
                                onClick={() => alert.show("WARNING: This topic is deactivated.", { timeout: 10000, type: "warning" })}
                                className="ml-2"
                                title={"WARNING: This topic is disabled."}
                            >
                                <i className="fas fa-exclamation-triangle text-warning position-absolute pointer"></i>
                            </span>
                        )}
                    </>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Updated</span>,
                accessor: "updatedAt",
                Cell: row => formatDistanceToNowStrict(new Date(row.value), { addSuffix: true })
            },
            {
                Header: <span style={{ float: "left" }}>Created</span>,
                accessor: "createdAt",
                Cell: row => formatDistanceToNowStrict(new Date(row.value), { addSuffix: true })
            }
        ];

        if (!isKnowledgePanelPage) {
            result.push({
                Header: <span style={{ float: "left" }}>Linked to Category</span>,
                accessor: "category",
                Cell: row => getLinkedCategories(row.value, row.data[row.row.index].id)
            });
        }
        return result;
    }, [alert, getLinkedCategories, isKnowledgePanelPage, pathnamePrefix]);

    const fetchTopics = useCallback(() => {
        setIsRefreshing(true);
        API.getTopicsList(user.access_token, params, isKnowledgePanelPage ? "knowledge_panel_topic" : "deep_dive_topic")
            .then(data => {
                setTopics(data.data);
                setTotal(data.total);
            })
            .catch(e => alert.show(e))
            .finally(() => setIsRefreshing(false));
    }, [alert, isKnowledgePanelPage, user.access_token, params]);

    const fetchCategories = useCallback(() => {
        API.getAllDeepDiveCategories(user.access_token)
            .then(data => setCategories(data && data.data))
            .catch(e => alert.show(e));
    }, [alert, user.access_token]);

    // Fetch data on mount
    useEffect(() => {
        fetchTopics();
        fetchCategories();
    }, [fetchCategories, fetchTopics]);

    useEffect(() => {
        fetchTopics();
    }, [fetchTopics, params]);

    const onNewTopicSuccess = useCallback(() => {
        fetchTopics();
        setShowNewTopicCard(false);
    }, [fetchTopics]);

    const addCategoryLink = useCallback(
        (topicId, parentId) => {
            if (parentId) {
                API.relinkTopicToCategory(topicId, parentId, user.access_token)
                    .then(() => setSelectedTopic(""))
                    .catch(e => alert.show(e))
                    .finally(() => fetchTopics());
            }
        },
        [alert, fetchTopics, user.access_token]
    );

    const onPerPageChange = useCallback(e => {
        const perPageNum = parseInt(e.target.value);
        localStorage.setItem("DEEPDIVE_TOPICS_PER_PAGE", perPageNum);
        setParams(currentParams => ({ ...currentParams, perPage: perPageNum }));
    }, []);

    const searchBarInputChange = useCallback(q => {
        setParams(currentParams => ({ ...currentParams, q }));
    }, []);

    const onFilterChange = useCallback(e => {
        const value = e?.target?.value;

        if (value) {
            localStorage.setItem("TOPICS_FILTER_V2", value);
            setParams(currentParams => (currentParams ? { ...currentParams, status: value } : currentParams));
        }
    }, []);

    return (
        <div className="DeepDiveTopicList">
            <h4 className="d-inline align-top">{isKnowledgePanelPage ? "Knowledge Panel Topics" : "Deep Dive Topics"}</h4>
            <span onClick={fetchTopics} className="mx-3 pointer h4">
                <i className={isRefreshing ? "fa fa-refresh fa-spin" : "fas fa-sync-alt"} aria-hidden={isRefreshing ? "true" : "false"}></i>
            </span>
            <span onClick={() => setShowNewTopicCard(true)} className="h4 pointer">
                <i className="fas fa-plus"></i>
            </span>
            <SearchBar
                className="mt-2"
                isSearching={isRefreshing}
                onChange={searchBarInputChange}
                onSubmit={searchBarInputChange}
                placeholderText="Search topics by name"
            />
            <fieldset className="d-flex form-group p-0 m-0 mb-2 my-3">
                <div className="form-check d-inline">
                    <label className="form-check-label">
                        <input
                            onChange={onFilterChange}
                            type="radio"
                            className="form-check-input"
                            name="all"
                            value="all"
                            checked={params?.status === "all"}
                        />
                        All
                    </label>
                </div>
                <div className="form-check d-inline ml-3">
                    <label className="form-check-label">
                        <input
                            onChange={onFilterChange}
                            type="radio"
                            className="form-check-input"
                            name="published"
                            value="published"
                            checked={params?.status === "published"}
                        />
                        Published
                    </label>
                </div>
                <div className="form-check d-inline ml-3">
                    <label className="form-check-label">
                        <input
                            onChange={onFilterChange}
                            type="radio"
                            className="form-check-input"
                            name="draft"
                            value="draft"
                            checked={params?.status === "draft"}
                        />
                        Draft
                    </label>
                </div>
            </fieldset>
            {topics && topics.length ? (
                <PaginationBar
                    className="mb-2 mt-2"
                    total={total}
                    page={params.page}
                    perPage={params.perPage}
                    onClick={selectedPage => setParams({ ...params, page: selectedPage })}
                    onPerPageChange={onPerPageChange}
                />
            ) : null}
            {showNewTopicCard && <NewDeepDiveTopic type="topic" onDone={onNewTopicSuccess} onClose={() => setShowNewTopicCard(false)} />}
            <div className="mt-2 w-100 bg-white">{topics && <Table columns={columns} data={topics} />}</div>
            {selectedTopic && (
                <CategoryTagModal topicId={selectedTopic} categories={categories} onSave={addCategoryLink} onClose={() => setSelectedTopic(null)} />
            )}
        </div>
    );
}

export default DeepDiveTopicsList;
