import React from "react";
import { Route, Switch } from "react-router-dom";
import TwitterReportList from "./twitterReportList";
import TwitterReportPreview from "./twitterReportPreview";

function Reports() {
    return (
        <Switch>
            <Route path="/reports/twitter/preview">
                <TwitterReportPreview />
            </Route>
            <Route path="/reports/twitter">
                <TwitterReportList />
            </Route>
        </Switch>
    );
}

export default Reports;
