/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { useAuth } from "react-oidc-context";
import API from "../../../../../api/deepDiveAPI";
import SnapshotModal from "../../modals/addSnapshotStories";
import SnapshotsTable from "../snapshots/snapshotsTable";

function SnapshotsTab({ topicId, parentRequestedRefetch }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [stories, setStories] = useState(null);
    const [openSnapshotModal, setOpenSnapshotModal] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const fetchRelatedStories = useCallback(() => {
        API.getTopicRelatedStories({ topicId, perPage: 50, page: 1 }, user.access_token)
            .then(data => {
                setStories(data && data.stories && data.stories.data);
                // setTotal(data.total); // TODO: Nic - add support for pagination
            })
            .catch(e => alert.show(e))
            .finally(() => setIsFetching(false));
    }, [alert, user.access_token, topicId]);

    useEffect(() => {
        fetchRelatedStories();
    }, [fetchRelatedStories, parentRequestedRefetch]);

    const requestRefresh = useCallback(() => fetchRelatedStories(), [fetchRelatedStories]);

    const deleteStory = useCallback(
        contentId => {
            API.unLinkRelatedStoryOrTopicToTopic(topicId, contentId, "story", user.access_token)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, user.access_token, requestRefresh, topicId]
    );

    const repositionStories = useCallback(
        (itemId, direction) => {
            let storyToMoveId = "";
            let moveAfterId = "";
            const currentIdx = stories.findIndex(x => x.id === itemId);

            if (direction === "up") {
                storyToMoveId = stories[currentIdx - 1].id;
                moveAfterId = itemId;
            } else {
                storyToMoveId = itemId;
                moveAfterId = stories[currentIdx + 1].id;
            }

            const request = { topicId, storyToMoveId, moveAfterId };
            API.reorderRelatedCompositeStory(user.access_token, request)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, user.access_token, requestRefresh, stories, topicId]
    );

    return (
        <div className="SnapshotsTab mt-3">
            {openSnapshotModal ? (
                <SnapshotModal topicId={topicId} content={stories} onSuccess={requestRefresh} onClose={() => setOpenSnapshotModal(false)} />
            ) : (
                <div className="d-flex justify-content-center mt-2 mx-auto">
                    <span onClick={() => setOpenSnapshotModal(true)} className="h4 pointer">
                        <i className="fas fa-plus"></i>
                    </span>
                </div>
            )}
            <small>
                Note: only the <strong>top 2</strong> items are visible on Freespoke.com
            </small>
            {stories && stories.length > 0 && (
                <SnapshotsTable stories={stories} type="edit" removeAction={deleteStory} moveAction={repositionStories} />
            )}
            {isFetching ? (
                <div className="mx-3 spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                stories && stories.length === 0 && <p className="text-center">No items</p>
            )}
        </div>
    );
}

SnapshotsTab.propTypes = {
    topicId: PropTypes.string.isRequired,
    parentRequestedRefetch: PropTypes.number.isRequired
};

export default SnapshotsTab;
