export function displayAPIError(error) {
    try {
        const parsedError = JSON.stringify(error, null, 2);
        return (
            <div>
                <pre style={{ color: "white", overflow: "auto", whiteSpace: "pre-wrap" }}>{parsedError}</pre>
            </div>
        );
    } catch {
        return <div>Failed to parse Error</div>;
    }
}
