import axios from "axios";

export function handleAPIError(error) {
    if (!axios.isAxiosError(error)) {
        return handleGenericError(error);
    }

    let message = "An unknown error occurred.";
    let code = "UNKNOWN_ERROR";
    let name = "UnknownError";

    if (error.response) {
        // The server responded with a status code that falls out of the range of 2xx
        if (error.response.data && typeof error.response.data === "object") {
            message = JSON.stringify(error.response.data);
        } else {
            message = error.response.data?.toString() || error?.message;
        }

        code = error.response.status.toString();
        name = error.response.statusText;
    } else if (error.request) {
        // The request was made but no response was received
        message = "No response was received from the server.";
        code = "NO_RESPONSE";
        name = "NoResponseError";
    } else {
        // Something happened in setting up the request that triggered an Error
        message = error.message;
        name = "RequestSetupError";
    }

    return {
        message,
        code,
        name
    };
}

function handleGenericError(error) {
    console.log("generic error");
    return {
        message: error.message || "An unknown error occurred.",
        code: "GENERIC_ERROR",
        name: "GenericError"
    };
}
