/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { useAuth } from "react-oidc-context";
import API from "../../../../api/deepDiveAPI";
import Table from "../../../utils/table";
import SpangldModal from "../modals/addOrEditSpangld";
import ConfirmPopup from "../../../utils/confirmPopup";
import { isValidHttpUrl, toTimeAgo } from "../../../../utils/utils";
const noImageDataURI = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

function SpangldTab({ topicId, parentRequestedRefetch }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [spangldToEdit, setSpangldToEdit] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [spangld, setSpangld] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchNewsContent = useCallback(() => {
        setIsFetching(true);
        API.getContentByType(user.access_token, topicId, "deep_dive_spangld")
            .then(data => {
                setSpangld(data.data);
                // setTotal(data.total); // TODO: Nic - add support for pagination
            })
            .catch(e => alert.show(e))
            .finally(() => setIsFetching(false));
    }, [alert, user.access_token, topicId]);

    useEffect(() => {
        fetchNewsContent();
    }, [fetchNewsContent, parentRequestedRefetch]);

    const requestRefresh = useCallback(() => fetchNewsContent(), [fetchNewsContent]);

    const repositionSpangld = useCallback(
        (itemId, direction) => {
            let contentToMoveId = "";
            let moveAfterContentId = "";
            const currentIdx = spangld.findIndex(x => x.id === itemId);

            if (direction === "up") {
                contentToMoveId = spangld[currentIdx - 1].id;
                moveAfterContentId = itemId;
            } else {
                contentToMoveId = itemId;
                moveAfterContentId = spangld[currentIdx + 1].id;
            }

            const request = { topicId, contentToMoveId, moveAfterContentId };
            API.reorderTopicContent(user.access_token, request)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, user.access_token, requestRefresh, spangld, topicId]
    );

    const unlinkContent = useCallback(
        spangldId => {
            API.unlinkTopicItem(spangldId, "spangld", topicId, user.access_token)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, user.access_token, requestRefresh, topicId]
    );

    const columns = useMemo(
        () => [
            {
                Header: <span style={{ float: "left" }}>Image</span>,
                accessor: "article.images",
                Cell: row => (
                    <div className="position-relative d-flex">
                        <>
                            {(!Array.isArray(row.value) || !row.value.length) && (
                                <span className="position-absolute translate-middle">No image selected</span>
                            )}
                            {Array.isArray(row.value) && row.value[0] && !isValidHttpUrl(row.value[0]) && (
                                <span className="position-absolute translate-middle">Invalid image URL</span>
                            )}
                            <img
                                style={{ objectFit: "cover", minWidth: "125px", height: "75px" }}
                                className="img-thumbnail m-0 p-0"
                                src={isValidHttpUrl(row.value[0]) ? row.value[0] : noImageDataURI}
                                alt="article"
                            />
                        </>
                    </div>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Title</span>,
                accessor: "article.title",
                Cell: row => (
                    <p className="d-inline-flex align-middle m-0 text-wrap">
                        <strong>{row.value}</strong>
                    </p>
                )
            },
            // {
            //     Header: <span style={{ float: "left" }}>Description</span>,
            //     accessor: "spangld.description",
            //     Cell: row => (
            //         <p className="d-inline-flex align-middle m-0 pointer text-wrap">
            //             {row.value}
            //         </p>
            //     )
            // },
            // {
            //     Header: <span style={{ float: "left" }}>Bias</span>,
            //     accessor: "spangld.bias",
            //     Cell: row => (
            //         <div className="d-flex align-items-center">
            //             {row.value ? (
            //                 <span>
            //                     <i className={`fas fa-circle ${row.value}`}></i>
            //                 </span>
            //             ) : (
            //                 ""
            //             )}
            //         </div>
            //     )
            // },
            // {
            //     Header: <span style={{ float: "left" }}>Publisher</span>,
            //     accessor: "spangld.publisherName",
            //     Cell: row => (
            //         <a
            //             className="d-inline-flex align-middle m-0 text-secondary"
            //             href={spangld[row.row.index].spangld.url}
            //             target="_blank"
            //             rel="noopener noreferrer">
            //             {row.value}
            //         </a>
            //     )
            // },
            // {
            //     Header: <span style={{ float: "left" }}>Logo</span>,
            //     accessor: "spangld.publisherLogo",
            //     Cell: row => <img style={{ objectFit: "contain", maxWidth: "60px", height: "35px" }} className="img-thumbnail" src={row.value}></img>
            // },
            {
                Header: <span style={{ float: "left" }}>Published</span>,
                accessor: "article.datePublished",
                Cell: row => (
                    <p style={{ minWidth: "90px" }} className="d-inline-flex align-middle m-0 text-secondary">
                        {toTimeAgo(row.value)}
                    </p>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Pinned</span>,
                accessor: "createdAt",
                Cell: row => (
                    <p style={{ minWidth: "90px" }} className="d-inline-flex align-middle m-0 text-secondary">
                        {toTimeAgo(row.value)}
                    </p>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Actions</span>,
                accessor: "_", // this is just a workaround for custom header
                Cell: row => {
                    return (
                        <div style={{ minWidth: "180px" }}>
                            {/* <button onClick={() => setSpangldToEdit(spangld[row.row.index])} className="btn btn-outline-primary btn-sm">
                            Edit
                        </button> */}
                            <ConfirmPopup onConfirm={() => unlinkContent(row.data[row.row.index].id)}>
                                <button className="btn btn-outline-danger btn-sm ml-2">Unlink</button>
                            </ConfirmPopup>
                            <button
                                onClick={() => repositionSpangld(row.data[row.row.index].id, "up")}
                                className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === 0 ? "invisible" : ""}`}
                            >
                                <i className="fas fa-arrow-up"></i>
                            </button>
                            <button
                                onClick={() => repositionSpangld(row.data[row.row.index].id, "down")}
                                className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === row.rows.length - 1 ? "invisible" : ""}`}
                            >
                                <i className="fas fa-arrow-down"></i>
                            </button>
                        </div>
                    );
                }
            }
        ],
        [repositionSpangld, unlinkContent]
    );

    const onSuccess = useCallback(() => {
        setSpangldToEdit(false);
        setOpenEditModal(false);
        requestRefresh();
    }, [requestRefresh]);

    return (
        <div className="SpangldTab mt-3">
            {spangldToEdit && (
                <SpangldModal type="edit" spangld={spangldToEdit} topicId={topicId} onSuccess={onSuccess} onClose={() => setSpangldToEdit(false)} />
            )}
            {openEditModal ? (
                <SpangldModal type="new" topicId={topicId} onSuccess={onSuccess} onClose={() => setOpenEditModal(false)} />
            ) : (
                <div className="d-flex justify-content-center mt-2">
                    <span onClick={() => setOpenEditModal(true)} className="h4 pointer">
                        <i className="fas fa-plus"></i>
                    </span>
                </div>
            )}
            <small>
                Note: only the <strong>top 1</strong> item is visible on Freespoke.com
            </small>
            {spangld && <Table columns={columns} data={spangld} />}
            {isFetching ? (
                <div className="mx-auto spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                spangld && spangld.length === 0 && <p className="text-center">No items</p>
            )}
        </div>
    );
}

SpangldTab.propTypes = {
    topicId: PropTypes.string.isRequired,
    parentRequestedRefetch: PropTypes.number.isRequired
};

export default SpangldTab;
