/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import prettyBytes from "pretty-bytes";
import { useAuth } from "react-oidc-context";
import { Link, useHistory } from "react-router-dom";
import { formatDistanceToNowStrict } from "date-fns";
import { useAlert } from "react-alert";
import DatePicker from "../utils/datePicker";
import Table from "../utils/table";
import FileDownload from "../utils/fileDownload";
import { getAuthHeaders } from "../../utils/utils";
import { BACKEND_URL, PROXY_URL, PROXY_AUTH_KEY } from "../../config";
import "react-datepicker/dist/react-datepicker.css";

function TwitterReports() {
    const perPage = 25;
    const alert = useAlert();
    const history = useHistory();
    const { user } = useAuth();
    const [isFetching, setIsFetching] = useState(false);
    const [reports, setReports] = useState(null);
    const [reportDate, setReportDate] = useState(new Date());

    const getReportAccessURL = useCallback(
        async id => {
            const headers = getAuthHeaders(user.access_token);
            const { data } = await axios.get(`${BACKEND_URL}/admin/reports/twitter/url?id=${id}`, { headers });
            return data.url;
        },
        [user.access_token]
    );

    const downloadReport = useCallback(
        async id => {
            try {
                const url = await getReportAccessURL(id);
                const headers = { Authorization: `Basic ${PROXY_AUTH_KEY}` };
                const { data } = await axios.get(`${PROXY_URL}?address=${url}`, { headers, responseType: "blob" });
                FileDownload(data, id);
            } catch (e) {
                alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" });
            }
        },
        [alert, getReportAccessURL]
    );

    const columns = useMemo(
        () => [
            {
                Header: () => <span style={{ float: "left" }}>Name</span>,
                accessor: "id",
                Cell: row => (
                    <>
                        <Link to={`/reports/twitter/preview?id=${row.value}`} className="text-primary">
                            <span>{row.value}</span>
                        </Link>
                        <span onClick={() => downloadReport(row.value)} className="float-right pointer" title="Download report">
                            <i className="fas fa-file-download ml-3"></i>
                        </span>
                        <span
                            onClick={() => history.push(`/reports/twitter/preview?id=${row.value}`)}
                            className="float-right pointer"
                            title="Preview report"
                        >
                            <i className="fas fa-eye"></i>
                        </span>
                    </>
                )
            },
            {
                Header: () => <span style={{ float: "left" }}>Updated</span>,
                accessor: "last_modified",
                Cell: row => formatDistanceToNowStrict(new Date(row.value), { addSuffix: true })
            },
            {
                Header: () => <span style={{ float: "left" }}>Size</span>,
                accessor: "size",
                Cell: row => <span>{prettyBytes(row.value)}</span>
            }
        ],
        [downloadReport, history]
    );

    const fetchReports = useCallback(async () => {
        try {
            const headers = getAuthHeaders(user.access_token);
            setIsFetching(true);
            const { data } = await axios.get(`${BACKEND_URL}/admin/reports/twitter/list?date=${reportDate.toISOString()}&perPage=${perPage}`, {
                headers
            });
            if (Array.isArray(data.data)) {
                data.data.reverse();
            }
            setReports(data.data);
        } catch (e) {
            alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" });
        } finally {
            setIsFetching(false);
        }
    }, [user.access_token, reportDate, alert]);

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    useEffect(() => {
        fetchReports();
    }, [fetchReports, reportDate]);

    return (
        <div className="p-4 bg-light">
            <h4>
                Twitter Reports
                {!isFetching ? (
                    <span onClick={fetchReports} className="pointer ml-3">
                        <i className="fas fa-sync-alt"></i>
                    </span>
                ) : (
                    <span className="pointer">
                        <i className="fa fa-refresh fa-spin ml-3" aria-hidden="true"></i>
                    </span>
                )}
            </h4>
            <div className="d-inline form-group">
                <label className="col-form-label mr-2">Select Date:</label>
                <DatePicker className="form-control-plaintext bg-white pl-2" selected={reportDate} onChange={date => setReportDate(date)} />
            </div>
            <div style={{ maxWidth: "1200px" }}>
                <div className="mt-2 w-100 bg-white">{reports && <Table columns={columns} data={reports} />}</div>
            </div>
        </div>
    );
}

export default TwitterReports;
