/* eslint-disable jsx-a11y/alt-text */
import PropTypes from "prop-types";
import { useState } from "react";
import { useAlert } from "react-alert";
import Modal from "react-modal";
import { useAuth } from "react-oidc-context";
import API from "../../../../api/deepDiveAPI";
import ScrapeAPI from "../../../../api/scrapeAPI";
import { ReactComponent as TwitterIcon } from "../../../../static/svg/twitter.svg";
import { isSpangldURL, isTweetURL, isValidHttpUrl } from "../../../../utils/utils";
import Tweet from "../../../tweet";
import { toTimeAgo } from "../../../../utils/utils";
import DatePicker from "../../../utils/datePicker";
import { safeParseJson } from "../../../utils/parse";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "475px"
    }
};

const noImageDataURI = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function ContentModal({ type, parentType, content, topicId, onClose, onSuccess }) {
    const isEdit = type === "edit";
    const alert = useAlert();
    const { user } = useAuth();
    const [isScraping, setIsScraping] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState(() => {
        if (isEdit) {
            return {
                title: content.title || "",
                description: content.description || "",
                datePublished: content.datePublished || "",
                imageUrl: (Array.isArray(content.images) && content.images[0]) || "",
                // publisherLogo: content.publisherLogo || "",
                publisherName: content.publisherName || "",
                url: content.url || "",
                bias: content.bias || ""
            };
        }
        // prettier-ignore
        return {
                title:          (isEdit && content.title) || "",
                description:    (isEdit && content.description) || "",
                // author:         (isEdit && content.author) || "",
                datePublished:  (isEdit && content.datePublished) || "",
                imageUrl:       (isEdit && content.imageUrl) || "",
                // publisherLogo:  (isEdit && content.publisherLogo) || "",
                publisherName:  (isEdit && content.publisherName) || "",
                url:            (isEdit && content.url) || "",
                bias:           (isEdit && content.bias) || "",
        }
    });

    const isTweet = isTweetURL(formData.url);

    const onScrapeClick = () => {
        if (!isValidHttpUrl(formData.url)) {
            return alert.show("Invalid URL");
        }
        if (parentType === "spangld" && !isSpangldURL(formData.url)) {
            return alert.show("Invalid URL: Only URL with `spangld.com' domain allowed");
        }
        setIsScraping(true);
        // to get tweet metadata we use another API
        ScrapeAPI.scrapeMetadataFromUrl(formData.url, user.access_token)
            .then(data => {
                // prettier-ignore
                setFormData({
                            // author:         data.author || "",
                            datePublished:  data.datePublished || "",
                            description:    data.description || "",
                            imageUrl:       data.imageUrl || "",
                            // publisherLogo:  data.publisherLogo || "",
                            publisherName:  data.publisherName || "",
                            title:          data.title || "",
                            url:            data.url || "",
                            bias:           (formData.bias) || "",
                    });
                console.log("scrapped data: ", data);
            })
            .catch(e => alert.show(e))
            .finally(() => setIsScraping(false));
        // }
    };

    const createTopicUpdateRequest = () => {
        if (isTweet) {
            if (content.bias === formData.bias && content.datePublished === formData.datePublished) {
                return undefined;
            }
            return {
                tweet_id: content.id,
                data: {
                    bias: formData.bias,
                    datePublished: formData.datePublished
                }
            };
        } else {
            const request = {
                id: content.id,
                ...(content.title !== formData.title && { title: formData.title }),
                ...(content.description !== formData.description && { description: formData.description }),
                ...(content.bias !== formData.bias && { bias: formData.bias }),
                ...(((Array.isArray(content.images) && content.images[0]) || "") !== formData.imageUrl && { imageUrl: formData.imageUrl }),
                ...(content.datePublished !== formData.datePublished && { datePublished: formData.datePublished }),
                ...(content.publisherName !== formData.publisherName && { publisherName: formData.publisherName })
            };
            // no changes
            if (Object.keys(request).length === 1) {
                return undefined;
            }
            return request;
        }
    };

    const saveContent = () => {
        setIsSaving(true);
        if (isEdit) {
            const request = createTopicUpdateRequest();
            if (!request) {
                setIsSaving(false);
                return;
            }
            const contentType = isTweet ? "tweet" : parentType === "spangld" ? "spangld" : "article";
            API.updateTopicContent(request, contentType, user.access_token)
                .then(() => onSuccess())
                .catch(e => alert.show(e))
                .finally(() => setIsSaving(false));
        } else {
            const parsedJson = safeParseJson(formData.url);
            if (parsedJson) {
                API.postNewTopicItem(
                    {
                        topicId: topicId,
                        type: parentType,
                        // url: formData.url,
                        tweet: parsedJson,
                        bias: formData.bias
                    },
                    "tweet",
                    user.access_token
                )
                    .then(() => onSuccess())
                    .catch(e => alert.show(e))
                    .finally(() => setIsSaving(false));
            } else if (isTweet) {
                API.postNewTopicItem(
                    {
                        topicId: topicId,
                        type: parentType,
                        url: formData.url,
                        bias: formData.bias
                    },
                    "tweet",
                    user.access_token
                )
                    .then(() => onSuccess())
                    .catch(e => alert.show(e))
                    .finally(() => setIsSaving(false));
            } else {
                API.postNewTopicItem(
                    {
                        topicId: topicId,
                        url: formData.url,
                        type: parentType,
                        metadata: { ...formData, url: undefined }
                    },
                    parentType === "spangld" ? "spangld" : "article",
                    user.access_token
                )
                    .then(() => onSuccess())
                    .catch(e => alert.show(e))
                    .finally(() => setIsSaving(false));
            }
        }
    };

    const onInputChange = e => {
        if (e.target.type === "radio") {
            setFormData({ ...formData, bias: e.target.value });
        } else {
            if (e.target.name === "url") {
                const parsedJson = safeParseJson(e.target.value);
                if (parsedJson) {
                    setFormData({ ...formData, [e.target.name]: e.target.value, datePublished: parsedJson.date });
                    return;
                }
            }
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const onDateChange = updatedData => {
        setFormData({ ...formData, datePublished: updatedData });
    };

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles} contentLabel="Update category">
            <div className="modal-body">
                <div className="form-group has-danger">
                    <label className="form-control-label" htmlFor="url">
                        URL:
                    </label>
                    {isTweet && (
                        <span className="float-right">
                            <TwitterIcon style={{ width: "25px", height: "25px" }} />
                        </span>
                    )}
                    <div className="input-group mb-3">
                        <input
                            onChange={onInputChange}
                            disabled={isEdit}
                            value={formData.url}
                            type="text"
                            id="url"
                            name="url"
                            className="form-control"
                        />
                        {!isTweet && (
                            <div className="input-group-append">
                                <span onClick={onScrapeClick} className="input-group-text pointer">
                                    {isScraping ? (
                                        <div className="mx-3 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        "Scrape"
                                    )}
                                </span>
                            </div>
                        )}
                    </div>

                    {!isTweet && (
                        <>
                            <label className="form-control-label" htmlFor="title">
                                Title:
                            </label>
                            <textarea
                                onChange={onInputChange}
                                value={formData.title}
                                disabled={isTweet}
                                rows="2"
                                id="title"
                                name="title"
                                className="form-control"
                            />

                            <label className="form-control-label mt-2" htmlFor="description">
                                Description:
                            </label>
                            <textarea
                                onChange={onInputChange}
                                value={formData.description}
                                name="description"
                                id="description"
                                rows="3"
                                className="form-control"
                            />
                        </>
                    )}
                    <label className="form-control-label mt-2" htmlFor="bias">
                        Bias:
                    </label>
                    <select onChange={onInputChange} defaultValue={formData.bias} name="bias" id="bias" className="custom-select d-inline">
                        <option value="">Select one</option>
                        <option value="left">Left</option>
                        <option value="middle">Middle</option>
                        <option value="right">Right</option>
                        {/* <option value="satire">Satire</option> */}
                    </select>
                    {/* {!isTweet && ( */}
                    <>
                        {/* <label className="form-control-label mt-2" htmlFor="author">
                                Author:
                            </label>
                            <input
                                onChange={onInputChange}
                                value={formData.author}
                                type="text"
                                id="author"
                                name="author"
                                className="form-control"
                            /> */}

                        {!isTweet && (
                            <>
                                <label className="form-control-label mt-2" htmlFor="publisherName">
                                    Publisher name:
                                </label>
                                <input
                                    onChange={onInputChange}
                                    value={formData.publisherName}
                                    type="text"
                                    id="publisherName"
                                    name="publisherName"
                                    className="form-control"
                                />
                            </>
                        )}

                        {/* <label className="form-control-label mt-2" htmlFor="publisherLogo">
                                Publisher Logo:
                            </label>
                            <input
                                onChange={onInputChange}
                                value={formData.publisherLogo}
                                type="text"
                                id="publisherLogo"
                                name="publisherLogo"
                                className="form-control"
                            /> */}

                        <label className="form-control-label mt-2" htmlFor="datePublished">
                            Date published: <span>({toTimeAgo(formData.datePublished)})</span>
                        </label>
                        <div className="w-100" style={{ marginBottom: isTweet ? 300 : 0 }}>
                            <DatePicker
                                showTimeInput
                                className="form-control bg-white pl-2"
                                selected={formData.datePublished ? new Date(formData.datePublished) : null}
                                onChange={onDateChange}
                                placeholderText="Click to select a date"
                            />
                        </div>

                        {!isTweet && (
                            <>
                                <label className="form-control-label mt-2" htmlFor="imageUrl">
                                    Image URL:
                                </label>
                                <input
                                    onChange={onInputChange}
                                    value={formData.imageUrl}
                                    type="text"
                                    id="imageUrl"
                                    name="imageUrl"
                                    className="form-control"
                                />
                            </>
                        )}
                    </>
                </div>
                {!isTweet && (
                    <div className="position-relative">
                        {!formData.imageUrl && <span className="position-absolute translate-middle">No image selected</span>}
                        {formData.imageUrl && !isValidHttpUrl(formData.imageUrl) && (
                            <span className="position-absolute translate-middle">Invalid image URL</span>
                        )}
                        <img
                            style={{ objectFit: "cover", width: "400px", height: "200px" }}
                            className="img-thumbnail"
                            src={isValidHttpUrl(formData.imageUrl) ? formData.imageUrl : noImageDataURI}
                        />
                    </div>
                )}
                {isTweet && formData.tweet && <Tweet tweet={formData.tweet}></Tweet>}
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-secondary">
                    Cancel
                </button>
                <button className="btn btn-primary" onClick={saveContent}>
                    {isSaving ? (
                        <div className="d-inline-flex">
                            <span className="mx-2 spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Save</span>
                            </span>
                        </div>
                    ) : (
                        "Save"
                    )}
                </button>
            </div>
        </Modal>
    );
}

// prettier-ignore
ContentModal.propTypes = {
    type:       PropTypes.oneOf(["new", "edit"]).isRequired,
    parentType: PropTypes.oneOf(["deep_dive_content_news", "deep_dive_content_other", "deep_dive_spangld", "deep_dive_featured_news"]).isRequired,
    content:    PropTypes.object,
    topicId:    PropTypes.string.isRequired,
    onClose:    PropTypes.func.isRequired,
    onSuccess:  PropTypes.func.isRequired
};

export default ContentModal;
