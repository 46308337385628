// @ts-check

import React, { useCallback, useState } from "react";
import { useAuth } from "react-oidc-context";
import Modal from "../../utils/modal";
import { deleteDocument } from "./pinnedDocumentsApi";

import { getHttpErrorMessage } from "../../../utils/utils";
import "./deleteDocumentModal.scss";

/**
 * @param {object} props
 * @param {string} props.storyId
 * @param {string} props.documentId
 * @param {() => Promise<void>} props.onDelete
 * @returns
 */
export function DeleteDocumentModal({ storyId, documentId, onDelete }) {
    const { user } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const onSubmit = useCallback(async () => {
        if (!storyId || !documentId || isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        setError(null);
        try {
            if (!user?.access_token) {
                throw new Error("unauthenticated");
            }
            await deleteDocument({
                token: user.access_token,
                storyId,
                documentId
            });
            await onDelete();
            setIsOpen(false);
        } catch (e) {
            setError(getHttpErrorMessage(e));
        } finally {
            setIsSubmitting(false);
        }
    }, [documentId, isSubmitting, onDelete, storyId, user?.access_token]);

    return (
        <>
            <button onClick={() => setIsOpen(true)} className="btn btn-danger ml-auto float-right pointer" data-testid="delete-document-button">
                <i className="fas fa-trash"></i>
            </button>
            <Modal
                className="delete-document-modal"
                label="Delete Document"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                closeClassName="delete-document-modal-close"
            >
                <div>
                    <p>Are you sure you want to delete this document?</p>
                    <div className="d-flex flex-row align-items-center justify-content-center mt-2">
                        <button className="btn btn-danger mr-3" onClick={onSubmit} disabled={isSubmitting} data-testid="delete-document-confirmation">
                            {isSubmitting ? "Deleting" : "Delete Document"}
                        </button>
                        <button className="btn btn-secondary" onClick={() => setIsOpen(false)}>
                            Cancel
                        </button>
                    </div>
                    {error && (
                        <>
                            <br />
                            <span className="text-danger">{error}</span>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
}
