import axios from "axios";
import { getAuthHeaders } from "../utils/utils";
import { BACKEND_URL } from "../config";
import { getAxiosError } from "./errors";

// Fetch Library Videos
async function fetchVideos(params, token) {
    try {
        const headers = getAuthHeaders(token);
        const url = `${BACKEND_URL}/admin/videos`;
        const { data } = await axios.get(url, { params, headers });
        return data;
    } catch (e) {
        throw getAxiosError(e);
    }
}

// Update single video by id
async function updateVideoById(videoId, data, token) {
    try {
        const headers = getAuthHeaders(token);
        const validateStatus = status => status === 200 || status === 201;
        const url = `${BACKEND_URL}/admin/videos/${videoId}`;
        const response = await axios.patch(url, data, { headers, validateStatus });
        return response.data;
    } catch (e) {
        throw getAxiosError(e);
    }
}

// Delete single video by id
async function deleteVideoById(videoId, token) {
    try {
        const headers = getAuthHeaders(token);
        const url = `${BACKEND_URL}/admin/videos/${videoId}`;
        await axios.delete(url, { headers });
    } catch (e) {
        throw getAxiosError(e);
    }
}

const APIsList = {
    fetchVideos,
    updateVideoById,
    deleteVideoById
};

export default APIsList;
