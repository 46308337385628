import React, { useState, useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { useAlert } from "react-alert";
import isValidHostname from "is-valid-hostname";
import SearchBar from "../utils/searchBar";
import uniqBy from "lodash-es/uniqBy";
import { addCrawlerSources, discoverCrawlerSources } from "../../api/crawlerAPI";

function CrawlerAdd(props) {
    const { user } = useAuth();

    const alert = useAlert();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [sources, setSources] = useState(null);
    const [domain, setDomain] = useState("");

    const discoverSources = useCallback(
        q => {
            setDomain(q);
            if (!q || !isValidHostname(q)) {
                if (q?.startsWith("https://") || q?.startsWith("https://")) {
                    alert.show("Invalid input. Domain should not include http:// or https://");
                } else {
                    alert.show("Invalid input");
                }
                return;
            }

            setIsRefreshing(true);
            discoverCrawlerSources(q, user.access_token)
                .then(data => {
                    setIsRefreshing(true);
                    setSources(uniqBy(data?.sources, "url"));
                })
                .catch(e => {
                    alert.show(e);
                })
                .finally(() => setIsRefreshing(false));
        },
        [alert, user.access_token]
    );

    const onRemoveClick = url => {
        setSources(s => s.filter(src => src.url !== url));
    };

    const addSources = useCallback(() => {
        setIsRefreshing(true);
        setSources(null);

        addCrawlerSources(domain, sources, user.access_token)
            .then(() => {
                setIsRefreshing(true);
                alert.show("success!", {});
            })
            .catch(e => {
                alert.show(e);
            })
            .finally(() => {
                setIsRefreshing(false);
            });
    }, [alert, domain, user.access_token, sources]);

    return (
        <div className="CrawlerSourcesList">
            <h4 className="d-inline align-top">Discover Crawler Sources</h4>
            <span onClick={() => discoverSources(domain)} className="mx-3 pointer h4">
                <i className={isRefreshing ? "fa fa-refresh fa-spin" : "fas fa-sync-alt"} aria-hidden={isRefreshing ? "true" : "false"}></i>
            </span>
            <SearchBar className="mt-2" isSearching={isRefreshing} onSubmit={discoverSources} placeholderText="Enter a domain name" />

            {!isRefreshing ? (
                <div className="results">
                    {sources && sources.length > 0 ? (
                        <div>
                            <button className="btn btn-primary" onClick={addSources}>
                                Add {sources.length} {sources.length === 1 ? "Source" : "Sources"} To Crawler
                            </button>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ textAlign: "left" }}>
                                            Action
                                        </th>
                                        <th scope="col" style={{ textAlign: "left" }}>
                                            Source URL
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sources
                                        ? sources.map(src => {
                                              return <DiscoverRow key={src.url} src={src} user={props.user} onRemoveClick={onRemoveClick} />;
                                          })
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    ) : null}
                    {!isRefreshing && (!sources || sources.length === 0) ? <div className="mt-2">No results</div> : null}
                </div>
            ) : (
                ""
            )}
        </div>
    );
}

function DiscoverRow(props) {
    return (
        <tr key={props.src.url}>
            <td scope="col">
                <button className="btn btn-danger" onClick={() => props.onRemoveClick(props?.src?.url)}>
                    Remove
                </button>
            </td>
            <td scope="col">{props?.src?.url}</td>
        </tr>
    );
}

export default CrawlerAdd;
