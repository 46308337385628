/* eslint-disable react/display-name */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { formatDistanceToNowStrict } from "date-fns";
import { useAuth } from "react-oidc-context";
import API from "../../../../api/deepDiveAPI";
import Table from "../../../utils/table";
import AnsoModal from "../modals/addAnsoModal";
import ConfirmPopup from "../../../utils/confirmPopup";
import { isValidHttpUrl } from "../../../../utils/utils";

const noImageDataURI = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
const WARNING_TEXT = "Warning: this ANSO article is in 'hidden' mode. It won't be visible on the website.";

function AnsoTab({ topicId, parentRequestedRefetch }) {
    const alert = useAlert();
    const { user } = useAuth();
    const [openNewAnsoModal, setOpenNewAnsoModal] = useState(false);
    const [anso, setAnso] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const fetchNewsContent = useCallback(() => {
        setIsFetching(true);
        API.getContentByType(user.access_token, topicId, "deep_dive_anso")
            .then(data => {
                setAnso(data.data);
                // setTotal(data.total); // TODO: Nic - add support for pagination
            })
            .catch(e => alert.show(e))
            .finally(() => setIsFetching(false));
    }, [alert, user.access_token, topicId]);

    useEffect(() => {
        fetchNewsContent();
    }, [fetchNewsContent, parentRequestedRefetch]);

    const requestRefresh = useCallback(() => fetchNewsContent(), [fetchNewsContent]);

    const unlinkContent = useCallback(
        articleId => {
            API.unlinkTopicItem(articleId, "article", topicId, user.access_token)
                .then(() => requestRefresh())
                .catch(e => alert.show(`Error: ${e.message}`, { timeout: 1000, type: "error" }));
        },
        [alert, user.access_token, requestRefresh, topicId]
    );

    const columns = useMemo(
        () => [
            {
                Header: <span style={{ float: "left" }}>Image</span>,
                accessor: "images",
                Cell: row => (
                    <div className="position-relative">
                        {Array.isArray(row.value) && !row.value.length && (
                            <span className="position-absolute translate-middle">No image selected</span>
                        )}
                        {Array.isArray(row.value) && row.value.length > 1 && !isValidHttpUrl(row.value[0]) && (
                            <span className="position-absolute translate-middle">Invalid image URL</span>
                        )}
                        <img
                            style={{ objectFit: "cover", minWidth: "125px", height: "75px" }}
                            className="img-thumbnail m-0 p-0"
                            src={isValidHttpUrl(row.value[0]) ? row.value[0] : noImageDataURI}
                            alt="article"
                        />
                    </div>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Title</span>,
                accessor: "title",
                Cell: row => (
                    <p style={{ minWidth: "200px" }} className="d-inline-flex align-middle m-0">
                        <strong>{row.value}</strong>
                        {!anso[row.row.index].active && (
                            <span onClick={() => alert.show(WARNING_TEXT, { timeout: 10000, type: "warning" })} className="ml-2" title={WARNING_TEXT}>
                                <i className="fas fa-exclamation-triangle text-warning position-absolute pointer"></i>
                            </span>
                        )}
                    </p>
                )
            },
            // {
            //     Header: <span style={{ float: "left" }}>Subtitle</span>,
            //     accessor: "subtitle",
            //     Cell: row => <p className="d-inline-flex align-middle m-0 text-danger border border-danger p-1">{row.value}</p>
            // },
            // {
            //     Header: <span style={{ float: "left" }}>Origin</span>,
            //     accessor: "removed_from",
            //     Cell: row => <p className="d-inline-flex align-middle m-0">{row.value}</p>
            // },
            {
                Header: <span style={{ float: "left" }}>Pinned</span>,
                accessor: "createdAt",
                Cell: row => (
                    <p style={{ minWidth: "90px" }} className="d-inline-flex align-middle m-0 text-secondary">
                        {formatDistanceToNowStrict(new Date(row.value), { addSuffix: true })}
                    </p>
                )
            },
            {
                Header: <span style={{ float: "left" }}>Actions</span>,
                accessor: "_", // this is just a workaround for custom header
                Cell: row => {
                    return (
                        <div style={{ minWidth: "180px" }}>
                            <ConfirmPopup onConfirm={() => unlinkContent(row.data[row.row.index].id)}>
                                <button className="btn btn-outline-danger btn-sm ml-2">Unlink</button>
                            </ConfirmPopup>
                            <button className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === 0 ? "invisible" : ""}`}>
                                <i className="fas fa-arrow-up"></i>
                            </button>
                            <button className={`btn text-primary btn-link ml-1 p-1 ${row.row.index === row.rows.length - 1 ? "invisible" : ""}`}>
                                <i className="fas fa-arrow-down"></i>
                            </button>
                        </div>
                    );
                }
            }
        ],
        [alert, anso, unlinkContent]
    );

    const onSuccess = useCallback(() => {
        setOpenNewAnsoModal(false);
        requestRefresh();
    }, [requestRefresh]);

    return (
        <div className="AnsoTab mt-3 position">
            {openNewAnsoModal ? (
                <AnsoModal type="new" topicId={topicId} onSuccess={onSuccess} onClose={() => setOpenNewAnsoModal(false)} />
            ) : (
                <div className="d-flex justify-content-center mt-2">
                    <span onClick={() => setOpenNewAnsoModal(true)} className="h4 pointer">
                        <i className="fas fa-plus"></i>
                    </span>
                </div>
            )}
            <small>
                Note: only the <strong>top 3</strong> items are visible on Freespoke.com
            </small>
            {anso && <Table columns={columns} data={anso} />}
            {isFetching ? (
                <div className="mx-auto spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            ) : (
                anso && anso.length === 0 && <p className="text-center">No items</p>
            )}
        </div>
    );
}

AnsoTab.propTypes = {
    topicId: PropTypes.string.isRequired,
    parentRequestedRefetch: PropTypes.number.isRequired
};

export default AnsoTab;
