import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { formatDistanceToNowStrict } from "date-fns";
//import TagInput from "../TagInput/TagInput";
import ConfirmPopup from "../utils/confirmPopup";
import { AiOutlinePushpin, AiFillPushpin, AiOutlineEdit } from "react-icons/ai";
import { ENV } from "../../config.js";
import "./videoTile.css";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        minWidth: "400px"
    }
};

function VideoTile(props) {
    const videoPlayer = useRef(null);
    const [isEditMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        title: props.video.title ? props.video.title : "",
        description: props.video.description ? props.video.description : "",
        active: typeof props.video.active === "undefined" ? false : props.video.active,
        author: props.video.author ? props.video.author : ""
        //tags: props.video.tags ? props.video.tags : []
    });
    const [confirmDelete, setConfirmDelete] = useState(false);

    const openVideo = useCallback(() => {
        videoPlayer.current.src([
            {
                src: props.video.url,
                type: "application/vnd.ms-sstr+xml"
            }
        ]);
    }, [props.video.url]);

    useEffect(() => {
        if (props.video.url && props.video.encoded) {
            if (!videoPlayer.current) {
                // eslint-disable-next-line no-undef
                videoPlayer.current = amp(`${props.locationKey}-${props.video.id}`, {
                    techOrder: ["azureHtml5JS", "html5FairPlayHLS", "html5"], // tech order for the player to decide on which tech to use. Removed "flashSS", "silverlightSS" since they can cause issue on mobile
                    nativeControlsForTouch: false, // nativeControlsForTouch must be false (default) to avoid "Object doesn't support property or method 'setControls'"
                    autoplay: false,
                    logo: { enabled: false },
                    fluid: true, // set whether the video should follow the width of its container while keeping the video aspect ratio. Default is false.
                    controls: true, // set whether the controls should be displayed. Default is false.
                    /*
                     * HighQuality:
                     *   Profile that tries to play highest quality possible. It builds the buffer to limit potential buffering.
                     *   It does not take the width and height of the player into account when switching bitrates.
                     *   For live streams this profile has a backoff from the live edge to avoid potential buffering.
                     * Hybrid:
                     *   Profile that tries to balance quality and speed.
                     *   It builds the buffer more than QuickStart but less than HighQuality.
                     *   It takes the width and height of the player into account when switching bitrates.
                     *   For live streams this profile tries to stay close to the live edge.
                     *   This is the default profile.
                     * LowLatency:
                     *   Profile designed to work alongside Azure Media Services low latency feature for live streaming.
                     *   If low latency is not enabled on the stream, this heuristic profile will not yield a latency improvement.
                     * QuickStart:
                     *   Profile that starts the playback as fast as possible.
                     *   It also takes the width and height of the player into account when switching bitrates.
                     *   For live streams this profile tries to stay close to the live edge.
                     */
                    heuristicProfile: "HighQuality",
                    // width: "300",
                    height: "auto",
                    poster: props.video.thumbnailURL,
                    playbackSpeed: {
                        // configuration options for playback speed control
                        enabled: true,
                        initialSpeed: 1.0,
                        speedLevels: [
                            { name: "2x", value: 2.0 },
                            { name: "1.5x", value: 1.5 },
                            { name: "normal", value: 1.0 },
                            { name: "0.5x", value: 0.5 }
                        ]
                    },
                    hotKeys: {
                        enableJogStyle: true, // flag to control whether seeking forth/back by 1 sec with up/down arrows is allowed
                        seekStep: 5, // seek step in seconds
                        enableMute: true
                    }
                });
                // bing a listener to the Player's ready state.
                videoPlayer.current.addEventListener("ready", () => {
                    openVideo();
                });
            } else {
                // to cover case when 'encoded' and 'url' props change but video player already had been already initialized
                openVideo();
            }
        }
    }, [props.video.url, props.video.encoded, props.video.id, props.video.thumbnailURL, props.locationKey, openVideo]);

    /* Dispose video player on component unmount */
    useEffect(() => {
        return () => {
            if (videoPlayer.current) {
                videoPlayer.current.dispose();
            }
        };
    }, []);

    const openEditModal = useCallback(() => {
        setEditMode(true);
    }, []);

    const closeEditModal = useCallback(() => {
        setEditMode(false);
    }, []);

    const onInputChange = useCallback(
        e => {
            if (e.target.type === "checkbox") {
                setFormData({ ...formData, [e.target.name]: e.target.checked });
                return;
            }
            setFormData({ ...formData, [e.target.name]: e.target.value });
        },
        [formData]
    );

    const resetFormData = useCallback(() => {
        setFormData({
            title: props.video.title ? props.video.title : "",
            description: props.video.description ? props.video.description : "",
            author: props.video.author ? props.video.author : ""
            //tags: props.video.tags ? props.video.tags : []
        });
    }, [props.video.author, props.video.description, props.video.title]);

    const onDeleteClick = useCallback(() => {
        if (!confirmDelete) {
            setConfirmDelete(true);
        } else {
            props.deleteVideo(props.video.id);
            closeEditModal();
        }
    }, [closeEditModal, confirmDelete, props]);

    const cancelDelete = useCallback(() => {
        setConfirmDelete(false);
    }, []);

    return (
        <div className={`video mb-3 mr-3 ${props.className || ""}`}>
            <div className="card p-0">
                <div className="card-body d-flex py-1 pl-3 pr-2">
                    <h6 className="align-items-center d-inline-flex flex-grow-1 video-title mr-2 my-0">
                        <span>{props.video.title}</span>
                    </h6>
                    {props.showPinIcon && (
                        <ConfirmPopup title="Pin this video?" onConfirm={() => props.onPinRequest(props.video.id)}>
                            <span title="Pin video to the story" style={{ marginRight: "0.25em" }} className="pointer">
                                <AiOutlinePushpin size={20} />
                            </span>
                        </ConfirmPopup>
                    )}
                    {props.showUnpinIcon && (
                        <ConfirmPopup title="Unpin this video?" onConfirm={() => props.onUnPinRequest(props.video.id)}>
                            <span title="Unpin video from the story" style={{ marginRight: "0.25em" }} className="pointer">
                                <AiFillPushpin size={20} />
                            </span>
                        </ConfirmPopup>
                    )}
                    {props.showEditIcon && (
                        <span onClick={openEditModal} className="pointer">
                            <AiOutlineEdit size={20} />
                        </span>
                    )}
                </div>
                <div className="position-relative video-poster-wrapper overflow-hidden">
                    {(!props.video.encoded || !props.video.url) && <div className="processing">Video is being processed</div>}
                    <video id={`${props.locationKey}-${props.video.id}`} className="azuremediaplayer amp-default-skin amp-big-play-centered"></video>
                </div>
                <div className="card-footer text-muted video-footer p-2 px-3">
                    <div title={new Date(props.video.createdAt)}>
                        Uploaded <span>{formatDistanceToNowStrict(new Date(props.video.createdAt), { addSuffix: true })}</span>
                        <span className={`float-right badge ${props.video.active ? "badge-success" : "badge-danger"}`}>
                            {props.video.active ? "Live" : "Hidden"}
                        </span>
                    </div>
                </div>
            </div>
            <Modal isOpen={isEditMode} onRequestClose={closeEditModal} style={customStyles} contentLabel="Article settings">
                <div className="modal-header">
                    <h5 className="modal-title">Edit Video</h5>
                    <span onClick={resetFormData} className="float-right pr-1 pointer text-info">
                        Reset
                    </span>
                </div>
                <div className="modal-body">
                    <div className="form-group mb-1">
                        <label className="control-label font-weight-bold" htmlFor="title">
                            Title:
                        </label>
                        <input
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="title"
                            type="text"
                            value={formData.title}
                            placeholder="Add video title"
                            required
                        />

                        <label className="control-label font-weight-bold" htmlFor="description">
                            Description:
                        </label>
                        <textarea
                            onChange={onInputChange}
                            className="form-control text-body mb-2"
                            name="description"
                            type="text"
                            value={formData.description}
                            placeholder="Add video description"
                            required
                        />

                        <label className="control-label font-weight-bold" htmlFor="author">
                            Author:
                        </label>
                        <input
                            onChange={onInputChange}
                            className="form-control text-body"
                            name="author"
                            type="text"
                            value={formData.author}
                            placeholder="Original author"
                        />
                        {/* <TagInput label="Keywords:" placeholder="Add keywords" name="tags" onChange={onInputChange} value={formData.tags} /> */}
                        <div className="custom-control custom-switch mt-3">
                            <input
                                onChange={onInputChange}
                                checked={formData.active}
                                type="checkbox"
                                id="active_checkbox"
                                name="active"
                                className="custom-control-input"
                            />
                            <label className="custom-control-label" htmlFor="active_checkbox">{`Visible on Freespoke (${ENV}) website`}</label>
                        </div>
                    </div>
                </div>
                {!confirmDelete ? (
                    <div className="modal-footer justify-content-between">
                        <div>
                            <button className="btn btn-danger" onClick={onDeleteClick}>
                                Delete
                            </button>
                        </div>
                        <div>
                            <button className="btn btn-secondary" onClick={closeEditModal}>
                                Cancel
                            </button>
                        </div>
                        <div>
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    props.updateVideo(props.video.id, formData);
                                    closeEditModal();
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="modal-footer justify-content-between">
                        <button className="btn btn-secondary" onClick={cancelDelete}>
                            Cancel
                        </button>
                        <button className="btn btn-danger" onClick={onDeleteClick}>
                            Confirm Delete
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
}

VideoTile.propTypes = {
    className: PropTypes.string,
    locationKey: PropTypes.string.isRequired, // to prevent video player issues
    video: PropTypes.object.isRequired,
    showPinIcon: PropTypes.bool,
    showUnpinIcon: PropTypes.bool,
    showEditIcon: PropTypes.bool,
    onPinRequest: PropTypes.func,
    onUnPinRequest: PropTypes.func,
    updateVideo: PropTypes.func,
    deleteVideo: PropTypes.func
};

export default VideoTile;
