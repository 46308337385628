// @ts-check

import React, { useCallback, useEffect, useState } from "react";

import { useAuth } from "react-oidc-context";
import { getHttpErrorMessage } from "../../../utils/utils";
import Modal from "../../utils/modal";
import { fetchSummaryPrompts, generateAISummary } from "./useAiSummaryApi";

/**
 * @param {object} props
 * @param {string} props.storyId
 * @param {(id: string) => Promise<void>} props.onGenerate
 */
export function AddSummaryModal({ storyId, onGenerate }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [promptId, setPromptId] = useState(/** @type {string | undefined} */ (undefined));
    const [promptOptions, setPromptOptions] = useState(/** @type {import("./AiSummaryTypes").AiSummaryPrompt[]} */ ([]));
    const [error, setError] = useState(/** @type {?string} */ (null));

    const { user } = useAuth();

    useEffect(() => {
        if (!user?.access_token) {
            return;
        }
        const fetchPrompts = async () => {
            const response = await fetchSummaryPrompts({ token: user?.access_token });
            setPromptOptions(response);
            setPromptId(x => x || response[0]?.id);
        };
        fetchPrompts();
    }, [user?.access_token]);

    const generate = useCallback(async () => {
        if (!storyId || !promptId || isGenerating) {
            return;
        }
        setIsGenerating(true);
        setError(null);
        try {
            if (!user?.access_token) {
                throw new Error("unauthenticated");
            }
            const { id } = await generateAISummary({ storyId, promptId, articleIds: undefined, token: user?.access_token });
            await onGenerate(id);
            setIsOpen(false);
        } catch (e) {
            setError(getHttpErrorMessage(e));
        } finally {
            setIsGenerating(false);
        }
    }, [isGenerating, onGenerate, promptId, storyId, user?.access_token]);

    return (
        <div className="d-flex justify-content-center my-2 mb-3">
            <button onClick={() => setIsOpen(true)} disabled={isOpen} className="btn btn-primary">
                Add AI Summary <i className="fas fa-plus text-secondary pointer"></i>
            </button>
            <Modal
                className="add-ai-modal"
                label="Add AI Summary"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                closeClassName="add-summary-modal-close "
            >
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <select className="mb-1 p-1" onChange={e => setPromptId(e.target.value)} value={promptId} disabled={isGenerating}>
                        {promptOptions.map(prompt => (
                            <option key={prompt.id} value={prompt.id}>
                                {prompt.display_name}
                            </option>
                        ))}
                    </select>
                    <button className="btn btn-info" disabled={!promptId || isGenerating} onClick={generate}>
                        {isGenerating ? "Generating" : "Add AI Summary"}
                    </button>
                    <span className="text-center">
                        *Once you click Add, it may take up to a minute to generate the summary.
                        <br />
                        Closing the modal will not cancel the request
                    </span>
                    <br />
                    {error && <span className="text-danger">{error}</span>}
                </div>
            </Modal>
        </div>
    );
}
