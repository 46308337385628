// @ts-check

import React, { useImperativeHandle, useRef, useState } from "react";
import Tiptap from "../../tiptap";

/**
 * @typedef Definition
 * @property {string} name
 * @property {string} description
 * @property {string} id
 *
 * @typedef EditEvent
 * @property {string} summary
 * @property {Definition[]} definitions
 *
 * @typedef SummaryRef
 * @property {() => EditEvent} getValue
 *
 * @param {object} props
 * @param {string} props.summary
 * @param {Definition[]} props.definitions
 * @param {React.Ref<SummaryRef>} props.summaryRef
 */
export function EditSummary({ summary, definitions, summaryRef }) {
    const tiptapRef = useRef(/** @type {?import("../../tiptap").TiptapRef} */ (null));
    const [updatedDefinitions, setUpdatedDefinitions] = useState(definitions);

    useImperativeHandle(summaryRef, () => ({
        getValue: () => ({
            summary: tiptapRef.current?.getHtml() || "",
            definitions: updatedDefinitions.filter(def => def.name && def.description)
        })
    }));

    return (
        <>
            <Tiptap content={summary} ref={tiptapRef} />
            <div className="d-flex flex-row mt-3">
                <h3 className="mr-3">Definitions</h3>
                <button
                    disabled={updatedDefinitions.some(def => !def.name || !def.description)}
                    onClick={() => {
                        setUpdatedDefinitions(prev => [{ name: "", description: "", id: Math.random().toString() }, ...prev]);
                    }}
                    className="btn btn-primary btn-md h-50"
                >
                    <i className="fas fa-plus"></i>
                </button>
            </div>
            {updatedDefinitions.map((entity, idx) => (
                <div key={entity.id} className="mb-3 border rounded d-flex flex-row">
                    <div className="flex-grow-1">
                        <input
                            className="form-control font-weight-bold"
                            style={{ fontSize: "1.5rem" }}
                            type="text"
                            name="name"
                            value={updatedDefinitions[idx].name}
                            onChange={e => {
                                const updated = [...updatedDefinitions];
                                updated[idx] = { ...updated[idx], name: e.target.value };
                                setUpdatedDefinitions(updated);
                            }}
                        />
                        <textarea
                            className="form-control"
                            name="definition"
                            value={updatedDefinitions[idx].description}
                            onChange={e => {
                                const updated = [...updatedDefinitions];
                                updated[idx] = { ...updated[idx], description: e.target.value };
                                setUpdatedDefinitions(updated);
                            }}
                        />
                    </div>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            setUpdatedDefinitions(prev => prev.filter(def => def.id !== entity.id));
                        }}
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </div>
            ))}
        </>
    );
}
